<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PQ2B553"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> 
<!-- End Google Tag Manager (noscript) -->
<app-navbar></app-navbar>
<div class="container">
    <div class="row">
        <div class="col-md-12">

            <section class="hero fadeandscaleIn">
                <h1 style="font-weight:800; font-size: 35px;">Amazing Featutes of Kalyankari softnet</h1>

                <p class="text-muted">kalyankari Softnet manage ICANN Acredited Domain Names at the most competitive rates in the industry. We
                    provide domain registration with more than 50 domain extensions at very competitive price. Every domain comes with multiple features to enable our customers an easy registration
                    process. Additionally we offer 24×7 support services</p>
                <button class="btn btn-warning" style="padding: 10px;"> <a href="#">Learn More</a> </button>
            </section>

        </div>


        <div class="col-md-6">
            <div class="card" style="padding: 20px;">
                <h4 class="text-center" style="font-weight: 700; font-size: 22px;">Free Email Accounts</h4>
                <p class="text-muted">Receive 2 personalized Email Addresses such as mail@kalyankarisoftnet.com with free fraud,
                    spam and virus protection.</p>
            </div>

        </div>

        <div class="col-md-6">
            <div class="card" style="padding: 20px;">
                <h4 class="text-center" style="font-weight: 700; font-size: 22px;">DNS Management</h4>
                <p class="text-muted">Free lifetime DNS service which allows you to manage your DNS records on our
                    globally
                    distributed and highly redundant DNS infrastructure.</p>
            </div>

        </div>

        <div class="col-md-6">
            <div class="card" style="padding: 20px;">
                <h4 class="text-center" style="font-weight: 700; font-size: 22px;">Privacy Protection</h4>
                <p class="text-muted">Get access to free Privacy Protection with every Domain Name you register.
                    Maintain
                    your privacy and prevent email spam by masking your public WHOIS details.</p>
            </div>

        </div>

        <div class="col-md-6">
            <div class="card" style="padding: 20px;">
                <h4 class="text-center" style="font-weight: 700; font-size: 22px;">Domain Forwarding</h4>
                <p class="text-muted">Point your domain name to another website for free! Redirect users when they type
                    your
                    domain name into a browser (with/without domain masking & SEO)</p>

            </div>

        </div>


        <div class="col-md-6">
            <div class="card" style="padding: 20px;">
                <h4 class="text-center" style="font-weight: 700; font-size: 22px;">Domain Theft Protection</h4>
                <p class="text-muted">Protect your Domain from being transferred out accidentally or without your
                    permission with our free Domain Theft Protection.</p>

            </div>

        </div>


        <div class="col-md-6">
            <div class="card" style="padding: 20px;">
                <h4 class="text-center" style="font-weight: 700; font-size: 22px;">Easy to Use Control Panel</h4>
                <p class="text-muted">Use our intuitive Control Panel to manage your domain name, configure email
                    accounts, renew your domain name and buy more services.</p>

            </div>

        </div>

        <div class="col-md-6">
            <div class="card" style="padding: 20px;">
                <h4 class="text-center" style="font-weight: 700; font-size: 22px;">Free Mail Forwarding</h4>
                <p class="text-muted">Create free email forwards and automatically redirect your email to existing email
                    accounts.</p>

            </div>

        </div>

        <div class="col-md-6">
            <div class="card" style="padding: 20px;">
                <h4 class="text-center" style="font-weight: 700; font-size: 22px;">Bulk Tools</h4>
                <p class="text-muted">Easy-to-use bulk tools to help you Register, Renew, Transfer and make other
                    changes to several Domain Names in a single step.</p>

            </div>

        </div>

        <div class="col-md-12">
            <app-contact-us></app-contact-us>

        </div>

    </div>

</div>






<app-footer></app-footer>