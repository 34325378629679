import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-card-view-tabs',
  templateUrl: './card-view-tabs.component.html',
  styleUrls: ['./card-view-tabs.component.css']
})
export class CardViewTabsComponent implements OnInit {

  constructor(private meta: Meta,
    private titleService: Title) {
    this.titleService.setTitle('Services Tabs | Kalyankari Softnet');
    this.meta.addTag({ name: 'description', content: 'services offered' });
    this.meta.addTag({ name: 'keywords', content: 'SCHOOL ERP, Ecommerce, Hosting, Bulk SMS, Software on Demand, Web Design, Cloud Service & Support, DevOps Engineering, Digital Marketing' })
  }

  ngOnInit(): void {
  }

}
