<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PQ2B553"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> 
<!-- End Google Tag Manager (noscript) -->
<app-navbar></app-navbar>

<body>
    <div class="c-hero">
        <div class="c-hero__content">
          <h1 style="font-weight: 800;" class="c-hero__headline">Software<br>Development</h1>
        </div>
        <div class="c-hero__img-container c-hero--fade-in--img"></div>
      </div>

    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h4 class="text-center mt-4" style="font-weight: 800;">Software is built to make human work easier to
                    manage.</h4>
                <p class="text-center text-muted">Our software, lets you make the admission of your student and get a printout of their respective admission letter.</p>
                <img src="../../assets/images/softwaresection-image2.png" class="img-fluid " alt="">
            </div>

            <div class="col-md-6">
                <img src="../../assets/images/softwaresection-image1.gif" class="img-fluid " alt="">

            </div>

            <div class="col-md-12">
                <h4 class="text-center mt-4" style="font-weight: 800;">Explore our featured software</h4>

            </div>

            <div class="col-md-6">
                <div class="card">
                    <h5 class="text-center mt-4 text-muted p-10" style="font-weight: 600;">Online student enrolment software</h5>
                    <p class="text-muted pl-10 text-justify">Make admission of your students online through our software. This also allows student to take
                        printout of their admission letter through easy login.</p>
                        <p class="text-muted pl-10 text-justify">Custom software is software that is specially developed for some specific organization or other user. </p>

                </div>
            </div>

            <div class="col-md-6" style="padding-bottom: 40px;">
                <div class="card">
                    <h5 class="text-center mt-4 text-muted p-10" style="font-weight: 600;">Online Test Series</h5>
                    <p class="text-muted pl-10 text-justify">Online Test Series
                        Presently, it is the era of digitalization where the education system is also adopting this trend. Now, almost all competitive exams have gone online.</p>
                        <p class="text-muted pl-10 text-justify">Proper Design and Responsive</p>

                </div>
            </div>

            

        </div>

    </div>
</body>

<app-footer></app-footer>