<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PQ2B553"
  height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> 
<!-- End Google Tag Manager (noscript) -->
<app-navbar></app-navbar>

<section class="hero-container">
  <div class="container">
    <div class="hero">
      <div class="hero__photo">
        <img src="../../assets/images/seoimage.gif" alt="Person with laptop">
      </div>
      <div class="hero__body half">

        <h1 class="hero__title text-center">Get 10x Better SEO Results</h1> <br>
        <p class="text-center" style="font-weight:600">Kalyankari Softnet is a leading SEO company in your city with the
          superlative marketing minds, brainstorming and
          innovating ideas for ranking websites higher on Google.</p>
        <footer class="hero__footer">
          <div class="text-center center-block" style="margin-left: auto; margin-right: auto;">
            <button data-toggle="modal" data-target="#myModal" type="button" class="btn btn-secondary center-block"
              style="color: black;font-weight: 900; background-color: beige;">Get A Free Quote</button>
          </div>

        </footer>

        <!-- Modal start -->

        <div class="modal fade" id="myModal" role="dialog">
          <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content">
              <div class="modal-header">

                <h4 class="modal-title" style="font-weight: 800; font-size: 22px;">GET A FREE QUOTE</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
              </div>

              <div class="modal-body" style="background-color: lavenderblush;">
                <form action="">
                  <div class="form-group">
                    <select class="form-control" id="sel1">
                      <option>Digital Marketing</option>
                      <option>Ecommerse</option>
                      <option>Hosting</option>
                      <option>Website Design</option>
                      <option>Custom Website</option>
                      <option>SEO</option>
                      <option>Bulk SMS</option>
                      <option>School ERP</option>
                    </select>

                  </div>

                  <div class="form-group">
                    <input type="text" class="form-control my-3" placeholder="Enter your name" autocomplete="off">
                    <input type="text" class="form-control my-4" placeholder="Enter your phone Number">
                    <input type="email" class="form-control my-4" placeholder="Enter your Email-id">
                    <textarea class="form-control my-4" id="exampleFormControlTextarea1" rows="3"
                      placeholder="Enter your address"></textarea>
                    <textarea class="form-control" cols="8" rows="5" placeholder="Write your message"></textarea>

                  </div>

                  <button class="btn btn-warning" style="background-color: blanchedalmond;  ">submit</button>

                </form>
              </div>

            </div>

          </div>
        </div>

        <!-- Modal end -->
      </div>
    </div>


  </div>

</section>


<!-- ..............................................................2nd section................................. -->

<div class="container">
  <h2 class="text-center" style="font-weight: 800; margin-top: 20px; font-size: 25px;">Result Driven SEO service in Your
    City, results
    guaranteed !!</h2>
  <div class="row">

    <div class="col-md-4">
      <div class="card">
        <div class="overlay"></div>
        <div class="card-header">
          <div class="card-img"></div>
          <div class="card-title">
            Research
          </div>
        </div>
        <div class="card-body">
          We analyze industries, Companies and competitions to cultivate a set of result-driven keywords and marketing
          strategy !!
        </div>
      </div>

    </div>


    <div class="col-md-4">
      <div class="card">
        <div class="overlay"></div>
        <div class="card-header">
          <div class="card-img"></div>
          <div class="card-title">
            Optimization
          </div>
        </div>
        <div class="card-body">
          Our on-page and Off-page optimization practices implant the ranking potenial to the depth of your website !!
        </div>
      </div>

    </div>


    <div class="col-md-4">
      <div class="card">
        <div class="overlay"></div>
        <div class="card-header">
          <div class="card-img"></div>
          <div class="card-title">
            Improvement
          </div>
        </div>
        <div class="card-body">
          Witness your site's success and a hike in traffic and conversion. Believe, we are here to make it possible !!
        </div>
      </div>

    </div>


    <!-- ..........................................3rd section ................................. -->
    <div class="container">
      <h3 class="text-center text-muted" style="font-weight: 800; font-size: 25px;">Let Your Website Meet Its Fullest
        Potential
        with the Best SEO Technique !!</h3>
      <div class="row">


        <div class="col-md-6">
          <img src="../../assets/images/seodesktoprankingimage.gif" class="img-fluid"
            style="padding-left: 0px; padding-top: 20px;" alt="">
        </div>

        <div class="col-md-6"> <br>

          <p class="text-justify text-muted" style="background-image: url('../../assets/images/left-bg-decor.png');">
            kalyankari Softnet is one such rare SEO agency in your city that doesn’t follow the run-of-the-mill SEO
            practices blindly. We believe SEO is an art. According to our consideration, a blend of creativity and
            innovation in SEO strategies gives the best result with lucrative figures to our customers. We deliver
            ranking and nurture real-time queries which cause revenue. </p>

          <p class="text-justify text-muted" style="background-image: url('../../assets/images/right-bg-decor.png');">
            Whether you aim at an increased conversion or traffic or both, Kalyankari Softnet is the SEO service
            provider in your city which can offer you global and local SEO services. We shape your future with
            long-lasting organic traffic and huge ROI.
          </p>


        </div>



      </div>


    </div>

    <!-- .................................4th section................................... -->

    <section class="container">

      <div class="row">
        <div class="col-md-12">

          <h4 class="text-center text-muted" style="font-weight: 700; padding-top: 20px;">Approach That Makes Us the
            Leading SEO Expert in Your City
          </h4> <br>
          <div>
            <p class="text-justify text-muted">
              Whatever be the type of your business, you always try to increase your customer list. Recently, the use of
              the internet by households has expanded tremendously, which made the market broader. In such a scenario,
              you are bound to consider the benefits of Search Engine Optimization and SEO service in your city.
              Innumerable online users are searching for the services. Those are provided by you. But due to lack of
              your website, you generally miss them.
            </p>

            <p class="text-justify text-muted">Thus, you need to get noticed through our captivating website. We have a
              team of a proficient SEO experts. We provide you a complete set of advanced SEO operations as per your
              requirement.</p>
          </div>

        </div>

      </div>


    </section>



  </div>

</div>

<!-- ...................................5th section...................... -->
<div class="container">
  <div class="row">
    <div class="col-md-4">
      <div class="card" style="min-height: 400px;">
        <div class="overlay"></div>
        <div class="card-header">
          <div class="card-img"></div>
          <div class="card-title" style="font-size: 22px;">
            Rational Market Research

          </div>
        </div>
        <div class="card-body">
          Being the leading SEO agency in your cities, we never work without figures! It is mandatory to evaluate the
          market for any SEO project, so we do it precisely. Consistently, we operate market research to get market
          nerve that helps us rank your website on top.
        </div>
      </div>

    </div>

    <div class="col-md-4">
      <div class="card" style="min-height: 400px;">
        <div class="overlay"></div>
        <div class="card-header">
          <div class="card-img"></div>
          <div class="card-title" style="font-size: 22px;">
            On-Page Optimization

          </div>
        </div>
        <div class="card-body">
          The architecture and content of a web page are the first pieces of a complex puzzle. Being the first choice of
          many individuals as an SEO company in your cities, we take the responsibility to unriddle the puzzle and
          complete the on-page optimization with utmost ease and strategy. !!
        </div>
      </div>

    </div>


    <div class="col-md-4">
      <div class="card" style="min-height: 400px;">
        <div class="overlay"></div>
        <div class="card-header">
          <div class="card-img"></div>
          <div class="card-title" style="font-size: 22px;">
            Quality Link Building

          </div>
        </div>
        <div class="card-body">

          The importance of link building, cannot be ignored. As it plays a vital role to rank your site or page on the
          search engine. Each company focuses on the quality of backlinks instead of quantity because Google considers
          the authority of the website that gives you a link !!
        </div>
      </div>

    </div>

  </div>

</div>

<!-- .............................6-section .................... -->
<div class="container">
  <div class="row">
    <div class="col-md-6">

      <h4 style="font-weight: 800; font-size: 19px;">Good website > Ethical SEO > More hits > Higher business</h4>

      <p>Get your website design in an SEO-friendly manner. Catch with google’s latest search algorithm based on Google
        Panda. Google Panda released in Feb 2011 is aimed to lower the rank of “low-quality sites” or “thin sites” and
        bring better sites on top of your websites.</p>
      <p>Get more targeted visitors with our SEO-friendly website designs and search engine optimization.</p>


    </div>

    <div class="col-md-6">
      <img src="../../assets/images/seolastimage.gif" alt="">
    </div>

  </div>


</div>






<div class="col-md-12">
  <h4 class="text-center" style="margin-top: 20px;">Contact our representative for more information and free
    consultation on your website.</h4>

  <app-contact-us></app-contact-us>

</div>







<app-footer></app-footer>