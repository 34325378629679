<app-navbar></app-navbar>
<div class="container-fluid">
    

</div>

<div class="container">
    <div style="margin-top: 50px;  ">

    </div>
    <div class="row">


        <div class="col-md-6">
            <img class="img-fluid" src="../../assets/images/portfolio_hero.svg" alt="" srcset="">
        </div>
        <div class="col-md-6">
            <div class="text-center my-5 ">
                <h4 class="text-uppercase" style="font-weight: 700">Our Portfolio</h4>
                <img class="img-fluid" style="width: 320px;" src="../../assets/images/portfolio_heading_new.png" alt="kalyankarisoftnet portfolio">       
                <h5 style="font-size: 17px; padding-top: 12px;">MOBILE | AI | VR | IOT | BLOCKCHAIN | DEVOPS | CLOUD | WEBSITE</h5>
            </div>

            <div style="font-size: 14px;">
                <p style="position: relative; float: right; " class="text-muted text-justify">We are passionately
                    committed to providing the best
                    IT services & Web Solutions to our clients. We involve
                    best human efforts, high technology & modern
                    management practices to envisage our mission. We
                    believe in transparency and are thus determined to
                    create an environment free of restlessness.</p>
    
                <p class="text-muted">We aspire to provide you with the best web & related
                    services to your business.</p>
    
                <p>Our Understanding</p>
                <p class="text-muted text-justify">To serve clients with new-fangled infrastructure and
                    advanced technique brings client users together.
                    Through a cohesive undertaking, we are here to
                    fabricate a noteworthy clients' brand.</p>
            </div>
        </div>

    </div>


    <h4 style="margin-top: 30px; font-weight: 700;">Our Reach</h4>
    <img style="width: 340px;" class="img-fluid" src="../../assets/images/our reach_heading.png" alt="Our reach kalyankari softnet">

    <div class="row my-4">
        <div class="col-md-3">

            <div style="margin-top: 10em;">
                <span>10+</span> <br>
                <span>years Industry</span> <br>
                <span>EXPERIENCE</span>
            </div>

        </div>

        <div class="col-md-6">
            <img class="img-fluid" src="../../assets/images/portfolio2.png" alt="Experiences kalyankari softnet">

        </div>

        <div class="col-md-3">

            <div style="margin-top: 10em; position: relative; float: right;">
                <span>50+</span> <br>
                <span>Web & Mobile</span> <br>
                <span>APPLICATION</span>
            </div>

        </div>

    </div>

    <div class="row my-4">
        <div class="col-md-12" style="margin-bottom: -20px;">
            <h4 style="margin-top: 30px; font-weight: 700;">Get To Know US</h4>

            <div>
                <span>2+ Android Apps, 3+ iOS Apps,</span> <br>
                <span>5+ Web apps, 10+ Cross-platform,</span> <br>
                <span>10+ Web portals.</span>
            </div>

        </div>

        <!-- Topic Cards -->
        <div id="cards_landscape_wrap-2">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <a href="">
                            <div class="card-flyer">
                                <div class="text-box">

                                    <div class="text-container">
                                        <h6>01</h6>
                                        <p>10+ Happy clients
                                            across the globe.</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <a href="">
                            <div class="card-flyer">
                                <div class="text-box">

                                    <div class="text-container">
                                        <h6>02</h6>
                                        <p>Content delivery experts
                                            multi-format, multi-channel.</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <a href="">
                            <div class="card-flyer">
                                <div class="text-box">


                                    <div class="text-container">
                                        <h6>03</h6>
                                        <p>Started by dynamic leaders in
                                            2021 with a collaborative
                                            exposure of 10+ years.</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <a href="">
                            <div class="card-flyer">
                                <div class="text-box">

                                    <div class="text-container">
                                        <h6>04</h6>
                                        <p>Over 10+ successfully
                                            delivered projects across
                                            web and mobile platforms..</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <a href="">
                            <div class="card-flyer">
                                <div class="text-box">


                                    <div class="text-container">
                                        <h6>05</h6>
                                        <p>Our promise is everything for clients to trust.</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <a href="">
                            <div class="card-flyer">
                                <div class="text-box">


                                    <div class="text-container">
                                        <h6>06</h6>
                                        <p>A team of 50+ young & energetic
                                            enthusiasts.</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                </div>
            </div>
        </div>


    </div>

    <!-- core functionality -->



    <div class="row my-4">
        <div class="col-md-12" style="margin-bottom: -20px;">
            <h4 class="text-center" style="margin-top: 30px; font-weight: 700;">Core Functionalities</h4>
            <div class="text-center">
                <img  style="width: 380px;" class="img-fluid" src="../../assets/images/portfolio underline1.png" alt="core functionality">
            </div>

        </div>

        <!-- Topic Cards -->
        <div id="cards_landscape_wrap-2">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <a href="">
                            <div class="card-flyer">
                                <div class="text-box">

                                    <div class="text-container">
                                        <h6>01</h6>
                                        <p>Mobile <br> Applications</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <a href="">
                            <div class="card-flyer">
                                <div class="text-box">

                                    <div class="text-container">
                                        <h6>02</h6>
                                        <p>Website & <br>
                                            Web Portal</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <a href="">
                            <div class="card-flyer">
                                <div class="text-box">


                                    <div class="text-container">
                                        <h6>03</h6>
                                        <p>DevOps & <br>
                                            Cloud</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <a href="">
                            <div class="card-flyer">
                                <div class="text-box">

                                    <div class="text-container">
                                        <h6>04</h6>
                                        <p>e-Commerce <br>
                                            Application</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <a href="">
                            <div class="card-flyer">
                                <div class="text-box">


                                    <div class="text-container">
                                        <h6>05</h6>
                                        <p>Software <br> On Demands</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <a href="">
                            <div class="card-flyer">
                                <div class="text-box">


                                    <div class="text-container">
                                        <h6>06</h6>
                                        <p>Artificial <br>
                                            Intelligence</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <a href="">
                            <div class="card-flyer">
                                <div class="text-box">


                                    <div class="text-container">
                                        <h6>07</h6>
                                        <p>Internet <br>
                                            Of things</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <a href="">
                            <div class="card-flyer">
                                <div class="text-box">


                                    <div class="text-container">
                                        <h6>08</h6>
                                        <p>CMS <br>
                                            Applications</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                </div>
            </div>
        </div>


    </div>

    <!-- Verticals We Dominate -->

    <div class="row my-4">
        <div class="col-md-12" style="margin-bottom: -20px;">
            <h4 style="margin-top: 30px; font-weight: 700;">Verticals We Dominate</h4>
            <img style="width: 380px;" class="img-fluid" src="../../assets/images/portfolio underline1.png" alt="core functionality">

        </div>

        <!-- Topic Cards -->
        <div id="cards_landscape_wrap-2">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <a href="">
                            <div class="card-flyer">
                                <div class="text-box">

                                    <div style="padding-top: 24px;"><i style="color: gray;"
                                            class="fa fa-4x fa-file-video-o" aria-hidden="true"></i></div>

                                    <div class="text-container">
                                        <h6>01</h6>
                                        <p>MEDIA</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <a href="">
                            <div class="card-flyer">
                                <div class="text-box">

                                    <div style="padding-top: 24px;"><i style="color: gray;" class="fa fa-4x fa-taxi"
                                            aria-hidden="true"></i></div>

                                    <div class="text-container">
                                        <h6>02</h6>
                                        <p>TEXI & TRAVEL</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <a href="">
                            <div class="card-flyer">
                                <div class="text-box">

                                    <div style="padding-top: 24px;"><i style="color: gray;" class="fa fa-4x fa-medkit"
                                            aria-hidden="true"></i></div>
                                    <div class="text-container">
                                        <h6>03</h6>
                                        <p>HEALTHCARE</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <a href="">
                            <div class="card-flyer">
                                <div class="text-box">
                                    <div style="padding-top: 24px;"><i style="color: gray;" class="fa fa-4x fa-gamepad"
                                            aria-hidden="true"></i></div>
                                    <div class="text-container">
                                        <h6>04</h6>
                                        <p>GAME</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <a href="">
                            <div class="card-flyer">
                                <div class="text-box">
                                    <div style="padding-top: 24px;"><i style="color: gray;"
                                            class="fa fa-4x fa-university" aria-hidden="true"></i></div>

                                    <div class="text-container">
                                        <h6>05</h6>
                                        <p>BANKING</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <a href="">
                            <div class="card-flyer">
                                <div class="text-box">

                                    <div style="padding-top: 24px;"><i style="color: gray;" class="fa fa-4x fa-building"
                                            aria-hidden="true"></i></div>
                                    <div class="text-container">
                                        <h6>06</h6>
                                        <p>REAL - ESTATE</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <a href="">
                            <div class="card-flyer">
                                <div class="text-box">
                                    <div style="padding-top: 24px;"><i style="color: gray;"
                                            class="fa fa-4x fa-shopping-cart" aria-hidden="true"></i></div>

                                    <div class="text-container">
                                        <h6>07</h6>
                                        <p>E-COMMERCE</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <a href="">
                            <div class="card-flyer">
                                <div class="text-box">

                                    <div style="padding-top: 24px;"><i style="color: gray;"
                                            class="fa fa-4x fa-building-o" aria-hidden="true"></i></div>
                                    <div class="text-container">
                                        <h6>08</h6>
                                        <p>ENTERPRISE</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <a href="">
                            <div class="card-flyer">
                                <div class="text-box">

                                    <div style="padding-top: 24px;"><i style="color: gray;" class="fa fa-4x fa-heart"
                                            aria-hidden="true"></i></div>
                                    <div class="text-container">
                                        <h6>09</h6>
                                        <p>DATING</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <a href="">
                            <div class="card-flyer">
                                <div class="text-box">
                                    <div style="padding-top: 24px;"><i style="color: gray;"
                                            class="fa fa-4x fa-line-chart" aria-hidden="true"></i></div>

                                    <div class="text-container">
                                        <h6>10</h6>
                                        <p>ON DEMAND <br>
                                            SERVICES</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                </div>
            </div>
        </div>


    </div>


    <!-- Our Strengths -->
    <div class="row my-4">
        <div class="col-md-12" style="margin-bottom: -20px;">
            <h4 class="text-center" style="margin-top: 30px; margin-bottom: 30px; font-weight: 700;">Our Strengths</h4>
            <div class="text-center">
                <img style="width: 380px;" class="img-fluid" src="../../assets/images/portfolio underline1.png" alt="core functionality">
            </div>

        </div>

    </div>

    <div class="row my-4">
        <div class="col-md-6 py-4">
            <div class="card">
                <h5 class="text-center py-5 px-4" style="font-size: 16px;">Proven training processes to
                    get team on board in shortest
                    period.</h5>
            </div>

        </div>

        <div class="col-md-6 py-4">
            <div class="card">
                <h5 class="text-center py-5" style="font-size: 16px;">Define measurable quality
                    benchmarks for the
                    deliverables.</h5>
            </div>

        </div>

        <div class="col-md-6 py-4">
            <div class="card">
                <h5 class="text-center py-5 px-4" style="font-size: 16px;">Flexibility in working with
                    existing processes (or invent
                    new ones) to reduce costs.</h5>
            </div>

        </div>

        <div class="col-md-6 py-4">
            <div class="card">
                <h5 class="text-center py-5" style="font-size: 16px;">Continuous process
                    improvements to meet the
                    quality benchmarks.</h5>
            </div>

        </div>

    </div>

    <!-- Business Engagement Models Start -->
    <h4 style="margin-top: 60px; margin-bottom: 30px; font-weight: 700;">Business Engagement Models</h4>
    <img style="width: 380px;" class="img-fluid" src="../../assets/images/portfolio underline1.png" alt="core functionality">
    <div class="row my-4">


        <div class="col-md-4 my-3">
            <div class="card" style="width: auto; height: auto;">
                <div class="card-body py-4 px-5">
                    <h5 class="card-title text-center">Time & Material</h5>
                    <h6 class="card-subtitle mb-2 text-muted text-center">01</h6>
                    <p class="card-text text-muted">Our Time & Material model lets
                        clients monitor development
                        hours of the projects and pay
                        only for the number of hours
                        our team dedicated into their
                        project or the amount of work
                        completed for their project.</p>

                </div>
            </div>

        </div>

        <div class="col-md-4 my-3">
            <div class="card" style="width: auto; height: auto;">
                <div class="card-body py-4 px-5">
                    <h5 class="card-title text-center">Fixed Price</h5>
                    <h6 class="card-subtitle mb-2 text-muted text-center">02</h6>
                    <p class="card-text text-muted">Our fixed price model enables
                        clients to dis cuss and fix the
                        precise cost and time estimates
                        for their project beforehand
                        Meaning the cost of the project
                        remains the same till the client
                        requirements do.</p>

                </div>
            </div>

        </div>

        <div class="col-md-4 my-3">
            <div class="card" style="width: auto; height: 240px;">
                <div class="card-body py-4 px-5">
                    <h5 class="card-title text-center">Fixed Price</h5>
                    <h6 class="card-subtitle mb-2 text-muted text-center">03</h6>
                    <p class="card-text text-muted">Make sure you do enough
                        research to support your points.
                        It's also a good idea to pair data
                        with visual aids like charts,
                        graphs, or images.</p>

                </div>
            </div>

        </div>


    </div>
    <!-- Business Engagement Models End -->


    <!-- Development Strategy start -->
    <h4 style="margin-top: 60px; margin-bottom: 30px; font-weight: 700;">Development Strategy</h4>
    <p>At Kalyankari Softnet, our development strategies are planned keeping in mind the
        perspective of clients, customers and current trends.</p>
    <div class="row my-4">
        <div class="col-md-6">
            <div>
                <ul>
                    <li>Comprehending and implementing mobile trends in smart phones, tablets
                        and platforms</li>
                    <li>Market and user research analysis.</li>
                    <li>Mobile platforms development strategy</li>
                    <li>Assessment of cross-platform requirements.</li>
                </ul>
            </div>

        </div>

        <div class="col-md-6">
            <div>
                <ul>
                    <li>Designing an approach for varied mobile platforms..</li>
                    <li>Building roadmaps for mobile platform..</li>
                    <li>Enterprise Mobile Strategy..</li>
                </ul>
            </div>

        </div>


    </div>

    <!-- Development Strategy end -->


    <!-- What Sets Us Apart start -->
    <h4  style="margin-top: 60px; margin-bottom: 30px; font-weight: 700;">What Sets Us Apart</h4>
    <img style="width: 380px;" class="img-fluid" src="../../assets/images/portfolio underline1.png" alt="core functionality">

    <div class="row" style="margin-top: -60px;">
        <!-- Topic Cards -->
        <div id="cards_landscape_wrap-2">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <a href="">
                            <div class="card-flyer">
                                <div class="text-box">

                                    <div class="text-container">
                                        <h6 style="font-size: 18px;" class="text-muted">CUSTOMER
                                            SATISFACTION</h6>
                                        <p>A crucial factor that
                                            helps to manage
                                            and improve one's own
                                            businesses.</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <a href="">
                            <div class="card-flyer">
                                <div class="text-box">

                                    <div class="text-container">
                                        <h6 style="font-size: 18px;" class="text-muted">ON TIME DELIVERY</h6>
                                        <p>We very well
                                            understand that time
                                            is precious and thus we
                                            ensure on
                                            time delivery of project.</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <a href="">
                            <div class="card-flyer">
                                <div class="text-box">


                                    <div class="text-container">
                                        <h6 style="font-size: 18px;" class="text-muted">PROJECT QUALITY</h6>
                                        <p>Quality is at the heart of
                                            kalyankari Softnet!
                                            Beacuase for us quality
                                            is not a task,
                                            it is habbit</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <a href="">
                            <div class="card-flyer">
                                <div class="text-box">

                                    <div class="text-container">
                                        <h6 style="font-size: 18px;" class="text-muted">PROJECT SCOPE</h6>
                                        <p>A factor that helps
                                            determining the
                                            success
                                            and feasibility of a
                                            project. </p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <a href="">
                            <div class="card-flyer">
                                <div class="text-box">


                                    <div class="text-container">
                                        <h6 style="font-size: 18px;" class="text-muted">COST</h6>
                                        <p>High quality at an
                                            affordable
                                            cost is every business'
                                            desire
                                            and we help you
                                            achieve it</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                </div>
            </div>
        </div>

    </div>


    <!-- What Sets Us Apart end -->

    <!-- our project section start  -->
    <h4 class="text-center" style="margin-top: 60px; margin-bottom: 30px; font-weight: 700;">Our Projects</h4>
    <img style="height: auto; " class="img-fluid" src="../../assets/images/portfolio_underline2.png" alt="our projects">
    <div class="row" style="margin-top: -60px;">
        <!-- Topic Cards -->
        <div id="cards_landscape_wrap-2">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">

                        <div class="card-flyer">
                            <div class="text-box">

                                <div class="text-container">
                                    <h6 style="font-size: 18px;"><i class="fa fa-external-link" aria-hidden="true"></i>
                                    </h6>
                                    <a href="https://freshvegstore.com/" target="_blank">Visit Freshveg Store</a>
                                    <div class="py-2">
                                        <img style="height: 60px; width: auto;"
                                            src="../../assets/images/frshveglogo.jpeg" alt="Fresh Veg Store">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">

                        <div class="card-flyer">
                            <div class="text-box">

                                <div class="text-container">
                                    <h6 style="font-size: 18px;"><i class="fa fa-external-link" aria-hidden="true"></i>
                                    </h6>
                                    <a href="https://wow2store.com/" target="_blank">Visit Wow2store</a>
                                    <div class="py-2">
                                        <img style="height: 60px; width: auto;"
                                            src="../../assets/images/wow2storelogo.png" alt="wow2store">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">

                        <div class="card-flyer">
                            <div class="text-box">


                                <div class="text-container">
                                    <h6 style="font-size: 18px;"><i class="fa fa-external-link" aria-hidden="true"></i>
                                    </h6>
                                    <a href="https://vansh.ca/" target="_blank">Visit Vansh.ca</a>
                                    <div class="py-2">
                                        <img style="height: 60px; width: auto;"
                                            src="../../assets/images/vanshlogo.jpg" alt="vansh- property dealing">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">

                        <div class="card-flyer">
                            <div class="text-box">

                                <div class="text-container">
                                    <h6 style="font-size: 18px;"><i class="fa fa-external-link" aria-hidden="true"></i>
                                    </h6>
                                    <a href="pioneermultiservices.com" target="_blank">Visit Pioneermultiservices</a>
                                    <div class="py-2">
                                        <img style="height: 60px; width: auto;"
                                            src="../../assets/images/pioneerlogo.jpeg" alt="Pioneer multi services">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">

                        <div class="card-flyer">
                            <div class="text-box">


                                <div class="text-container">
                                    <h6 style="font-size: 18px;"><i class="fa fa-external-link" aria-hidden="true"></i>
                                    </h6>
                                    <a href="https://www.yuwee.com/" target="_blank">Visit yuwee</a>
                                    <div class="py-2">
                                        <img style="height: 60px; width: auto;"
                                            src="../../assets/images/yuwee-logo.png" alt="Yuwee">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">

                        <div class="card-flyer">
                            <div class="text-box">


                                <div class="text-container">
                                    <h6 style="font-size: 18px;"><i class="fa fa-external-link" aria-hidden="true"></i>
                                    </h6>
                                    <a href="www.softnetworld.com" target="_blank">Visit softnetworld</a>
                                    <div class="py-3">
                                        <img style="height: 60px; width: auto;"
                                            src="../../assets/images/softnetworld_logo.jfif" alt="Softnet World">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">

                        <div class="card-flyer">
                            <div class="text-box">


                                <div class="text-container">
                                    <h6 style="font-size: 18px;"><i class="fa fa-external-link" aria-hidden="true"></i>
                                    </h6>
                                    <a href="www.computerpathsala.com" target="_blank">Visit Computer Pathsala <br> (
                                        Work In Progress )</a>

                                        <div class="py-1">
                                            <img style="height: 90px; width: auto;" src="../../assets/images/cp_logo.png" alt="Computer pathsala">
                                        </div>    
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">

                        <div class="card-flyer">
                            <div class="text-box">


                                <div class="text-container">
                                    <h6 style="font-size: 18px;"><i class="fa fa-external-link" aria-hidden="true"></i>
                                    </h6>
                                    <a href="https://www.harshpay.com/W
                        eb/Default.aspx" target="_blank">Visit Harsh Pay <br> ( Work In Progress )</a>

                                    <div class="py-2">
                                        <img style="height: 60px; width: auto;"
                                            src="https://www.harshpay.com/Web/Images/Front/logo.png" alt="harshpay">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>

    </div>


    <!-- our project section end -->


</div>


<app-footer></app-footer>