import { Component, OnInit } from '@angular/core';
import { Meta , Title } from '@angular/platform-browser';

@Component({
  selector: 'app-e-commerce',
  templateUrl: './e-commerce.component.html',
  styleUrls: ['./e-commerce.component.css']
})
export class ECommerceComponent implements OnInit {

  constructor(private title : Title, private meta : Meta) { 
    this.title.setTitle('ecommerce website building | Kalyankari Softnet');
    this.meta.addTag({name: 'description', content : 'affordable ecommerce website bilding, best buy ever, best support, 24/7'});
    this.meta.addTag({name : 'keywords', content : 'ecommerce frontend design, API, robost backend, best DB selection, idea to reality, Front End Modules , Admin Panel Modules , Client Panel Modules , Payment Options, Profile Correction, Notifications, order detail, order tracking, order history, booking history, product review, secure login, customer management, CMS, courier tracking, attractive dashboard, account management, coupon code generation, Bar code and QR code genarator, instat notification, product view, price lookup, login, signup, checkout as a guest, cart'})
  }

  ngOnInit(): void {
  }

}
