import { Component, OnInit } from '@angular/core';
import { Meta , Title } from '@angular/platform-browser';

@Component({
  selector: 'app-case-study',
  templateUrl: './case-study.component.html',
  styleUrls: ['./case-study.component.css']
})
export class CaseStudyComponent implements OnInit {

  constructor(private caseStudyTitle : Title, private meta: Meta) { 
    this.caseStudyTitle.setTitle('Case Study | kalyankari Softnet');
    this.meta.addTag({name : 'description', content : 'The Best place to hide a dead body is the second page of google search | Best SEO'});
    this.meta.addTag({name : 'keywords' , content : 'Case study, customer engagement, google search, project goal, our solution, why kalyankari softnet ?'})
  }

  ngOnInit(): void {
  }

}
