import { Component, OnInit } from '@angular/core';
import { Meta , Title } from '@angular/platform-browser';

@Component({
  selector: 'app-custom-website-design',
  templateUrl: './custom-website-design.component.html',
  styleUrls: ['./custom-website-design.component.css']
})
export class CustomWebsiteDesignComponent implements OnInit {

  constructor(private customTitle : Title, private meta: Meta) {
    this.customTitle.setTitle('Custom Web Design | Kalyankari Softnet');
    this.meta.addTag({name: 'description' ,  content : 'unique custom web design, web development'});
    this.meta.addTag({name: 'keywords', content: 'good quality design, easy to load, less loading time, light and unique design, virtual business store'})
   }

  ngOnInit(): void {
  }

}
