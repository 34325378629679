<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PQ2B553"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> 
<!-- End Google Tag Manager (noscript) -->
<app-navbar></app-navbar>

<body>
    <div class="c-hero">
        <div class="c-hero__content">
          <h1 class="c-hero__headline" style="font-weight: 800; margin-top: -30px;">Custom<br>Web Design</h1>
        </div>
        <div class="c-hero__img-container c-hero--fade-in--img"></div>
      </div>

    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h2  style="font-weight: 900; margin-top: 20px;">Custom Website Design !!</h2>
                <p class=" text-muted text-justify" style=" margin-top: 18px;">Website design follows many consideration. A
                    good quality design comprises of interactive and attractive graphic user interface, plus properly
                    built backend coding to make it browse friendly and easy to load.</p>

                <p class="text-justify text-muted" style="margin-top: 20px !important; ">We understand that every business has a different requirement and every business person has a
                    different set of mind towards his/her business. So, we keep your consideration in mind while
                    designing your online virtual business store. We take your complete requirement, analyse it and work
                    on the project to give it a final shape to suit you and your client’s taste.</p>

            </div>

            <div class="col-md-6">
                <img src="../../assets/images/customwebsitedesign.gif" class="img-fluid" alt="">

            </div>

            <div class="col-md-12">
                <app-contact-us></app-contact-us>

            </div>

        </div>

    </div>

</body>

<app-footer></app-footer>