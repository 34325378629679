<div style="background-color: black;">
    <div class="container-fluid">
        <div class="row" style=" background-color: lightgray;">

            <div class="col-md-12 text-center" style="margin-top: 20px; margin-bottom: 10px;">
                <h5 style=" padding-left: 10px; padding-right: 10px;  font-size: 16px; font-weight: 700;">
                    Call us today at <span class="text-danger">+ 91 9607926665</span> and get free support </h5>

                <p style="color: gray; padding-left: 10px; padding-right: 10px; margin-top: 10px; font-size: 13px;">We
                    strive to provide Our Customers with Top Notch Support to make their Wonderful Website</p>



                <div id="livesupport" type="button" class="btn btn-outline-danger" style=" 
                       color: black;  font-weight: 900; font-size: 20px; padding-left: 30px; padding-right: 30px;">
                    LIVE SUPPORT</div>


            </div>



        </div>

    </div>

</div>
<!-- ...................................................start footer...................... -->

<body>
    <div class="container" style="padding-top: 30px;">
        <div class="row">
            <div class="col-md-3">
                <h6 class="text-center" style="color: black; font-weight: 700 !important;"> SERVICES</h6>
                <img class="img-fluid"  src="../../../assets/images/heading-underline1.png" alt="kalyankari services" srcset="">
                <ul style="color: white;">

                    <a [routerLink]="['/web-designing']">
                        <li> <i class="fa fa-television" aria-hidden="true"></i>
                            Web Designing</li>
                    </a>

                    <a [routerLink]="['/domain-registration']">
                        <li> <i class="fa fa-venus-double" aria-hidden="true"></i>
                            Domain Registration</li>
                    </a>

                    <a [routerLink]="['/email-hosting']">
                        <li><i class="fa fa-shopping-bag" aria-hidden="true"></i>
                            Email Hosting</li>
                    </a>

                    <a [routerLink]="['/logo-design']">
                        <li><i class="fa fa-heart" aria-hidden="true"></i>
                            Logo Designing</li>
                    </a>

                    <a [routerLink]="['/web-hosting']">
                        <li><i class="fa fa-globe" aria-hidden="true"></i>
                            Web Hosting</li>
                    </a>
                    <a [routerLink]="['/E-Commerce']">
                        <li><i class="fa fa-cart-arrow-down" aria-hidden="true"></i>
                            E-Commerce</li>
                    </a>
                    <a [routerLink]="['/software-Development']">
                        <li> <i class="fa fa-laptop" aria-hidden="true"></i>
                            Software Development</li>
                    </a>

                    <a [routerLink]="['/custom-website-design']">
                        <li><i class="fa fa-asterisk" aria-hidden="true"></i>
                            Custom Website Design </li>
                    </a>

                    <a [routerLink]="['/Best-seo-plan']">
                        <li><i class="fa fa-rocket" aria-hidden="true"></i>
                            SEO </li>
                    </a>

                </ul>

            </div>

            <div class="col-md-3">
                <h6 class="text-center" style="color: black; font-weight: 700 !important;"> OTHER SERVICES</h6>
                <img class="img-fluid"  src="../../../assets/images/heading-underline1.png" alt="kalyankari Other services" srcset="">
                <ul style="color: white;">

                    <a href="">
                        <li><i class="fa fa-certificate" aria-hidden="true"></i>
                            AWS </li>
                    </a>
                    <a href="">
                        <li><i class="fa fa-certificate" aria-hidden="true"></i>
                            Digital certificate</li>
                    </a>

                    <a href="">
                        <li><i class="fa fa-cloud-upload" aria-hidden="true"></i>
                            Linux & Windows</li>
                    </a>

                    <a href="">
                        <li><i style="background-color: black; border-radius: 50%;" class="fa fa-superpowers"
                                aria-hidden="true"></i>
                            Branding Services</li>
                    </a>

                    <a href="">
                        <li><i class="fa fa-cloud" aria-hidden="true"></i>
                            Cloud Services </li>
                    </a>

                    <a href="">
                        <li><i style="background-color: black; border-radius: 50%;" class="fa fa-deviantart"
                                aria-hidden="true"></i>
                            DevOps Services </li>
                    </a>

                    <a href="">
                        <li><i class="fa fa-share-square" aria-hidden="true"></i>
                            Social Media Marketing</li>
                    </a>

                    <a href="">
                        <li><i class="fa fa-suitcase" aria-hidden="true"></i>
                            Google Business GSuit</li>
                    </a>

                    <a href="">
                        <li><i class="fa fa-medkit" aria-hidden="true"></i>
                            Job Support & Training</li>
                    </a>

                </ul>

            </div>

            <div class="col-md-3">
                <h6 class="text-center" style="color: black; font-weight: 700 !important;">RESOURCES</h6>
                <img class="img-fluid"  src="../../../assets/images/heading-underline1.png" alt="kalyankari Resources" srcset="">
                <ul style="color: white;">
                    <a [routerLink]="['/branding']">
                        <li><i class="fa fa-leaf" aria-hidden="true"></i>
                            Branding</li>
                    </a>
                    <a [routerLink]="['/web-development-case-studies']">
                        <li><i class="fa fa-coffee" aria-hidden="true"></i>
                            Case Studies</li>
                    </a>
                    <a [routerLink]="['/FAQ']">
                        <li><i class="fa fa-question-circle" aria-hidden="true"></i>
                            FAQ</li>
                    </a>


                </ul>

            </div>

            <div class="col-md-3 ">
                <h6 style="color: black; font-weight: 700 !important;" class="text-center"> COMPANY EXPLORE </h6>
                <img class="img-fluid"  src="../../../assets/images/heading-underline1.png" alt="Company Explore" srcset="">


                <ul style="color: white;">
                    <a [routerLink]="['/aboutUs']">
                        <li><i class="fa fa-user" aria-hidden="true"></i>
                            About Us</li>
                    </a>
                    <a [routerLink]="['/career']">
                        <li><i class="fa fa-graduation-cap" aria-hidden="true"></i>
                            Careers</li>
                    </a>
                    <a [routerLink]="['/contact-us']">
                        <li><i class="fa fa-envelope-open" aria-hidden="true"></i>
                            Contact Us</li>
                    </a>
                    <!-- <a [routerLink]="['/blog']">
                        <li><i class="fa fa-rss-square" aria-hidden="true"></i>
                            Blog</li>
                    </a> -->
                    <a [routerLink]="['/privacy-policy']">
                        <li><i class="fa fa-eye" aria-hidden="true"></i>
                            Privacy policy</li>
                    </a>

                    <a [routerLink]="['/our-work-process']">
                        <li><i class="fa fa-tasks" aria-hidden="true"></i>
                            Work Process</li>
                    </a>

                    <a [routerLink]="['/disclaimer']">
                        <li><i style="background-color: black; border-radius: 50%;" class="fa fa-cc-diners-club"
                                aria-hidden="true"></i>
                            Disclaimer</li>
                    </a>

                    <a [routerLink]="['/terms-condition']">
                        <li><i style="background-color: black;  border-radius: 50%;" class="fa fa-file-text-o"
                                aria-hidden="true"></i>
                            Terms & Conditions</li>
                    </a>



                </ul>




            </div>

        </div>

    </div>



    <footer>
        <div class="box links txtsize">
            <h5 class="mt-4 p-2" style="color: cadetblue; font-size: 14px; font-weight: 700;">VISIT US</h5>
            <img style="width: 320px;" class="img-fluid" src="../../../assets/images/visit&subscribe_underline.png" alt="VISIT Us">
            <div>
                <img class="img-fluid" style="max-width: 200px;" src="../../../assets/images/1.png" alt="">
            </div>
            <!-- <P style="text-shadow: 2px solid white; color: #1484d3;" class="mt-4">Kalayankari softnetworld Pvt. Ltd, Lorem
                ipsum dolor sit amet, consectetur adipisicing elit. Alias, harum?</P> -->

            <p class="my-2" style="text-shadow: 2px solid white; color: cadetblue;">Kalyankari Softnet Pvt. Ltd
                address : 199, PKT-11, Sec A6, narela, Delhi 110040, IN</p>





        </div>
        <div class="box social txtsize">
            <h5 class="mt-4  p-2" style="color: cadetblue; font-size: 14px; font-weight: 700;">FOLLOW US ON SOCIAL MEDIA
            </h5>
            <img class="img-fluid" src="../../../assets/images/footer_underline_below.png" alt="FOLLOW US">
            <a href="https://www.facebook.com/kalyankarisoftnet" target="_blank" style="color: #4064AC;"> <i
                    class="fa fa-facebook-square" aria-hidden="true"> <span style="color: cadetblue;">&nbsp;
                        Facebook</span></i></a>

            <a href="https://www.linkedin.com/company/kalyankari-softnet-private-limited" target="_blank"
                style="color: #4064AC;"> <i class="fa fa-linkedin-square" aria-hidden="true"><span
                        style="color: cadetblue;">&nbsp; Linkedin </span> </i></a>

            <a href="https://www.instagram.com/kalyankarisoftnet/" target="_blank" style="color: #4064AC;"> <i
                    class="fa fa-instagram" aria-hidden="true"><span style="color: cadetblue;">&nbsp; Instagram </span>
                </i></a>

            <!-- <a href="#" class="fa fa-youtube"></a> -->



            <!-- <p class="txtsize" style="color: gray;">Kalyankari Softnetworld Awesome ERP for Your School, Ecommerse for
                your Business & Customized Software as per your Demand. </p> -->
            <hr class="hr">
            <p id="copyright" style="font-size: 13px; color: cadetblue;">
                Copyright ©️ 2017 - 2020 Kalyankari Softnet Pvt Ltd, Web Design and Website Development Company, All
                Right Reserved.
            <p>
        </div>
        <div class="box subscribe">
            <h5 class="mt-4  p-2" style="color: cadetblue; font-size: 14PX; font-weight: 700;">SUBSCRIBE TO OUR
                NEWSLETTER
            </h5>
            <img class="img-fluid" src="../../../assets/images/visit&subscribe_underline.png" alt="news letter subscribe">
            <form action="submit">
                <input type="email" required placeholder="Your email here...">
                <button type="submit" style="color: cadetblue;">Subscribe</button>
            </form>
        </div>
    </footer>
</body>