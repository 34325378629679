

<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PQ2B553"
      height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> 
<!-- End Google Tag Manager (noscript) -->
      
<app-navbar></app-navbar>
<app-hero></app-hero>
<app-offers></app-offers>
<!-- <app-welcome-header></app-welcome-header> -->
<app-card-view-tabs></app-card-view-tabs>
<app-technology-used></app-technology-used>
<app-our-value></app-our-value>
<app-service-tab></app-service-tab>
<app-seo></app-seo>

<app-whythepopweb></app-whythepopweb>
<app-our-work></app-our-work>
<app-revew-card></app-revew-card>
<app-contact-us></app-contact-us>
<app-happy-client></app-happy-client>

<app-footer></app-footer>