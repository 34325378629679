import { Component, OnInit } from '@angular/core';
import { Meta , Title } from '@angular/platform-browser';

@Component({
  selector: 'app-web-desining',
  templateUrl: './web-desining.component.html',
  styleUrls: ['./web-desining.component.css']
})
export class WebDesiningComponent implements OnInit {

  constructor(
    private title : Title,
    private meta : Meta
  ) { 
    this.title.setTitle('Web Designing | kalyankari Softnet');
    this.meta.addTag({name : 'description', content : 'Web Design And Development'});
    this.meta.addTag({name : 'keywords', content: 'amazing website development, analysis, budget, confirmation, consultation, conceptualization, maintanance, update, domain hosting, design , develop, user friendly, powerful website, dedicated team, visual sappy, alluring'})
  }

  ngOnInit(): void {
  }

}
