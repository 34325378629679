import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConatctUsComponent } from './conatct-us/conatct-us.component';
import { WorkProcessComponent } from './work-process/work-process.component';
import { BrandingComponent } from './branding/branding.component';
import { CaseStudyComponent } from './case-study/case-study.component';
import { FAQComponent } from './faq/faq.component';
import { WebDesiningComponent } from './web-desining/web-desining.component';
import { DomainregistrationComponent } from './domainregistration/domainregistration.component';
import { EmailHostingComponent } from './email-hosting/email-hosting.component';
import { LogoDesignComponent } from './logo-design/logo-design.component';
import { WebHostingComponent } from './web-hosting/web-hosting.component';
import { ECommerceComponent } from './e-commerce/e-commerce.component';
import { SoftwareDevelopmentnComponent } from './software-developmentn/software-developmentn.component';
import { CustomWebsiteDesignComponent } from './custom-website-design/custom-website-design.component';
import { SeoPageComponent } from './seo-page/seo-page.component';
import { AboutusPageComponent } from './aboutus-page/aboutus-page.component';
import { BlackHatSEO8Component } from './black-hat-seo8/black-hat-seo8.component';
import { CareerPageComponent } from './career-page/career-page.component';
import { SimpleSeoAuditComponent } from './simple-seo-audit/simple-seo-audit.component';
import { OnPageSeoMistakeComponent } from './on-page-seo-mistake/on-page-seo-mistake.component';
import { CloudServiceComponent } from './cloud-service/cloud-service.component';
import { DevopsServiceComponent } from './devops-service/devops-service.component';
import { PortfolioComponent } from './portfolio/portfolio.component';


const routes: Routes = [
  {path : '', component : HomeComponent},
  {path : 'contact-us', component : ConatctUsComponent},
  {path : 'our-work-process', component : WorkProcessComponent },
  {path : 'branding', component: BrandingComponent},
  {path: 'web-development-case-studies', component : CaseStudyComponent},
  {path : 'FAQ', component : FAQComponent},
  {path: 'web-designing', component : WebDesiningComponent},
  {path: 'domain-registration', component : DomainregistrationComponent},
  {path : 'email-hosting',component : EmailHostingComponent},
  {path:'logo-design', component: LogoDesignComponent},
  {path : 'web-hosting', component: WebHostingComponent},
  {path : 'E-Commerce', component : ECommerceComponent},
  {path: 'software-Development', component : SoftwareDevelopmentnComponent},
  {path : 'custom-website-design', component : CustomWebsiteDesignComponent},
  {path : 'Best-seo-plan', component : SeoPageComponent},
  {path : 'cloud-services', component : CloudServiceComponent},
  {path : 'devops-services', component : DevopsServiceComponent},
  {path:'aboutUs', component: AboutusPageComponent},
  {path : 'dangerous-black-hat-seo-techniques', component : BlackHatSEO8Component},
  {path : 'Why-seo_audit_important' , component : SimpleSeoAuditComponent},
  {path : 'basic-on-page-seo-mistakes', component : OnPageSeoMistakeComponent},
  {path : 'career', component : CareerPageComponent},
  {path : 'privacy-policy' , component : PrivacyPolicyComponent},
  {path : 'terms-condition', component : TermsConditionComponent},
  {path : 'disclaimer', component : DisclaimerComponent},
  {path: 'portfolio', component : PortfolioComponent}
];

@NgModule({

  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]

  // imports: [RouterModule.forRoot(routes),
  // ],
  // exports: [RouterModule]
})
export class AppRoutingModule { }
