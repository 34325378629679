import { Component, OnInit } from '@angular/core';
import { Meta , Title } from '@angular/platform-browser';

@Component({
  selector: 'app-conatct-us',
  templateUrl: './conatct-us.component.html',
  styleUrls: ['./conatct-us.component.css']
})
export class ConatctUsComponent implements OnInit {

  constructor(private meta : Meta, private title : Title) { 
    this.title.setTitle("Contact Us | kalyankari Softnet");
    this.meta.addTag({name : 'description', content : 'contact detail of kalyankari softnet'});
    this.meta.addTag({name : 'keywords', content : 'contact detail, Email address, sale email address, phone number, 24/7 support'});
  }

  ngOnInit(): void {
  }

}
