import { Component, OnInit } from '@angular/core';
import { Meta , Title } from '@angular/platform-browser';

@Component({
  selector: 'app-devops-service',
  templateUrl: './devops-service.component.html',
  styleUrls: ['./devops-service.component.css']
})
export class DevopsServiceComponent implements OnInit {

  constructor(private titleDevops : Title , private meta : Meta) {
    this.titleDevops.setTitle('Devops For Digital | Kalyankari Softnet');
    this.meta.addTag({name: 'Description', content : 'Devops For Digital | Best Devops service provider in India'});
    this.meta.addTag({name : 'keywords', content : 'devops engineering, cloud engineering, digital products, best service , best support 24/7, contact our expert, contiuous delivery, continuous integration, continuous testing, progresive Delivery'})
   }

  ngOnInit(): void {
  }

}
