import { Component, OnInit } from '@angular/core';
import { Meta , Title } from '@angular/platform-browser';

@Component({
  selector: 'app-email-hosting',
  templateUrl: './email-hosting.component.html',
  styleUrls: ['./email-hosting.component.css']
})
export class EmailHostingComponent implements OnInit {

  constructor(private title : Title, private meta : Meta) { 
    this.title.setTitle('Email Hosting | kalyankari Softnet');
    this.meta.addTag({name: 'description', content : 'Simple, Powerful Email for Small Businesses'});
    this.meta.addTag({name: 'keywords', content : 'free email hosting, powerful email hosting, gmail accounts, Intuitive and Responsive Design, Inbuilt Virus Protection, 5GB Storage Plus Backup, responsive email design, calender, contacts, tasks, social media, inbox, spam box, reliable email hosting'})
  }

  ngOnInit(): void {
  }

}
