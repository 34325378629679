import { Component, OnInit } from '@angular/core';
import {Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-aboutus-page',
  templateUrl: './aboutus-page.component.html',
  styleUrls: ['./aboutus-page.component.css']
})
export class AboutusPageComponent implements OnInit {

  constructor(
    private meta : Meta,
    private titleService : Title
  ) { 
    this.titleService.setTitle('About us | Kalyankari Softnet');
    this.meta.addTag({name : 'description', content : 'About us | Kalyankari Softnet'});
    this.meta.addTag({name: 'keywords', content : 'about company , kalyankari softnet, explore company, company detail, company overview, how we work, our jouney, mission and vision'})
  }

  ngOnInit(): void {
  }

}
