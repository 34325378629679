<body>
    

<div class="sectiontitle">
    <h2>Projects statistics</h2>
    <span class="headerLine"></span>
</div>
<div id="projectFacts" class="sectionClass" style="margin-bottom: 0px !important;">
    <div class="fullWidth eight columns">
        <div class="projectFactsWrap ">
            <div class="item wow fadeInUpBig animated animated" data-number="12" style="visibility: visible;">
                <i class="fa fa-briefcase"></i>
                <p id="number1" class="number">1010</p>
                <span></span>
                <p>Projects done</p>
            </div>
            <div class="item wow fadeInUpBig animated animated" data-number="55" style="visibility: visible;">
                <i class="fa fa-smile-o"></i>
                <p id="number2" class="number">1003</p>
                <span></span>
                <p>Happy clients</p>
            </div>
            <div class="item wow fadeInUpBig animated animated" data-number="359" style="visibility: visible;">
                <i class="fa fa-coffee"></i>
                <p id="number3" class="number">3026</p>
                <span></span>
                <p>Cups of coffee</p>
            </div>
            <div class="item wow fadeInUpBig animated animated" data-number="246" style="visibility: visible;">
                <i class="fa fa-camera"></i>
                <p id="number4" class="number">246</p>
                <span></span>
                <p>Photos taken</p>
            </div>
        </div>
    </div>
</div>



</body>