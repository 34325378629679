<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PQ2B553"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> 
<!-- End Google Tag Manager (noscript) -->

<app-navbar></app-navbar>

<!--=======================Hero section start============================-->



    <div class="text-center my-4">
        <div class="header-bg" > <img class="img-fluid" src="../../assets/images/ecommerceNewDevelopment_.png" alt="header-image" /> </div>
        <div class="header-content">
            <!-- <p class="heading-1" style="color: #462b31;"></p> -->
            <h1 style="font-size: 25px;">Welcome to Brand<span>y</span> Unique Design</h1>
            <img style="width: 380px;" class="img-fluid" src="../../assets/images/portfolio underline1.png" alt="ecommerce Development">
            <p style="color: #a51457; margin-top: 52px !important; padding-right: 40px; padding-left: 40px; font-size: 18px;" >KalyankariSoftnet Provides best experience in Ecommerse Site. Our
                Cost Effective Plan and enhanced features make this
                product The Best Seller. Designed and Developed on Angular latest version platform with effective use of
                MVVM, nodeJs Java Script and more of latest technologies
                make our site diferent.</p>

                <div>
                    <button class=" btn btn-outline-danger" [routerLink]="['/contact-us']">Contact us</button>
                </div>

            
        </div>
    </div>


<!--===================Hero section end ====================-->






<div class="container">
    <div class="row" style="margin-top: 50px;">


        <div class="col-md-6">

            <div class="card"
                style="padding: 40px; margin-top: 20px; margin-bottom: 20px; background-image: url(../../assets/images/left-bg-decor.png);">
                <h4 class="text-center text-muted" style="font-weight: 900; padding-top: 10px;">IDEA TO REALITY</h4>
                <p class="text-justify" style="padding: 10px;">These days, E-Commerce website has become mandatory
                    for online trading. We are providing you highly developed interactive graphic user interface to
                    captivate customers. Kalyankari Softnet is committed to helping you in making your idea into reality.
                    Here at The Kalyankari shapes your concepts, at very affordable prices. To initiate, you need to
                    consult our team. Free consultations
                    and thorough analyses for your project are available for you.</p>
            </div>


        </div>

        <div class="col-md-6">
            <div style="margin-top: 20px; margin-bottom: 20px;">
                <h3 class="text-muted" style="padding: 10px; font-weight: 600; font-size: 20px;">Basic points which
                    you need to
                    concern, when chating/talking with our team is : </h3>

                <ul>
                    <li>What your business is about ? </li>
                    <li>Products/services you offer ? </li>
                    <li>Reference, based on design you are looking for ?</li>
                    <li>Functionality you think would be required ? </li>
                    <li>Payment gateway or cash on delivery ? </li>
                    <li>Any other features ? </li>
                </ul>

            </div>


        </div>


    </div>

</div>

<section class="wrapper">
    <div class="container-fostrap">

        <div class="content">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-sm-4">
                        <div class="card">
                            <a class="img-card"
                                href="http://www.fostrap.com/2016/03/bootstrap-3-carousel-fade-effect.html">
                                <img src="../../assets/images/fontendmodule.png" />
                            </a>
                            <div class="card-content">
                                <h4 class="card-title text-muted">
                                    <a class="text-muted" style="font-size: 25px;"
                                        href="http://www.fostrap.com/2016/03/bootstrap-3-carousel-fade-effect.html">Front
                                        End Modules
                                    </a>
                                </h4>
                                <h6 class="text-muted">Front End Modules</h6>
                                <p class="text-muted text-justify" style="font-size: 13px;">Responsive Design for All
                                    Device Resolution GUI And Admin Panel </p>
                                <ul>
                                    <li>Product View</li>
                                    <li>Price Lookup</li>
                                    <li>Login/signup</li>
                                    <li>Checkout as a guest</li>
                                    <li>Cart</li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-4">
                        <div class="card">
                            <a class="img-card"
                                href="http://www.fostrap.com/2016/03/5-button-hover-animation-effects-css3.html">
                                <img src="../../assets/images/Admin Panel Modules.jpg" />
                            </a>
                            <div class="card-content">
                                <h4 class="card-title text-muted">
                                    <a class="text-muted" style="font-size: 25px;"
                                        href="http://www.fostrap.com/2016/03/5-button-hover-animation-effects-css3.html">Admin
                                        Panel Modules
                                    </a>
                                </h4>
                                <ul>
                                    <li>Secure Login</li>
                                    <li>Customer Management</li>
                                    <li>Review Management</li>
                                    <li>CMS (Content Management System)</li>
                                    <li>Courier Tracking</li>
                                    <li>Attractive Dashboard with instant notification.</li>
                                    <li>Address Label Printout </li>
                                    <li>Accounts Management</li>
                                    <li>Coupon Code Generator</li>
                                    <li>Bar code and QR Code Generator</li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-4">
                        <div class="card">
                            <a class="img-card"
                                href="http://www.fostrap.com/2016/03/5-button-hover-animation-effects-css3.html">
                                <img src="../../assets/images/Client Panel Modules.jpg" />
                            </a>
                            <div class="card-content">
                                <h4 class="card-title text-muted">
                                    <a class="text-muted" style="font-size: 25px;"
                                        href="http://www.fostrap.com/2016/02/awesome-material-design-responsive-menu.html">
                                        Client Panel Modules
                                    </a>
                                </h4>
                                <ul>
                                    <li>Payment Options</li>
                                    <li>Profile Correction</li>
                                    <li>Notifications</li>
                                    <li>Order Details</li>
                                    <li>Order Tracking</li>
                                    <li>Booking History</li>
                                    <li>Product Review</li>
                                </ul>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<div class="container">
    <div class="row">
        <div class="col-md-12">
            <h4 class="text-center text-muted" style="font-weight: 700; font-size: 17px;">Based on the above parameters,
                we would suggest
                you best design and budget along with time, it will take to complete the project.</h4>
            <h3 class="text-center text-muted" style="font-weight: 700;">Looking forward to a virtual store ? </h3>

            <app-contact-us></app-contact-us>

        </div>

    </div>

</div>





<app-footer></app-footer>