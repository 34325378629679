import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-happy-client',
  templateUrl: './happy-client.component.html',
  styleUrls: ['./happy-client.component.css']
})
export class HappyClientComponent implements OnInit {

  constructor(){}
  

  ngOnInit(): void {
  }

}
