import { Component, OnInit } from '@angular/core';
import { Meta , Title } from '@angular/platform-browser';

@Component({
  selector: 'app-logo-design',
  templateUrl: './logo-design.component.html',
  styleUrls: ['./logo-design.component.css']
})
export class LogoDesignComponent implements OnInit {

  constructor(private meta : Meta, private title : Title) { 
    this.title.setTitle('Logo design | Kalyankari Softnet');
    this.meta.addTag({name : 'description', content : 'free logo design , affordable logo design'});
    this.meta.addTag({name:'keywords', content : 'free logo design, affordable price, unique logo design, company logo design, starter, premium, best buy'})
  }

  ngOnInit(): void {
  }

}
