<div class="container">
<h2 class="text-center">Technology We Use</h2>
<h5 class="text-center text-muted text-justify my-4 " style="margin-bottom: 10px;">We make the technology talk! The wide variety of technologies used by our organization makes every complexity easy. 
    The variety of technology and our expertise on them are simply hard to find elsewhere.</h5>

    <div class="row">
        <div class="col-md-4 ">
            <div class="card " style="width: 18rem;">
                <div class="card-body">
                 <img class="tech_logo" src="../../../assets/images/Angularlogo.png" alt="" srcset="">  <h4 class="card-title">Aangular</h4>
                <p class="card-text">Angular is a open source web framework for building single page mobile and desktop web applications</p>
                
                </div>
                </div>

        </div>

        <div class="col-md-4">
            <div class="card" style="width: 18rem;">
                <div class="card-body">
                    <img class="tech_logo" src="../../../assets/images/ajax.png" alt="" srcset=""> <h4 class="card-title">AJAX</h4>
                <p class="card-text">Now get richer and more responsive user-experience with AJAX</p>
                
                </div>
                </div>

        </div>

        <div class="col-md-4">
            <div class="card" style="width: 18rem;">
                <div class="card-body">
                    <img class="tech_logo" src="../../../assets/images/jquery.png" alt="" srcset=""> <h4 class="card-title">jQuery</h4>
                <p class="card-text">Easy to use, cross browser friendly and search engine optimized</p>
                
                </div>
                </div>

        </div>

    </div>


    <div class="row" style="padding-top: 20px;">
        <div class="col-md-4">
            <div class="card" style="width: 18rem;">
                <div class="card-body">
                    <img class="tech_logo" src="../../../assets/images/MongoDB.jpg" alt="" srcset=""> <h4 class="card-title">MongoDB</h4>
                <p class="card-text">For large data transactions and for implementing a scalable model</p>
                
                </div>
                </div>

        </div>

        <div class="col-md-4">
            <div class="card" style="width: 18rem;">
                <div class="card-body">
                    <img class="tech_logo" src="../../../assets/images/bootstrap-stack.png" alt="" srcset=""> <h4 class="card-title">Bootstrap</h4>
                <p class="card-text">For making website responsive according to your machine, we use css too </p>
                
                </div>
                </div>

        </div>

        <div class="col-md-4">
            <div class="card" style="width: 18rem;">
                <div class="card-body">
                    <img class="tech_logo" src="../../../assets/images/firebase.png" alt="" srcset=""> <h4 class="card-title">Firebase DB</h4>
                <p class="card-text">Real time syncing for JSON data The Firebase Realtime Database is a cloud-hosted NoSQL database</p>
                
                </div>
                </div>

        </div>

    </div>

    <!-- .........3rd row............ -->

    <div class="row" style="padding-top: 20px;">
        <div class="col-md-4">
            <div class="card" style="width: 18rem;">
                <div class="card-body">
                    <img class="tech_logo" src="../../../assets/images/html5.png" alt="" srcset=""> <h4 class="card-title">HTML 5</h4>
                <p class="card-text">HTML5 is a markup language used for structuring and presenting content on the World Wide Web</p>
                
                </div>
                </div>

        </div>

        <div class="col-md-4">
            <div class="card" style="width: 18rem;">
                <div class="card-body">
                    <img class="tech_logo" src="../../../assets/images/wordpresslogo1.png" alt="" srcset=""> <h4 class="card-title">WordPress</h4>
                <p class="card-text">WordPress is a free and open-source content management system written in PHP and paired with a MySQL or MariaDB database. </p>
                
                </div>
                </div>

        </div>

        <div class="col-md-4">
            <div class="card" style="width: 18rem;">
                <div class="card-body">
                    <img class="tech_logo" src="../../../assets/images/seologo.jpg" alt="" srcset=""> <h4 class="card-title">SEO</h4>
                <p class="card-text"> In simple terms, it means the process of improving your site to increase its visibility for relevant searches.</p>
                
                </div>
                </div>

        </div>

    </div>

</div>