<body>
	<h1 style=" margin-top: 90px; text-align: center; color:#113F64; font-weight: 700; font-size: 34px;"> <span>Don't Only
			Hear Us</span> But also see what our <span></span> client say</h1>

	<div class="section-slantedcard">
		<div class="content-slantedcard">
			<div style="position: relative">
				<div class="section-slantedcard-card section-slantedcard-bottom"></div>
				<div class="section-slantedcard-card section-slantedcard-top">
					<div class="content-slantedcard-img">
						<img src="../../../assets/images/alpanasinha.jpg" class="Alpana Sinha">
					</div>
					<h4 style="color: black;">Dr. Alpana Sinha</h4>
					<div class="content-slantedcard-text">
						<p style="color: gray;">" In the present scenario, no one can imagine a business without a site, Kalyankari softnet made me confident and comfortable within the first meeting. "</p>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="section-slantedcard" class="mt-2">
		<div class="content-slantedcard">
			<div style="position: relative">
				<div class="section-slantedcard-card section-slantedcard-bottom"></div>
				<div class="section-slantedcard-card section-slantedcard-top">
					<div class="content-slantedcard-img">
						<img src="../../../assets/images/rohansingh.jpg" class="">
					</div>
					<h4 style="color: black;">Mr. Rohan Rathor</h4>
					<div class="content-slantedcard-text">
						<p style="color: gray;">" I was not technically an expert, so it was difficult for me to cope with the new advancement of technology. I thanks Kalyankari softnet, who updated me through designing an amazing site. "</p>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="section-slantedcard">
		<div class="content-slantedcard">
			<div style="position: relative">
				<div class="section-slantedcard-card section-slantedcard-bottom"></div>
				<div class="section-slantedcard-card section-slantedcard-top">
					<div class="content-slantedcard-img">
						<img src="../../../assets/images/avatar2.png" class="">
					</div>
					<h4 style="color: black;">Er. Prince Talvaar</h4>
					<div class="content-slantedcard-text">
						<p style="color: gray;">" I was not technically an expert, so it was difficult for me to cope with the new advancement of technology. I thanks Kalyankari softnet, who updated me through designing an amazing site. "</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</body>