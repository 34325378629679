<div class="container">
    <h4 class="pt-4 text-center">Our Values</h4>
    <p class="text-center text-muted pb-3" style="font-size: 15px;">From the day first till now, we have faced many challenges and
        overcome them. Meanwhile, honesty is prevalent in our work. Our goal is your optimum satisfaction. We are
        committed to providing you the best useful, responsive, high grossing, and uniquely designed sites at an
        affordable cost. Due to these qualities, we manage to create our presence as an apex web improvement
        organization. Your trust matters for us.</p>

    <div class="row row-cols-1 row-cols-md-3">

        <div class="col mb-3">
            <div class="card">
                <img src="../../../assets/images/integrity.png" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">
                        Integrity</h5>
                    <p class="card-text">We stick to the corporate governance standards which create trust, morality, honesty, and accountability for our organization</p>
                </div>
            </div>
        </div>

        <div class="col mb-3">
            <div class="card">
                <img src="../../../assets/images/valuefor client.jpg" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Value for Clients</h5>
                    <p class="card-text">We take care of your every need. Because our every effort is the link to your success and we mean it from the bottom of our heart.</p>
                </div>
            </div>
        </div>

        <div class="col mb-3">
            <div class="card">
                <img src="../../../assets/images/quality.png" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Commitment</h5>
                    <p class="card-text">Your continued growth and maximum satisfaction are our commitment. And we are passionately looking forward to achieving this.</p>
                </div>
            </div>
        </div>

    </div>


</div>