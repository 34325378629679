import { Component, OnInit } from '@angular/core';
import {Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-domainregistration',
  templateUrl: './domainregistration.component.html',
  styleUrls: ['./domainregistration.component.css']
})
export class DomainregistrationComponent implements OnInit {

  constructor(
    private meta : Meta,
    private titleService : Title
  ) { 
    this.titleService.setTitle('Domain Registration');
    this.meta.addTag({name: 'description', content : 'Amazing Featutes of Kalyankari softnet'});
    this.meta.addTag({name: 'keywords', content : 'Free email accounts, DNS Management, Privacy Protection, Domain Forwarding, Domain Theft Protection, Easy to Use Control Panel, Free Mail Forwarding, Bulk Tools'})
  }

  ngOnInit(): void {
  }

}
