<app-navbar></app-navbar>

<body>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xl-12" id="hero">
                <div class="text-center" style="margin-top: 20px;">
                    <img class="img-fluid" id="imglogo" src="https://s.w.org/images/core/emoji/13.0.0/svg/1f4a1.svg"
                        alt="">
                </div>
                <h2 class="text-center" style="margin-top: 20px; font-weight: 900;">Basic On-Page SEO Mistakes That Can
                    Be the Downfall of Your Business</h2> <br>

                <p class="text-center" style="padding-right: 20px; padding-left: 20px; font-size: 20px;">A site is the
                    prime medium to connect with the world in the present date. Regardless of whether you make a site
                    for selling items, exhibiting your enthusiasm or develop your business, a site has an interminable
                    potential. Notwithstanding, individuals commit a few errors with their site that don't let them
                    appreciate the heaps of potential outcomes that a site has to bring to the table! All things
                    considered, there can be various reasons, yet the premier one that strikes our psyche is the on-page
                    SEO botches!</p>

            </div>




        </div>

    </div>


    <div class="container">
        <div class="row">
            <div class="col-md-12 col-xm-12 col-xl-12">
                <div class="card" style="margin: 20px;">
                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">Aside
                        from the nature of administrations/items, showcasing is the establishment of any business to
                        dominate. Furthermore, ineffectively done promoting can make any business sink. Also, with
                        regards to the advertising of a site, the primary thing that springs up in our mind is SEO
                        (website improvement). It enables a site to rank on the top page of Google and along these
                        lines, increasing more snaps from expected clients and having more deal questions!
                    </p>

                    <img class="img-fluid"
                        src="../../assets/images/SEO-Mistakes-That-Can-Be-the-Downfall-of-Your-Business.jpg"
                        alt="Seo mistake that can be spoil your website">

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        In the event that anybody of you has a little colleague with the SEO, you would know how
                        significant on-page improvement is. This is the structure square of an effective SEO crusade
                        which can never be dismissed in the event that you are focusing on highest level with no
                        disappointment. </p>

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        As you can accept, this humongous significance of on-page SEO is the motivation behind why we
                        are contacting all of you with the slip-ups that are regularly found. We get huge amounts of SEO
                        ventures where the on-page SEO botches are very obvious and some have the errors concealed where
                        it counts. In any case, your SEO endeavors and fantasy about positioning can go futile.

                        Before we come down to the missteps in on-page SEO, let us notice the reasons why the slip-ups
                        may occur.
                    </p>

                    <h4 class="text-muted"
                        style="font-weight: 900; padding-top: 18px; padding-left: 12px; padding-right: 12px;">Most
                        Common Reasons for On-Page SEO Mistakes</h4>

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        Since we get SEO ventures from all over the globe in a major number, we have the freedom to
                        examine each task and discover the shared characteristics among them. Our exploration group has
                        had the option to identify a couple of focuses where the entire on-page turns out badly! Here
                        they are:
                    </p>

                    <h5 class="text-muted"
                        style="font-weight: 900; padding-top: 18px; padding-left: 12px; padding-right: 12px;">Doing It
                        on Your Own</h5>

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        At the point when you begin finding out about SEO, it appears to be intriguing and a serious
                        simple undertaking. Thus, similar to some other individual, your characteristic response is,
                        "Gracious! This is excessively simple. I can do it all alone!" So, you set aside your cash and
                        start the on-page enhancement work without anyone else. Furthermore, that is the place it turns
                        out badly. Website design enhancement is a mind boggling cycle and more than science, it
                        includes innovativeness and work of art. It's tied in with making another method of making your
                        site more critical than others before Google. Furthermore, there are a ton of things that
                        solitary the specialists would comprehend. In this way, you accomplish something incorrectly
                        without getting it. By the day's end, the site stays as a barrier for itself and you censure
                        Google for not positioning your site.</p>
                    <h5 class="text-muted"
                        style="font-weight: 900; padding-top: 18px; padding-left: 12px; padding-right: 12px;">Not
                        Auditing Your Site</h5>
                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        Regardless of how cool your site is or how prestigious it is, there's consistently an extension
                        for certain blemishes to have remained. That is the motivation behind why you ought to never
                        skirt the inspecting part. Search engine optimization review guarantees that all the defects of
                        your on-page and specialized SEO is found so as to get fixed. Along these lines, keep in mind
                        the significance of a SEO review. Indeed, in the event that you don't have a clue about the
                        ailment, how might you fix it? Isn't it so? We get endless sites that are experiencing plainly
                        noticeable on-page gives only for the way that they have avoided the SEO review. For your
                        situation, don't rehash the missteps.

                    </p>

                    <h4 class="text-muted"
                        style="font-weight: 900; padding-top: 18px; padding-left: 12px; padding-right: 12px;">Not
                        Consulting an Experienced SEO Expert</h4>

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        This is the more agonizing one among the reasons that cause on-page SEO botches. Indeed, we can
                        comprehend that you did your site's SEO and weren't fruitful. That didn't include any
                        speculation in any event. However, recruiting a SEO master or a SEO organization for the
                        activity yet not achieving the objective just due to their shortcoming burns through your
                        speculation and time. We, at Intlum, have gotten various SEO ventures which were recently taken
                        care of by some other inquiry showcasing firms. The entirety of the site proprietors were
                        frightened and stressed whether they'd face a similar outcome. Also, we worked day and night to
                        put a grin all over by bringing great outcomes. However, why employ a second organization when
                        you can pick the first appropriately.
                    </p>

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        As far as picking a SEO organization, ensure you are doing a touch of examination on that
                        organization. Investigate their online audits, tributes, and above all, perceive how they are
                        positioning. A tenable SEO Company ought to have the option to rank its own site.
                    </p>

                    <h5 class="text-muted"
                        style="font-weight: 900; padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        A credible SEO Company should be able to rank its own website.
                    </h5>

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        Webxnow positions at the top situation on Google for a serious pursuit term, 'Website
                        optimization Company in Kolkata', which is the explanation numerous clients recruit us for the
                        SEO ventures. <br>
                        However, without wasting a single second of yours, we would quickly move to the on-page SEO
                        mistakes that may not seem dangerous but are lethal.
                    </p>

                    <h4 class="text-muted"
                        style="font-weight: 900; padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        Common On-Page SEO Mistakes That Can Ruin Your Website and Savings
                    </h4>

                    <p class="text-justify" style=" padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        At the point when you commit an error in on-page SEO and you don't know about that, you are
                        destroying your site and reserve funds. You continue putting resources into it and consequently,
                        your site's SEO wellbeing deteriorates.</p>


                    <h5 class="text-muted"
                        style=" font-weight: 900; padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        Not Using a Keyword Research Tool</h5>

                    <img class="img-fluid" src="../../assets/images/KEYWORD-RESEARCH-TOOLS.jpg"
                        alt="best keyword reasearch tool for best seo practice">

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        A few people don't have the foggiest idea how to utilize a catchphrase research device and a few
                        people basically don't utilize it and adhere to the topic since they feel watchwords can chain
                        the artistic part of the substance. Indeed, that is the magnificence (or challenge) of it. You
                        need to utilize a watchword such that it bodes well and mixes with the substance. Watchword
                        research is significant and that is the motivation behind why there are several catchphrase
                        research devices accessible out there.
                    </p>

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">


                        Remembering catchphrases for your substance makes your substance super-amazing as it focuses on
                        the specific term that is more well known than the others (with a similar importance). Besides,
                        in the present SEO scene, utilizing one catchphrase on and on won't going to work! You need to
                        utilize long-tail, LSI, and equivalents of the essential watchwords. So as to discover those
                        watchwords, you need to utilize a catchphrase research instrument.

                    </p>

                    <h4 class="text-muted"
                        style=" font-weight: 900; padding-top: 18px; padding-left: 12px; padding-right: 12px;">Using
                        Keywords Excessively</h4>

                    <img class="img-fluid" src="../../assets/images/Using-Keywords-Excessively.jpg"
                        alt="using keyword reasearch seo ">

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        A remarkable inverse of the primary misstep, numerous individuals will in general utilize
                        watchwords unnecessarily in the substance so as to have their site rank quicker. Indeed,
                        adjusting between utilizing no catchphrases and an excessive number of watchwords is the mastery
                        of the top SEO firms. Utilizing such a large number of catchphrases in a specific substance is
                        the thing that called 'Watchword Stuffing' and that is one of the primary benchers among the
                        exercises that disregard Google's positioning calculation. Placing 20 catchphrases in a
                        150-200-word article is one of the crude on-page SEO botches, individuals are rehashing even
                        today!
                    </p>

                    <h4 class="text-muted"
                        style=" font-weight: 900; padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        Not Using Unique Page Title and Description</h4>
                    <img src="../../assets/images/Not-Using-Unique-Page-Title-and-Description.jpg"
                        alt="not using page title and description about the website">
                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">

                        One of the greatest on-page SEO botches however regularly disregarded by numerous website
                        admins, not giving a one of a kind title and depiction to your page can cause your site more
                        mischief than you can actually envision. The page title and depiction show up on the SERP and
                        that is the main thing that would assist clients with understanding the topic and prompt them to
                        tap on your connection.

                    </p>

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">


                        Presently, the slip-ups that we regularly find are same title and practically no portrayal for
                        the pages, no streamlining, and expecting it an additional heap! Following are a couple of
                        things to recall while making the title and depiction:
                    </p>

                    <div class="text-justify text-muted"
                        style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        <ul>
                            <li>Title and description should always be keyword optimized with the primary, LSI and
                                synonyms in a relevant manner</li>
                            <li>Page Description should not be more than 155 characters (with space)</li>
                            <li>Page Title should not be more than 65 characters (with space)</li>
                            <li>Putting the website name is necessary for the homepage but not for the others</li>
                            <li>Along with the SEO aspects, the title and description also be engaging enough to prompt
                                a user to click on it</li>
                        </ul>
                    </div>

                    <h4 class="text-muted"
                        style=" font-weight: 900; padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        Inconsistent Publishing Schedule</h4>

                    <img class="img-fluid" src="../../assets/images/Inconsistent-Publishing-Schedule.jpg"
                        alt="article Publishing schedule">

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        Whether or not you distribute content once in a month, week, or day, you have to follow a
                        specific timetable for the substance distributing. One of the most well-known on-page SEO
                        botches that we run over is irregularity in the distributing plan. Individuals need fast
                        outcomes however SEO is something that sets aside some effort to blossom. Thus, when they don't
                        locate any positioning with one article or two, they begin avoiding the timetable and they quit
                        composing.

                    </p>

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">


                        This legitimately influences the on-page SEO wellbeing of the site. While this isn't identified
                        with the SEO esteem however it's identified with the traffic and more traffic implies more
                        trust. Trust us, Google cherishes those destinations where individuals pay ordinary visits.
                    </p>

                    <h6 class="text-muted"
                        style=" font-weight: 700; padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        But I may get sick or any other urgency may appear! What to do then!?</h6>

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        Along these lines, so as to keep up your timetable, you may compose articles or different types
                        of substance ahead of time! At that point you can distribute it on the day when it should be
                        distributed. Additionally, on the off chance that you are utilizing WordPress, you can have it
                        in the draft to distribute it with a solitary snap or far superior, you can plan the substance.
                    </p>

                    <h4 class="text-muted"
                        style=" font-weight: 900; padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        Linking (Internal and External)</h4>

                    <img class="img-fluid" src="../../assets/images/Linking-Internal-and-External.jpg"
                        alt="internal link building">
                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        Inward and outer connecting legitimately or by implication influences the on-page SEO. Here,
                        numerous people commit errors. In the event that you don't begin connecting appropriately, that
                        is one of the most deadly on-page SEO botches you may make! Most site proprietors and even
                        website admins try not to connection to the inward pages and outside assets, which brings about
                        poor on-page streamlining.


                    </p>

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        Why Internal Linking is Important: Google bot enters one of your pages, and when it finds an
                        interior connection, it goes there and the inside connection there drives it to another page.
                        Along these lines, this cycle assists with slithering a tremendous part (or the whole) of your
                        site.
                    </p>

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        Why External Linking is Important: Google adores those sites that help their clients with great
                        assets and data. Along these lines, when it finds that you are connecting to an outer asset for
                        teaching your crowd with something that your site doesn't have, it values your exertion. That
                        gratefulness adds to the positioning cycle.
                    </p>

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">But how
                        to do internal and external linking?</p>
                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">Well,
                        there are no rules, but we can provide a guideline here:</p>

                    <div class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        <ul>
                            <li>Don’t use generic anchors like ‘Click Here’ or ‘Read More’</li>
                            <li>Using tag clouds is a bad practice for internal linking</li>
                            <li>Try to link to the high-DA sites when it comes to external linking</li>
                            <li>Be descriptive about the linking. User should understand the about the linked page from
                                the anchor text itself</li>
                            <li>All external links should open in a new tab to prevent the increasing bounce rate (when
                                a user leaves your site by clicking the external link because it opens in the same tab)
                            </li>
                            <li>Keep updating your old contents with internal and external links</li>

                        </ul>
                    </div>

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        No connecting at all to an excess of connecting can be deadly for your on-page SEO and this is
                        one of the unpretentious on-page SEO botches that you may rehash unwittingly, which is the
                        reason it's prescribed to enlist a SEO office.</p>

                    <h4 class="text-muted"
                        style=" font-weight: 900; padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        Using Poor-Quality Content</h4>
                    <img class="img-fluid" src="../../assets/images/Using-Poor-Quality-Content.jpg"
                        alt="poor quality content result in poor seo">

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        Content assumes a urgent function in SEO. If not first, it is unquestionably one of the two most
                        significant positioning variables alongside backlinks. Numerous organizations and essayists
                        neglect to concoct a decent quality substance that intrigues the internet searcher bots just as
                        the perusers. Content is a significant factor for Google and due to the inferior quality of the
                        substance, numerous sites needed to endure previously and many are enduring in the present date
                        also.
                    </p>

                    <h6 class="text-muted"
                        style=" font-weight: 700; padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        But I have the best writers on the desk! They write so well. Why is my website not ranking then?
                    </h6>

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        This line can spring up in your brain. Furthermore, that is characteristic. You may have an
                        essayist who is astoundingly inventive with words and style of composing. Yet, when we talk
                        about SEO, we are not restricted to the writing. Website design enhancement substance's three
                        most significant elements are data, comprehensibility, and watchword usage.</p>

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        <b>SEO content’s three most important factors are information, readability, and keyword
                            implementation.</b></p>

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        So, when you write a piece of SEO content, try to</p>

                    <div class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        <ul>
                            <li>Make the content easy to read. That’s the difference in SEO writing and literary
                                content. Here, your reader can be anyone (that includes someone who may not know English
                                very much). So, instead of flaunting your vocabs, stick to the idea of making them
                                understand everything.</li>
                            <li>Put as much information as you can to make sure that your readers don’t need to look
                                elsewhere.</li>
                            <li>Implement keywords in a way that they don’t seem “PUSHED.” It may trouble you at first
                                but you’ll understand how to blend your keywords in your content naturally.</li>
                        </ul>
                    </div>

                    <h4 class="text-muted"
                        style=" font-weight: 900; padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        Neglecting Low-Volume Keywords</h4>

                    <img src="../../assets/images/Neglecting-Low-Volume-Keywords.jpg" alt="low volume keyword">

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        This is an immense misstep that one can make. On the off chance that we can mark one misstep as
                        a 'Quiet Killer', this on-page SEO error would be the one. Absent a lot of involvement with the
                        SEO field, it would appear to be the best plan to focus on the high-volume watchwords as it
                        were. Also, that is the thing that everybody does. However…
                    </p>

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        At the point when you just objective the high-volume watchwords and begin disregarding the
                        low-volume ones, you burrow a grave for your site.
                    </p>

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        Simply envision what number of individuals would need to focus on the high-volume catchphrases
                        that could present to them a ton of traffic? In this way, alongside the high-volume, you focus
                        on the watchwords that accompany high rivalry. Also, now and then the opposition is so awful,
                        it's close to difficult to outclass the effectively positioning outcomes (in view of their power
                        and trust stream). </p>

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        Thus, you continue doing SEO and at last, quit streamlining the page, feeling that SEO isn't
                        some tea. <br>

                        Then again, you have those innumerable low-volume catchphrases that may not present to you that
                        much traffic like the high-volume ones however clearly, they would be path simpler to rank.
                        Thus, you put less exertion to get positioned. <br>

                        Subsequently, rather than not having the option to rank and get a solitary guest, you get
                        positioned and get a few guests. Also, you may get positioned with the high-volume watchwords
                        later on as your DA increments.</p>

                    <h4 class="text-muted"
                        style=" font-weight: 900; padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        Not Optimizing Images</h4>

                    <img src="../../assets/images/Not-Optimizing-Images.jpg" alt="image optimization for better seo">

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        An Image can in reality express a thousand words yet web index crawlers don't comprehend those
                        words since they don't have eyes as we do! Anyway, how would they 'see' a picture? Basic,
                        through the ALT Text, which portrays what a picture is about.

                    </p>

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        Along these lines, when you simply skirt the ALT Text part, your picture passes up on the
                        opportunity to get positioned. Furthermore, let us let you know, Google Image Search is
                        expanding at a quick movement.
                    </p>

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        Indeed, the absence of picture improvement doesn't end here! Another inconspicuous and sensitive
                        on-page SEO botch that we have seen practically 80% sites are rehashing is naming the picture
                        record inappropriately.
                    </p>

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        Google can peruse the picture record name that you transfer. Along these lines, when you name
                        your pictures something like image1, or gksjgbo, or 123, it can make disarray.

                    </p>

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        In this way, naming your picture document (and some other record) appropriately is the best
                        thing for you and SEO.</p>

                    <h4 class="text-muted"
                        style=" font-weight: 900; padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        Slow Loading Time</h4>

                    <img src="../../assets/images/Slow-Loading-Time.jpg" alt="slow loading casue in bad seo">

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        It's basic – if your site has great client experience, Google adores you. Also, site page
                        stacking time is without a doubt one of the prime parts of a site's client experience. At the
                        point when a site page stacks gradually, it bothers the clients and trust us, web clients are
                        less lenient and distrustful about renewed opportunities. Along these lines, the second they
                        discover your webpage setting aside more effort to stack than they expected, they will close
                        your site and move to another (who is your opposition).
                    </p>

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;"> <b>Yes,
                            that’s bad for the traffic but how does that affect SEO and ranking?</b></p>

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">Well, if
                        you think a little more deeply, you will get it.</p>

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        When the traffic leaves your website, it increases the bounce rate of your website. And an
                        increased bounce rate means diminished ranking and SEO.
                    </p>

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">So,
                        optimize your webpage loading speed and try to stay within 3 seconds.</p>

                    <h4 class="text-muted"
                        style=" font-weight: 900; padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        Never Repeat These On-Page SEO Mistakes</h4>

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">
                        Thus, you do have a thought of the on-page confuses that are deadly with your site. All things
                        considered, there are all the more profound and specialized blunders that may lie in your site
                        that you may not know. Attempt to contact your SEO accomplice to fix any issues which are
                        referenced here.
                    </p>

                    <p class="text-justify" style="padding-top: 18px; padding-left: 12px; padding-right: 12px;">


                        Nonetheless, on the off chance that you feel that the SEO organization you have employed isn't
                        confronting your desire, you may likewise call us for a fast discussion. We give free interviews
                        and our specialists clarify everything unmistakably so you don't have any disarray as a top
                        priority.
                    </p>

                </div>

            </div>

        </div>

    </div>
</body>

<app-footer></app-footer>