<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PQ2B553"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> 
<!-- End Google Tag Manager (noscript) -->


<div class='container '>
    <h1 class='brand' style="font-weight: 800; font-size: 35px; margin-top: 20px;"> Let’s Build The Future, TOGETHER!</h1>
    <p style="font-size: 14px;">Send Us Your Query and Get A Call from One of Our Experts</p>

    <!--animated bounceInLeft class for selection what will be animated-->
    <div class='wrapper animated bounceInLeft'>
        <div class='company-info'>
            <h3 style="font-weight: 800; font-size: 16px;">Kalyankari Softnet</h3>
            <!-- <h5 style="font-size: 17px;" class="text-justify" >Best Website Design and Development Company in your city </h5>  -->
            <h6 style="font-weight: 600; font-size: 16px;">Modernize Your Website Today !!</h6> <br>
            <p class=" text-justify" style="font-size: 14px; font-weight: 600;">Many websites are now infancy and costs you a lot. With us, select from a wide variety of designs and get
                one customized. Also choose from ready to host websites and get :-</p>
                <ul>
                    <li>Free Domain & Unlimited Web Space</li>
                    <li>Unlimited Bandwidth and 2 email accounts</li>
                    <li>Unlimited Pages</li>
                    <li>Search Engine Submission & Google Apps Support</li>
                    <li>And Many more ...</li>
                </ul>
        </div>
        <div class='contact'>
            <h3 style=" font-size: 20px; margin-bottom: 30px !important;">DROP YOUR QUERY</h3>



              <div class="text-center">
                  <h2 style="margin-bottom: 20px; font-size: 20px; color: darkgreen;" id="response"></h2>
              </div>

            <form [formGroup]="form" (ngSubmit)="onSubmit()">

                <div class="form-group">
                    <label for="name">Name</label>
                    <input type="text" class="form-control" formControlName="name" id="userName" required  autocomplete="off">
                    <p style="font-size: 12px;" id="checkName"></p>
                </div>
                


                <div class="form-group">
                    <label for="company">Company</label>
                    <input type="text" class="form-control"  formControlName="company" id="userCompany"  autocomplete="off" >
                    <p style="font-size: 12px;" id="checkCompany"></p>
                </div>
                


                <div class="form-group">
                    <label for="email">Email Address</label>
                    <input type="email" class="form-control"  formControlName='email' id="userMail" autocomplete="off" >
                    <p style="font-size: 12px;" id="checkEmail"></p>
                </div>
                


                <div class="form-group">
                    <label for="phoneNumber">Phone Number</label>
                    <input type="text" class="form-control"  formControlName='phoneNumber' id="userPhone"  autocomplete="off" >
                    <p style="font-size: 12px;" id="checkPhoneNumber"></p>
                </div>
                


                <div class="full">
                    <label for="message">Message</label>
                    <textarea type="text" class="form-control"  rows="5"  formControlName='message' id="userMessage" autocomplete="off"  > </textarea>
                    <p style="font-size: 12px;" id="checkMessage"></p>
                </div>
                


        

                <div class='full'>
                    <button  id="submitbtn" style="font-weight: 700;  background-color: black;" type="submit" class="btn btn-success">Submit</button>


                </div>


            </form>


        </div>
    </div>