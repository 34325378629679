import { Component, OnInit } from '@angular/core';
import { Meta , Title } from '@angular/platform-browser';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.css']
})
export class DisclaimerComponent implements OnInit {

  constructor(private discalimerTitle : Title , private meta : Meta) { 
    this.discalimerTitle.setTitle('Disclaimer | Kalyankari Softnet');
    this.meta.addTag({name : 'description', content : 'disclaimer of kalyankari softnet, '});
    this,meta.addTag({name : 'keywords', content : 'disclaimer, kalyankari, softnet, website, services, facts, info@kalyankarisoftnet.com'})
  }

  ngOnInit(): void {
  }

}
