import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-on-page-seo-mistake',
  templateUrl: './on-page-seo-mistake.component.html',
  styleUrls: ['./on-page-seo-mistake.component.css']
})
export class OnPageSeoMistakeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
