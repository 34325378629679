<body>
    <div class="y-us-section">
        <div class="container">
            <div class="y-us-head">
                <div class="y-us-title text-center">
                    <h2 class="my-4" id="chooseus" style="color:#113F64;">Why choose us</h2>

                    <span class="y-us-title-border"></span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8 col-md-offset-2 col-sm-12 col-xs-12">

                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-6 col-xs-12">
                    <div class="y-us-content">
                        <div class="service-3">
                            <div class="service-box">
                                <div class="clearfix">
                                    <div class="iconset">
                                        <span class="glyphicon glyphicon-cog icon"></span>
                                    </div>
                                    <div class="y-us-content">
                                        <h4>Talented Team</h4>
                                        <p class="text-muted text-justify">Our dedicated and talented team possesses
                                            in-depth expertise in UI-UX design, coding, web analysts, app development,
                                            conversion optimization, and project management. </p>

                                        <p class="text-muted text-justify">
                                            Each team member of our organization is highly skilled and goal-oriented,
                                            having vast industry experience through which our clients can generate
                                            maximum ROI. We can provide you every service as per your need, e.i.,
                                            brainstorming, right deployment, testing, delivery, and support.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="service-box">
                                <div class="clearfix">
                                    <div class="iconset">
                                        <span class="glyphicon glyphicon-cog icon"></span>
                                    </div>
                                    <div class="y-us-content">
                                        <h4>Free Consultation and Guidance</h4>
                                        <p class="text-muted text-justify">Whatever be the question in your mind, we
                                            have got the right solution for it. Our free service of website consultation
                                            and guidance will lead your website and business to your desired height. We
                                            know an individual having less technical knowledge cannot choose the best
                                            option for them. Thus, we are here with all possible best options for your
                                            business and website. We are liable to clear all your doubts concerning the
                                            website or marketing.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 col-xs-12">
                    <div class="why-choose-banner">
                        <img class="img-fluid" src="../../../assets/images/about-image.png" alt="">
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 col-xs-12">
                    <div class="y-us-contentbox">
                        <div class="service-3">
                            <div class="service-box">
                                <div class="clearfix">
                                    <div class="iconset">
                                        <span class="glyphicon glyphicon-cog icon"></span>
                                    </div>
                                    <div class="y-us-content">
                                        <h4>Custom Development</h4>
                                        <p class="text-muted text-justify">Our custom Development will give a new
                                            horizon to your unique ideas into a dynamic website. From website redesign
                                            to custom development, we provide all services. </p>

                                        <p class="text-muted text-justify">Being the modern web development entity, we
                                            offer a custom solution as per
                                            the requirement to every individual and corporation willing to develop a
                                            website. User-friendly designs and clutter-free codes as per your specific
                                            requirement fetches your success. Be unique to grab all opportunities.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="service-box">
                                <div class="clearfix">
                                    <div class="iconset">
                                        <span class="glyphicon glyphicon-cog icon"></span>
                                    </div>
                                    <div class="y-us-content">
                                        <h4>UI-UX that Converts</h4>
                                        <p class="text-muted text-justify">We configure website designs in such a
                                            manner, which attract audience attention instantly. A simple and
                                            user-friendly website leads to convert the audience into a customer. So, we
                                            craft a perfect user interface through which it becomes easy to engage an
                                            audience on the website. </p>

                                        <p class="text-muted text-justify">
                                            That's why we use the latest UI-UX elements and logic to develop your
                                            website. We always try to infuse the latest technology. Our highly expert
                                            designers are capable enough to craft the best website which can generate
                                            more sales as well as business leads with no added cost,
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>