<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PQ2B553"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> 
<!-- End Google Tag Manager (noscript) -->
<app-navbar></app-navbar>

<body>
    <div class="text text-center"
        style="font-size: 30px; margin-top: 0px; margin-bottom: 1s0px; padding-top: 15px; padding-bottom: 15px; font-weight: 900;">
        Simple, Powerful Email for Small Businesses</div>
    <p class="text-center text-muted" style="padding-bottom: 15px;">Give your business a professional makeover with
        email@yourbrand.com </p>
</body>

<div class="container">
    <div class="row">
        <div class="col-md-6">
            <h2 style="font-weight: 800; margin-top: 30px;">Get started with just the number of email accounts you need
                with 5GB/account
            </h2>

            <h5 style="margin-top: 25px; margin-bottom: 20px; font-size: 17px;" class="text-muted">Starts at just
                Rs.25.00/account/month</h5>

            <button class="btn btn-warning" style="margin-bottom: 20px;">Buy Now</button>

        </div>



        <div class="col-md-6">
            <img src="../../assets/images/email-thepopweb.png" class="img-fluid " alt="">

        </div>

        <!-- .....................................2nd section ............................. -->

        <div class="col-sm-4">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Intuitive and Responsive Design</h5>
                    <p class="card-text">Having featured with OpenXchange technology, you can access your email on your smartphone and a tablet also.</p>

                </div>
            </div>
        </div>

        <div class="col-sm-4">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Inbuilt Virus Protection</h5>
                    <p class="card-text">Are you facing the problem of malavare and viruses? Don't worry, we are here to protect your inbox through our advanced anti-virus technology.</p>

                </div>
            </div>
        </div>

        <div class="col-sm-4">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">5GB Storage Plus Backup</h5>
                    <p class="card-text">Backed up your emails with our state-of-art Infrastructure with 5 GB mail storage.</p>

                </div>
            </div>
        </div>

        <div class="col-sm-4">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Intuitive and Responsive Design</h5>
                    <p class="card-text">Beautifully designed and delivered with the power of OpenXchange. You can also
                        access your email on your smartphone or tablet.</p>

                </div>
            </div>
        </div>


        <div class="col-sm-4">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Calendars, Contacts and Tasks</h5>
                    <p class="card-text">OpenXchange lets you manage your contacts, create to-do lists, and track your all meetings simultaneously. </p>

                </div>
            </div>
        </div>

        <div class="col-sm-4">
            <div class="card ">
                <div class="card-body">
                    <h5 class="card-title">Social Media in your Inbox</h5>
                    <p class="card-text ">OpenXchange gives you the freedom to access your Facebook, Twitter, Google+ feeds in your inbox.</p>

                </div>
            </div>
        </div>

        <!-- ........................section - 3 ................ -->


        <div class="col-md-12">
            <h2 class="text-center" style="font-weight: 800;">FAQ – Your questions? We got answers ! </h2>


            <div class="accordion-option">

                <!-- <a href="javascript:void(0)" class="toggle-accordion active" accordion-id="#accordion">wwewewe</a> -->
            </div>
            <div class="clearfix"></div>
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingOne">
                        <h4 class="panel-title">
                            <a  role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne"
                                aria-expanded="true" aria-controls="collapseOne">
                                How Will Purchasing Business Email benifit ?
                            </a>
                        </h4>
                    </div>
                    <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel"
                        aria-labelledby="headingOne">
                        <div class="panel-body text-justify">
                            As we know that free emails are not a solution for your business. For that, you should take a branded Domain name that can enhance your professional look( ex. SALES@MYBRAND.COM). We can provide you latest email technology that would help you in industry best result and reliability. Also, you can add more email account as per your requirements.
                        </div>
                    </div>
                </div>

                <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingTwo">
                        <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                href="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                Which Email Clients and Protocol are Supported ?
                            </a>
                        </h4>
                    </div>


                    <div id="collapseTwo" class="panel-collapse collapse show" role="tabpanel"
                        aria-labelledby="headingTwo">
                        <div class="panel-body text-justify">
                            Emails could be sent/received through any desktop-based email client(ex. Microsoft outlook, Outlook Enterprise, etc.). Guidance of configuration of different email clients to send/receive emails are provided. POP, IMAP, AND MAPI protocols are supported by our enterprise mail products.
                        </div>
                    </div>
                </div>


                <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingThree">
                        <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                href="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                How DO I Use My Webmail Interface ?
                            </a>
                        </h4>
                    </div>
                    <div id="collapseThree" class="panel-collapse collapse in" role="tabpanel"
                        aria-labelledby="headingThree">
                        <div class="panel-body text-justify">
                            To access your Webmail Interface, you can use the white-labelled URL:
                            http://webmail.yourdomainname.com. Once on the log in page, you would need to login with
                            your email address and the corresponding password.
                        </div>
                    </div>
                </div>


                <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingThree">
                        <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                href="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                Which Mobile Phone Can I Access My Mail Form ?
                            </a>
                        </h4>
                    </div>
                    <div id="collapseFour" class="panel-collapse collapse in" role="tabpanel"
                        aria-labelledby="headingFour">
                        <div class="panel-body text-justify">
                            You can use any smartphone/Tablet to access your email. Compatibility on all major operating systems(Ex. IOS, Android window, Mobile Symbian, and so on) is available with our Fluidic webmail, on both HTML5 and Javascript.
                        </div>
                    </div>
                </div>

                <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingThree">
                        <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                href="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                                What Is the Space Provided Per Email account ?
                            </a>
                        </h4>
                    </div>
                    <div id="collapseFive" class="panel-collapse collapse in" role="tabpanel"
                        aria-labelledby="headingFive">
                        <div class="panel-body text-justify">
                            We provide 5GB of space per email account.
                        </div>
                    </div>
                </div>

                <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingThree">
                        <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                href="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                What Port Do I Need To Use For Email Hosting ?
                            </a>
                        </h4>
                    </div>
                    <div id="collapseSix" class="panel-collapse collapse in" role="tabpanel"
                        aria-labelledby="headingSix">
                        <div class="panel-body text-justify">
                            Generally, the outgoing mail server/SMTP Service is 25, which you can use as a port. But sometimes, due to your blocked ISP, our alternate Port 587 can be used instead of port 25.
                        </div>
                    </div>
                </div>


                <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingThree">
                        <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                href="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
                                Can I Create Mailing List ?
                            </a>
                        </h4>
                    </div>
                    <div id="collapseSeven" class="panel-collapse collapse in" role="tabpanel"
                        aria-labelledby="headingSeven">
                        <div class="panel-body text-justify">
                            Of course, you can do it. Moreover, you can add or delete users from the mailing list. A selection of moderators, and also restrict/ban users from a list by you.
                        </div>
                    </div>
                </div>


                <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingThree">
                        <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                href="#collapseEight" aria-expanded="true" aria-controls="collapseEight">
                                What Is Your SPAM Policy ?
                            </a>
                        </h4>
                    </div>
                    <div id="collapseEight" class="panel-collapse collapse in" role="tabpanel"
                        aria-labelledby="headingEight">
                        <div class="panel-body text-justify">
                            We have a spam filter policy by default applicable to all the recipients in the organization.
                        </div>
                    </div>
                </div>


                <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingThree">
                        <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                href="#collapseNine" aria-expanded="true" aria-controls="collapseNine">
                                Can I Use Auto Responder ?
                            </a>
                        </h4>
                    </div>
                    <div id="collapseNine" class="panel-collapse collapse in" role="tabpanel"
                        aria-labelledby="headingNine">
                        <div class="panel-body text-justify">
                            Yes, you can use it.
                        </div>
                    </div>
                </div>



            </div>


        </div>

        <app-contact-us></app-contact-us>



    </div>

</div>


<app-footer></app-footer>