<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PQ2B553"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> 
<!-- End Google Tag Manager (noscript) -->
<app-navbar></app-navbar>

<div class="container">
    <div class="row">
        <div class="col-md-12">
            <h2 class="text-center" style="color: #111180;border-bottom: 3px solid black; margin-top: 50px;">Privacy Policy </h2>
            <!-- <hr style="border-top: 3px dotted gray;"> -->
            <p>Last updated on Feb 27, 2021</p>

        </div>

        <div class="col-md-12">
            <h4>Introduction to Privacy Policy</h4>
            <p>The terms and conditions contained hereinafter (" TERMS OF USE") shall apply to the use of the website
                www.kalyankarisoft.com and any other linked pages, products, software(s), API keys, features, content or
                application services (including but without limitation to any mobile application services) in connection
                therewith, offered from time to time by kalyankari.</p>

            <p>Any person logging on to or using the Website (even when such person does not avail of any services
                provided in the Website shall be presumed to have read these Terms of Use (which includes the Privacy
                Policy, separately provided on the Website) and unconditionally accepted the terms and conditions set
                out herein. These Terms of Use constitute a binding and enforceable agreement between the User and
                Kalyankari Soft. These Terms of Use do not alter in any way the terms or conditions of any other written
                agreement you may have with Kalyankari softnet for other services.</p>
            <br>

            <p>Please read the terms set out hereunder carefully before agreeing to the same. If you do not agree to
                these Terms of Use (including any referenced policies or guidelines), please immediately terminate your
                use of the Website. You can accept the Terms of Use by:</p>

            <br>

            <ul>
                <li>Clicking to accept or agree to the Terms of Use, where this option is made available to you by
                     in the User interface for any particular Service; or</li>

                <li>Accessing, testing or actually using the Services. In this case, you understand and agree that
                    kalyankarisoftnet will treat your use of the Services as acceptance of the Terms of Use from that point
                    onwards.</li>
            </ul>

            <h4>Protection of your information</h4>
            <p>Subject to these Terms of Use, kalyankarisoftnet offers the Services set forth in Schedule I herein.</p>
            <p>Oview provides access to a system where users can:</p>
            <p>– create polar questions (Yes/No questions) about current articles (the “survey questions”). This is done
                via the backend;</p>
            <p>– answer the survey questions.</p>
            <p>It is important to note that all analysis of the survey results such as reports, statistical tables,
                charts, graphics or other visual forms (“statistical reports”), are always aggregated into statistical
                information without compromising individual votes.</p>

            <p>For these purposes Oview asks you to provide your personal data which will be used by Oview to create
                statistical reports based on personal opinions about different news articles, items, issues, topics, and
                other internet sources which users link while creating their survey questions in order to help other
                users to understand the general context of these survey questions(the “articles”). Oview provides you
                with access to our services only after you create an account in Oview as defined in the Privacy Policy
                in the part “Registration in Oview”. As soon as you sign the Terms and Conditions and finish the whole
                registration process in the Oview backend of the app or in the mobile app (the “registration”), your
                personal data will be transferred to Oview and you will become an Oview user (the “user”). Becoming an
                Oview user contains the right to express your opinion by voluntary participation in survey questions
                and/or the right to create survey questions. </p>

            <p>Oview controls personal data. It collects and processes personal data only for the purposes of Oview
                activities. Oview respects your privacy and complies with Dutch and international data protection law.
            </p>


            <h4>Information we collect and how we use it</h4>

            <p>During the registration in Oview we ask you to provide personal data which includes your name, birth
                date, gender, education level, location (city), language preference and/or other categories of personal
                data which Oview will decide to include in the future. Providing this personal data is optional, and it
                determines the amount of information in statistical reports the user will be able to see after
                responding to the survey questions; meaning; providing your personal data gives access to the
                corresponding aggregated data in the statistics reports. It is possible at any point to remove and/or
                add personal data, and that will immediately change the extent of access to the relevant statistical
                reports.</p>

            <p>Oview can collect, process, store and use your technical informationsuch as cookies, and/or other
                technologies, and subsidiary analytical software as described in our Cookies Policy and in accordance to
                Dutch and international law. The statistical information of your activities in your Oview account can be
                used for improvement of our system including security policies, but not limited to it alone.</p>

            <p>We collect information from your mobile device about the system and model of your device, version of your
                operating system, and statistical information about the equipment of your device such as the processor,
                short-term memory, internal memory, application versions and similar. This information could be used as
                statistical data for improvement of our system including security policies, but not limited to it alone.
            </p>

            <p>You can use third party services (such as Facebook and others) to share personal data while creating an
                account in Oview. Only personal data with public access will be collected in the case of using third
                party services. Oview does not have direct or indirect intention to collect more personal data than we
                ask of you during the registration. You can manage the information that we collect through third party
                services by changing the extent of public access to your personal data in those services according to
                their Privacy Policies.</p>

            <h4>Account information</h4>
            <p>Without infringement of personal protection and in accordance to Dutch, European, and international Data
                Protection Law. Oview has the right to use all data collected for actions or a set of actions, including
                the collection, recording, organization, storage, updating or modification, retrieval, consultation,
                use, dissemination by means of transmission, distribution or display in any other form, merging,
                linking, as well as blocking, erasure or destruction of data (“processing”).</p>

            <p>Your personal data is processed according to the Terms and Conditions and Privacy Policy of Oview.

                Oview will store personal data on a special database in the server of third parties (the “provider”).
                This provider stores personal data in accordance with the Terms and Conditions of the special agreement
                between us and this provider. All provider activity related to personal data is regulated by the Terms
                and Conditions, our Privacy Policy and the provider’s Privacy Policy. The provider guarantees additional
                security measures to your personal data with relation to the server where all data are saved.</p>

            <p>Your personal data is protected from unauthorized access also from the third parties through our
                encryption during transmission, storage, and other processing of personal data to increase the level of
                safety of your personal data.</p>

            <p>With respect to your privacy and following the requirements of data protection, your personal data are
                used by Oview only after its depersonalization, mixing and modification. The modification of information
                is the final step of creating statistical reports.

                All results of public opinion become public only after the user`s voluntarily participation in survey
                questions, with the option of user`s preliminary research into the topic discussed in the different
                articles.</p>

            <h4>Users’ rights </h4>

            <p>You have the right to know what kind of information related to you is being processed by Oview, and/or
                whether your personal data is being processed or not. You have the right to send a request to Oview in
                order to correct, supplement, delete or block information related to you according to your specification
                based on legitimate grounds and according to the Dutch and international data protection law.Oview is
                obliged to inform you when we finish all actions related to such a withdrawal, in accordance with the
                term fixed by the law.</p>

            <p>Users have the right to react to this document and notify Oview, via email, what they would like to
                change in the Terms and Conditions and why. Oview reserves the right to change the Terms and Conditions
                in accordance with these suggestions, but it is not obliged to do so.</p>

            <h4>Activities</h4>

            <p>We keep your personal data in accordance with confidentiality requirements by law and without
                infringement of your privacy.

                We encrypt your personal data during transmission, storage, and backup to increase the level of safety
                of your personal data.</p>

            <p>Specific employees of Oviewhave a key to the codified personal data for the purposes of improvement of
                the application system including, but not limited to security policy, for ensuring proper operation of
                the platform, and for other legitimate purposes related to Oview activities.

                Third parties can view the statistical reports without any personal identification of the users.</p>

            <h4>Limitations of the validity of the the Terms and Conditions</h4>

            <p>You agree, for an unlimited time, to give your consent regarding your personal data to Oview according to
                the terms and conditions as described in the Terms and Conditions.</p>

            <p>Oview has the right to change the Terms and Conditions without any notification unless these changes
                reduce users’ rights; it will not be done without the notice and additional consent of the users.</p>

            <h4>GENERAL</h4>
            <p>All the definitions in the Terms and Conditions, including “Oview”, “user”, “survey question”, “personal
                data”, “statistical report”, “registration”, “provider”, and others can be used in the plural form as
                well as in the singular form without it having any effect on the definitions themselves. The pronouns
                used herein shall include, where appropriate, either gender and/or both singular and plural.</p>

            <p>All terms and their definitions in the Terms and Conditions can be used in the Privacy Policy document,
                and vice versa, without any changes to their meaning/s. </p>
            <p>In the event that any clause in this Terms and Conditions should be and/or become null and void, this
                mere fact will not have any effect on the validity of the other clauses.</p>

            <p style="margin-bottom: 30px;" class="text-justify">This Terms and Conditions shall be governed and interpreted through and under the Dutch Law and the law of the court in Utrecht.

                If you have any questions, suggestions or comments regarding Oview’s processing of personal data, please contact us. </p>    
        </div>

    </div>

</div>

<app-footer></app-footer>