<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PQ2B553"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> 
<!-- End Google Tag Manager (noscript) -->
<app-navbar></app-navbar>

<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="hero">

                <div id="wordHeader" class="text-center">
                    <h1 style="font-weight: 900;">Turning your Idea into Brand</h1> <br>

                    <div class="col-md-12" style="padding-left: 20px; padding-right: 20px;">
                        <p>Building a brand is an essential part of any business. It is a process and requires a proper strategy. Thus, the ongoing effort results in long-term relationships with your customers. It can generate a consistent increase in leads and sales.</p> <br>
                        <p>It creates awareness about your products and services. In 2020, it can be done effectively through various digital marketing activities. </p> <b></b>
                        <ul class="my-4">
                            <li>User experience(i.e. your website)</li>
                            <li>SEO & Content Marketing</li>
                            <li>Social Media Marketing</li>
                            <li>Paid advertising(PPC)</li>
                        </ul>
                    </div>
                </div> <br>

                <h4 class="text-center" style="font-weight: 700;">How to Implement a Successful Personalized Marketing
                    Strategy</h4> <br>
                <p class="text-center"> <i>- Gather as Much Customer Data as Possible -</i> </p> <br>


                <p class="text-center"> <i>- Spread the 'Personalization Love' Across Channels -</i> </p> <br>
                <p class="text-center"> <i>- Impact Every Stage of the Digital Customer Journey -</i></p> <br>
                <p class="text-center"> <i>- Make Sure You Have the Right Technology in Place -</i> </p> <br>
                <p class="text-center"> <i>- Continuously Update and Refine Personalization Processes -</i></p> <br>


                <br>
                <div class="col-md-12 text-center">

                    <button type="button" class="btn btn-outline-warning"
                        style="padding-left: 20px; padding-right: 20px; padding-bottom: 10px; padding-top: 10px; 
                        border-radius: 10px;  margin-bottom: 21px; color: black; font-weight: 800;">Turn Your Idea With Us</button>



                </div>



            </div>

        </div>

    </div>


    <app-footer></app-footer>