<!-- start we serve all industry sectiion -->
<div class="container">
    <h3 style="text-align: center; margin-top: 30px; font-weight: 700;">We Serve All Industries</h3>
    <p class="text-muted" style="text-align: center;">We stay on top of our industry by being experts in yours.</p>

    <div class="row">


        <div class=" col-md-4" style="margin-top: 50px;">
            <div class="card businessCard zoom" style="margin-left: 15px;" style="background-image: url(../../../assets/images/right-bg-decor.png);  background-repeat: no-repeat;
            background-size: auto;">
                <div class="card-body" style="box-shadow: 0 0 3px rgba(252, 251, 251, 0.856);">
                    <ul class="bsection" style="color: gray;">
                        <li style="cursor: pointer; " onMouseOver="this.style.color='#2A59AB'"
                            onMouseOut="this.style.color='gray'"><i class="fas fa-heartbeat"></i> Health Care</li>
                        <li style="cursor: pointer;" onMouseOver="this.style.color='#2A59AB'"
                            onMouseOut="this.style.color='gray'"><i class="fas fa-university"></i> Education</li>
                        <li style="cursor: pointer;" onMouseOver="this.style.color='#2A59AB'"
                            onMouseOut="this.style.color='gray'"><i class="fas fa-meteor"></i> MLM</li>
                        <li style="cursor: pointer;" onMouseOver="this.style.color='#2A59AB'"
                            onMouseOut="this.style.color='gray'"><i class="fas fa-school"></i> Government</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-md-4" style="margin-top: 50px; padding-bottom: 50px;" id="middleCard">
            <div class="card zoom businessCard">
                <div class="card-body" style="padding-top: 40px; padding-bottom: 40px; 
                                          box-shadow: 0 0 4px rgb(247, 245, 245); box-sizing: none; border: none !important;" >
                    <ul class="bsection" >

                        <li style="cursor: pointer;" onMouseOver="this.style.color='#2A59AB'"
                            onMouseOut="this.style.color='gray'"><i class="fas fa-robot"></i> Automotive</li>

                        <li style="cursor: pointer;" onMouseOver="this.style.color='#2A59AB'"
                            onMouseOut="this.style.color='gray'"><i class="fas fa-tshirt"></i> Beauty & Fashion</li>

                        <li style="cursor: pointer;" onMouseOver="this.style.color='#2A59AB'"
                            onMouseOut="this.style.color='gray'"><i class="fas fa-dumpster"></i> Ecommerce</li>

                        <li style="cursor: pointer;" onMouseOver="this.style.color='#2A59AB'"
                            onMouseOut="this.style.color='gray'"><i class="fas fa-briefcase"></i> B2B</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-md-4" style="margin-top: 50px; ">
            <div class="card zoom businessCard" style="background-image: url(../../../assets/images/right-bg-decor.png);  background-repeat: no-repeat;
            background-size: auto; ">
                <div class="card-body " style="box-shadow: 0 0 3px rgba(247, 247, 247, 0.856); ">
                    <ul class="bsection" style="color: gray;">
                        <li style="cursor: pointer;" onMouseOver="this.style.color='#2A59AB'"
                            onMouseOut="this.style.color='gray'"><i class="fas fa-piggy-bank"></i> Finance</li>
                        <li style="cursor: pointer;" onMouseOver="this.style.color='#2A59AB'"
                            onMouseOut="this.style.color='gray'"><i class="fas fa-hammer"></i> Construction</li>
                        <li style="cursor: pointer;" onMouseOver="this.style.color='#2A59AB'"
                            onMouseOut="this.style.color='gray'"><i class="fas fa-gavel"></i> Law</li>
                        <li style="cursor: pointer;" onMouseOver="this.style.color='#2A59AB'"
                            onMouseOut="this.style.color='gray'"><i class="fas fa-sign-language"></i> NGO</li>
                    </ul>

                </div>
            </div>
        </div>





    </div>

</div>



<!-- end we serve all industry sectiion -->





<!-- ...............start short description about the services............ -->
<div class="container">
    <h2 class="text-center my-4" style="font-size: 30px; font-weight: 700; color:#113F64;">Keep Your Business Online</h2>
    <p class="text-center text-muted">Our expert IT team will help you to boost your business over the web.</p>

    <div class="row">


        <div class="col-md-6">
            <div id="accordion" class="myaccordion">
                <div class="card">
                    <div class="card-header" id="headingOne">
                        <h2 class="mb-0">
                            <button class="d-flex align-items-center justify-content-between btn btn-link"
                                data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                                aria-controls="collapseOne" style="font-weight: 900;">
                                Web Design and UX
                                <span class="fa-stack fa-sm">
                                    <i class="fas fa-circle fa-stack-2x"></i>
                                    <i class="fas fa-minus fa-stack-1x fa-inverse"></i>
                                </span>
                            </button>
                        </h2>
                    </div>
                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                        <div class="card-body">
                            <h6 style="font-weight: 700;">We Dress Your Website with Custom Designs that Convert !</h6>

                            <p class="text-muted text-justify">Experience the mesmerizing, vivid, and vibrant design for
                                your website. Our commercially driven UI-UX designers ensure you the professional and
                                unique website for your organization's reputation.</p>

                            <p class="text-muted text-justify">
                                The blend of creativity and user experience in our custom design maintains a perfect
                                balance, which proves handy for conversion and engagement. In short, we create designs,
                                which attract (the audience) and reveal (your goals and commitment). Unique creativity
                                and knowledge help us to dominate as a top website design company in India.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header" id="headingTwo">
                        <h2 class="mb-0">
                            <button class="d-flex align-items-center justify-content-between btn btn-link collapsed"
                                data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                                aria-controls="collapseTwo" style="font-weight: 900;">
                                Web Development
                                <span class="fa-stack fa-2x">
                                    <i class="fas fa-circle fa-stack-2x"></i>
                                    <i class="fas fa-plus fa-stack-1x fa-inverse"></i>
                                </span>
                            </button>
                        </h2>
                    </div>
                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                        <div class="card-body">
                            <h6 style="font-weight: 700;">Not Only Websites, We Develop Your Business and Brand</h6>

                            <p class="text-muted text-justify">These days, a website or mobile app has become the medium
                                of communication with the audience. Our web developers team is proficient enough to
                                convert a collection of visuals into a working one. Our website or apps meet their
                                desired parameter with the help of extraordinary developmental skills. We take special
                                care while making a website and app as per your recommendations and convert its dynamic
                                appearance with backend coding( with or without an open-source CMS)</p>

                            <p class="text-muted text-justify">To be on the Top in the market, we employ so many
                                different technologies in developmental works. "The intense knowledge of CMS frameworks
                                and software" manage to earn the Title- Best web Development Company in India.</p>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header" id="headingThree">
                        <h2 class="mb-0">
                            <button class="d-flex align-items-center justify-content-between btn btn-link collapsed"
                                data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                                aria-controls="collapseThree" style="font-weight: 900;">
                                Online Marketing
                                <span class="fa-stack fa-2x">
                                    <i class="fas fa-circle fa-stack-2x"></i>
                                    <i class="fas fa-plus fa-stack-1x fa-inverse"></i>
                                </span>
                            </button>
                        </h2>
                    </div>
                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                        <div class="card-body">
                            <h6 style="font-weight: 700;">Give Your Business Wings to Fly with Our Online Marketing
                                Solutions</h6>

                            <p class="text-muted text-justify">Today, whatever be the business depends upon the
                                marketing of the product with a specific strategy to procure leads. Now, marketing has
                                become a core component of doing business. We provide the result-driven, sprint-based
                                approach, manipulates data to create organic connections to generate Goodwill while
                                staying agile. Our bonafide digital marketers have accurate strategies and policies. And
                                also, we provide a consultant for any of your online marketing needs.</p>

                            <p class="text-muted text-justify">Our expert executives will properly monitor your
                                Information Architecture (IA), design, business nature, and targeted audience. We work
                                hard round the clock to get the desired objective. Consequently, your site turns into an
                                effective as well as an impressive marketing tool. We also offer services like SEO, SMO,
                                ORM, PPC, and much more.</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="col-md-6 " >
            <img style="margin-top: 70px;" class=" img-fluid"  src="../../../assets/images/webdev.serviceimage.gif" alt="Online Marketing
                Solutions">


        </div>

    </div>

</div>
<!-- ...............end short description about the services............ -->






<div class="container" style="margin-top: 30px; padding-bottom: 40px;">

    <h3 style="text-align: center !important;  font-weight: 900; padding-bottom: 30px;">
        Core Services Offered by kalyankari Softnet</h3>
    <div class="row">




        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" style="margin-top: 20px; ">
            <div class="productCardContainer" >
                <div class="productCardContent" style="border-radius: 40px !important; border-style: none;">

                    <div class="productCardDetails">
                        <div class="productCardModel">
                            <span class="modelCardEffect"></span>
                            <a href="" style="color: white; text-shadow: 2px 2px black;">
                                WEB DESIGN SOLUTION

                            </a>
                        </div>
                        <div class="productCardDetailsList">
                            <div class="list-group" style="border-radius: 40px !important;">

                                <a href="" class="list-group-item">
                                    <span class="detailIcon">
                                        <i class="fa fa-dot-circle-o"></i>
                                    </span>
                                    <span class="detailInfo" onMouseOver="this.style.color='white'"
                                        onMouseOut="this.style.color='gray'">
                                        Web Page Design
                                    </span>
                                </a>


                                <a href="" class="list-group-item">
                                    <span class="detailIcon">
                                        <i class="fa fa-dot-circle-o"></i>
                                    </span>
                                    <span class="detailInfo">
                                        Landing Page Design
                                    </span>
                                </a>

                                <a href="" class="list-group-item">
                                    <span class="detailIcon">
                                        <i class="fa fa-dot-circle-o"></i>
                                    </span>
                                    <span class="detailInfo">
                                        User Experience (UX) Design
                                    </span>
                                </a>

                                <a href="" class="list-group-item">
                                    <span class="detailIcon">
                                        <i class="fa fa-dot-circle-o"></i>
                                    </span>
                                    <span class="detailInfo">
                                        User interface (UI) design
                                    </span>
                                </a>

                                <a href="" class="list-group-item">
                                    <span class="detailIcon">
                                        <i class="fa fa-dot-circle-o"></i>
                                    </span>
                                    <span class="detailInfo">
                                        Responsive Web Design
                                    </span>
                                </a>

                                <a href="" class="list-group-item">
                                    <span class="detailIcon">
                                        <i class="fa fa-dot-circle-o"></i>
                                    </span>
                                    <span class="detailInfo">
                                        Mobile Site Design
                                    </span>
                                </a>

                                <a href="" class="list-group-item">
                                    <span class="detailIcon">
                                        <i class="fa fa-dot-circle-o"></i>
                                    </span>
                                    <span class="detailInfo">
                                        Email Newsletter Design
                                    </span>
                                </a>

                                <a href="" class="list-group-item">
                                    <span class="detailIcon">
                                        <i class="fa fa-dot-circle-o"></i>
                                    </span>
                                    <span class="detailInfo">
                                        Social Media Page
                                    </span>
                                </a>

                                <a href="" class="list-group-item">
                                    <span class="detailIcon">
                                        <i class="fa fa-dot-circle-o"></i>
                                    </span>
                                    <span class="detailInfo">
                                        Logo Design
                                    </span>
                                </a>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- 2nd card .................. -->

        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" style="margin-top: 20px;">
            <div class="productCardContainer">
                <div class="productCardContent" style="border-radius: 40px !important;">

                    <div class="productCardDetails">
                        <div class="productCardModel">
                            <span class="modelCardEffect"></span>
                            <a href="" style="color: white; text-shadow: 2px 2px black;">
                                DEVELOPMENT SOLUTION

                            </a>
                        </div>
                        <div class="productCardDetailsList">
                            <div class="list-group" style="border-radius: 40px !important;">

                                <a href="" class="list-group-item">
                                    <span class="detailIcon">
                                        <i class="fa fa-dot-circle-o"></i>
                                    </span>
                                    <span class="detailInfo">
                                        Ecommerce Site Development
                                    </span>
                                </a>


                                <a href="" class="list-group-item">
                                    <span class="detailIcon">
                                        <i class="fa fa-dot-circle-o"></i>
                                    </span>
                                    <span class="detailInfo">
                                        Vendor Ecommerce Development
                                    </span>
                                </a>

                                <a href="" class="list-group-item">
                                    <span class="detailIcon">
                                        <i class="fa fa-dot-circle-o"></i>
                                    </span>
                                    <span class="detailInfo">
                                        CMS Development
                                    </span>
                                </a>

                                <a href="" class="list-group-item">
                                    <span class="detailIcon">
                                        <i class="fa fa-dot-circle-o"></i>
                                    </span>
                                    <span class="detailInfo">
                                        Real Estate Portal Development
                                    </span>
                                </a>

                                <a href="" class="list-group-item">
                                    <span class="detailIcon">
                                        <i class="fa fa-dot-circle-o"></i>
                                    </span>
                                    <span class="detailInfo">
                                        Custom Web Development
                                    </span>
                                </a>

                                <a href="" class="list-group-item">
                                    <span class="detailIcon">
                                        <i class="fa fa-dot-circle-o"></i>
                                    </span>
                                    <span class="detailInfo">
                                        Educational Website Development
                                    </span>
                                </a>

                                <a href="" class="list-group-item">
                                    <span class="detailIcon">
                                        <i class="fa fa-dot-circle-o"></i>
                                    </span>
                                    <span class="detailInfo">
                                        Web Application Development
                                    </span>
                                </a>

                                <a href="" class="list-group-item">
                                    <span class="detailIcon">
                                        <i class="fa fa-dot-circle-o"></i>
                                    </span>
                                    <span class="detailInfo">
                                        Blogging Website Development
                                    </span>
                                </a>

                                <a href="" class="list-group-item">
                                    <span class="detailIcon">
                                        <i class="fa fa-dot-circle-o"></i>
                                    </span>
                                    <span class="detailInfo">
                                        School & College ERP System
                                    </span>
                                </a>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <!-- 3rd card................... -->

        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" style="margin-top: 20px;">
            <div class="productCardContainer">
                <div class="productCardContent" style="border-radius: 40px !important;">

                    <div class="productCardDetails">
                        <div class="productCardModel">
                            <span class="modelCardEffect"></span>
                            <a href="" style="color: white; text-shadow: 2px 2px black;">
                                WEB MARKETING SOLUTION

                            </a>
                        </div>
                        <div class="productCardDetailsList">
                            <div class="list-group" style="border-radius: 40px !important;">

                                <a href="" class="list-group-item">
                                    <span class="detailIcon">
                                        <i class="fa fa-dot-circle-o"></i>
                                    </span>
                                    <span class="detailInfo">
                                        Content Strategy
                                    </span>
                                </a>


                                <a href="" class="list-group-item">
                                    <span class="detailIcon">
                                        <i class="fa fa-dot-circle-o"></i>
                                    </span>
                                    <span class="detailInfo">
                                        professional Copywriting
                                    </span>
                                </a>

                                <a href="" class="list-group-item">
                                    <span class="detailIcon">
                                        <i class="fa fa-dot-circle-o"></i>
                                    </span>
                                    <span class="detailInfo">
                                        Search Engine Optimization
                                    </span>
                                </a>

                                <a href="" class="list-group-item">
                                    <span class="detailIcon">
                                        <i class="fa fa-dot-circle-o"></i>
                                    </span>
                                    <span class="detailInfo">
                                        Social Media Marketing
                                    </span>
                                </a>

                                <a href="" class="list-group-item">
                                    <span class="detailIcon">
                                        <i class="fa fa-dot-circle-o"></i>
                                    </span>
                                    <span class="detailInfo">
                                        Email Marketing
                                    </span>
                                </a>

                                <a href="" class="list-group-item">
                                    <span class="detailIcon">
                                        <i class="fa fa-dot-circle-o"></i>
                                    </span>
                                    <span class="detailInfo">
                                        Display Advertising
                                    </span>
                                </a>

                                <a href="" class="list-group-item">
                                    <span class="detailIcon">
                                        <i class="fa fa-dot-circle-o"></i>
                                    </span>
                                    <span class="detailInfo">
                                        Blog Marketing
                                    </span>
                                </a>

                                <a href="" class="list-group-item">
                                    <span class="detailIcon">
                                        <i class="fa fa-dot-circle-o"></i>
                                    </span>
                                    <span class="detailInfo">
                                        Online Public Relations
                                    </span>
                                </a>

                                <a href="" class="list-group-item">
                                    <span class="detailIcon">
                                        <i class="fa fa-dot-circle-o"></i>
                                    </span>
                                    <span class="detailInfo">
                                        Conversion Rate Optimization
                                    </span>
                                </a>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    </div>
</div>