<!DOCTYPE html>
<html>
<head>
	
	<link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN" crossorigin="anonymous">
	<link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
	<meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>

<h2 class="text-center" style="font-weight: 900; font-size: 45px; margin: 20px; color: #113F64; text-shadow: 2px 2px whitesmoke;">Our Key services</h2>
<body>

	

	<div class="container">
		
		<div class="Box">
			<div>
				<i class="fas fa-file-powerpoint"></i>
				<h2>SCHOOL ERP</h2>
				<p>
					Available In Both Online and Offline Mode...
				</p>
				
			</div>
		</div>
		<div class="Box">
			<div>
				<i class="fas fa-users"></i>
				<h2>Ecommerce </h2>
				<p>
					Single Vendor & Multi Vendor Available...
				</p>
				
			</div>
		</div>
		<div class="Box">
			<div>
				<i class="fas fa-laptop-code"></i>
				<h2>Hosting</h2>
				<p>
					Starts at 999/- SSL Avability...
				</p>
				
			</div>
        </div>
        
        <div class="Box">
			<div>
				<i class="fas fa-sms"></i>
				<h2>Bulk SMS</h2>
				<p>
					Quick Respons, Instant Delivery, DND Support... 
				</p>
				
			</div>
        </div>
        
        <div class="Box">
			<div>
				<i class="fas fa-democrat"></i>
				<h2>Software on Demand</h2>
				<p>
					Browser & Windows Based Software...
				</p>
				
			</div>
        </div>
        
        <div class="Box">
			<div>
				<i class="fas fa-palette"></i>
				<h2>Web Design</h2>
				<p>
					Responsive, Fast, Attractive...
				</p>
				
			</div>
		</div>


		<div class="Box">
			<div>
				<i class="fas fa-cloud-upload-alt"></i>
				<h2>Cloud Service & Support</h2>
				<p>
					Branding solution can lay the foundation of any given company with the...
				</p>
				
			</div>
		</div>


		<div class="Box">
			<div>
				<i class="fab fa-dev"></i>
				<h2>DevOps Engineering</h2>
				<p>
					Fast , Continuous and relaible CI-CD Integration...
				</p>
				
			</div>
		</div>

		<div class="Box">
			<div>
				<i class="fab fa-draft2digital"></i>
				<h2>Digital Marketing</h2>
				<p>
					Digital marketing is the component of marketing that utilizes internet..
				</p>
				
			</div>
		</div>
	</div>	





</body>
</html>