import { Component, OnInit } from '@angular/core';
import { Meta , Title } from '@angular/platform-browser';
declare var $;
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FAQComponent implements OnInit {

  constructor(private faqTitle : Title, private meta : Meta) {
    this.faqTitle.setTitle('faq | kalyankari Softnet');
    this.meta.addTag({name : 'description', content : 'Frequenty Asked Question (FAQ)'});
    this.meta.addTag({name : 'keywords', content : '24*7 support, ask your query, answer, Web Development , why kalyankari, web design, SEO, web, desktop application, CMS, web hosting, domain name, static website, email hosting, dynamic website, multimedia flash , news, Latest update'})
   }

  ngOnInit(): void {

    var headers = ["H1","H2","H3","H4","H5","H6"];

$(".accordion").click(function(e) {
  var target = e.target,
      name = target.nodeName.toUpperCase();
  
  if($.inArray(name,headers) > -1) {
    var subItem = $(target).next();
    
    //slideUp all elements (except target) at current depth or greater
    var depth = $(subItem).parents().length;
    var allAtDepth = $(".accordion p, .accordion div").filter(function() {
      if($(this).parents().length >= depth && this !== subItem.get(0)) {
        return true; 
      }
    });
    $(allAtDepth).slideUp("fast");
    
    //slideToggle target content and adjust bottom border if necessary
    subItem.slideToggle("fast",function() {
        $(".accordion :visible:last").css("border-radius","0 0 10px 10px");
    });
    $(target).css({"border-bottom-right-radius":"0", "border-bottom-left-radius":"0"});
  }
});
  }

}
