<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PQ2B553"
        height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> 
  <!-- End Google Tag Manager (noscript) -->
<app-navbar></app-navbar>
<div class="term-con container">

    <h1 class="text-center mt-4" style="color: #111180;border-bottom: 3px solid black;">Disclaimer <br></h1>
    <br><br>


    <p><span style="font-weight: 400;">The content on our Website/Services may not be accurate. Although all precautions
            are taken to ensure it’s appropriate, relevant and timely, the content is presented merely as an opinion and
            not fact.</span></p>
    <p><span style="font-weight: 400;">The content presented on our Website/Services is only for information and is not
            to be interpreted as professional advice. If you engage in any outside transactions basing on the
            information on the website, you do so at your own risk. </span></p>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">We do not endorse or guarantee any third party links. The services offered on the
            third party sites are not the services of kalyankarisoftnet. We do not attest or vouchsafe for the accuracy of
            information offered by the linked sites</span></p>
    <p><span style="font-weight: 400;">There are numerous scams happening with fake brand names. We advise our clients
            to take caution and vigilance. Especially in relation to unsolicited emails, phone calls and other
            communication that seek the customer’s personal or financial information or request for any payment in our
            name.</span></p>
    <p><span style="font-weight: 400;">In case of any queries, doubts about the authenticity of communication
            purportedly from, for or on behalf of kalyankarisoftnet click here to report to our internal team or simply send an
            e-mail to <span class="text-primary">info@kalyankarisoftnet.com</span> </span></p>

    <br>
</div>
<br><br><br><br><br><br><br><br>
<app-footer></app-footer>