import { Component, OnInit } from '@angular/core';
import { Meta , Title } from '@angular/platform-browser';

@Component({
  selector: 'app-branding',
  templateUrl: './branding.component.html',
  styleUrls: ['./branding.component.css']
})
export class BrandingComponent implements OnInit {

  constructor(private brandingTitle : Title, private meta : Meta) {
    this.brandingTitle.setTitle('Make Your Brand with Us | Kalyankari Softnet');
    this.meta.addTag({name : 'description', content : 'Turning your Idea into Brand'});
    this.meta.addTag({name : 'keywords' , content : 'make your brand, brand value, User experience, SEO & Content, marketing, social media marketing, ppc, website, huge data,Continuously Update, branding tip'})
   }

  ngOnInit(): void {
  }

}
