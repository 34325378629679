<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PQ2B553"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> 
<!-- End Google Tag Manager (noscript) -->
<app-navbar></app-navbar>


<div style="background-color: black;">
    <div class="container" style="background-color: black; padding-bottom: 30px; padding-top: 30px;">
        <div class="row " style="margin: 0px 0px 0px 20px;">
            <img  class="img-fluid" src="../../assets/images/careerpage_kalyankari.webp" alt="">
    
        </div>
    
        <h2 class="text-center" style="font-weight: 800; text-align: center; margin-top: 20px;"> CAREER OPPORTUNITY
        </h2>
        <img class="img-fluid" src="../../assets/images/career-heading-underline.png" alt="career Kalayankari softnet " srcset="">
        <h3 class="text-center" style="font-weight: 800; text-align: center; margin-top: 20px;">Build Your Career with Kalyankari Softnet</h3>
        <h4 class="text-center" style="text-align: center; margin-top: 20px;">We have a range of amazing opportunities
            available in every corner of the world..
            Take a look and apply !</h4>
        <h6 class="text-center" style="text-align: center; margin-top: 20px;">“ We may be perfect for each other !! ” </h6>
    
    
        <section class="links-underlined">
    
    
        </section  >
    
        
        <section class="demo text-muted">
            <div class="tabs-container">
                <h2 class="tab-label text-muted" style="font-size: 25px;">Socket.io/Node.js Developer</h2>
                <p class="text-muted">We are looking for an experienced Socket.io/Node.js developer for a project who has designed and
                    developed large scale enterprise applications. Candidate should be having strong professional experience
                    in Socket.io/Node.js framework. Must be exceptionally good in MongoDB or MySQL database and Socket.IO
                    for web socket connections.
                </p>
    
                <p class="text-muted">Desired Candidate Profile</p>
                <ul class="text-muted">
                    <li>Role Category: Programming & Design</li>
                    <li>Role: Software Develope</li>
                    <li>Keyskills: Javascript, Node.Js, MySQL, JSON, MongoDB, Java, Web Services…</li>
                    <li>Education: (UG – Any Graduate) AND (PG – Any PG Course – Any Specialization)</li>
                    <li>Job Location: Mumbai (Near Subhash Nagar Metro Station)</li>
                    <li>Job Type: Full-time permanent position with day shift.</li>
                </ul>
                <p>Resumes, compensation goals, mug shots or pictures (optional) along with a cover letter explaining why we
                    should consider you and how you would help the organization should be emailed ...</p>
    
                <hr>
    
                <h2 class="tab-label collapsed" style="font-size: 25px;">Web Designer/Graphic Designer
                </h2>
                <p>There is no vacancy available for this post.</p>
    
                <hr>
    
    
                <h2 class="tab-label collapsed" style="font-size: 25px;">Web Developer</h2>
                <p>Urgently Req PHP Developer, Who can develop PHP Website Independently. Good knowledge of PHP, MySQL,
                    Joomla, ECommerce, CMS, Shopping carts, Payment gateway integration. Knowledge of C & C++/ html/ CSS/
                    Javascript/Flash is an advantage</p>
    
                    <br>
                    <p>Desired Candidate Profile</p>
    
                    <ul>
                        <li>Role Category: Programming & Design</li>
                        <li>Role: Web Developer</li>
                        <li>Education: (UG – Any Graduate) AND (PG – Any PG Course – Any Specialization)</li>
                        <li>Job Location: Hyderabad</li>
                        <li>Job Type: Full-time permanent position with day shift.</li>
                    </ul>
                   <p>Resumes, compensation goals, mug shots or pictures (optional) along with a cover letter explaining why we should consider you and how you would help the organization should be emailed ...</p>
    
                    <hr>
            </div>
            <h2>NOTES : </h2>
            <p><u>Be aware of fake jobs</u> We don't use to post our jobs on online portal like naukri.com, indeed.com and so on. Keep 
                your eyes on our website's career section, Here you will find relevant jobs. And mail us on mentioned email id.
                
            </p>
            <hr>
            <p>Thank You !!</p>
        </section>
    
    
    </div>
</div>

<app-footer></app-footer>