import { Component, OnInit } from '@angular/core';
import { Meta , Title } from '@angular/platform-browser';

@Component({
  selector: 'app-seo-page',
  templateUrl: './seo-page.component.html',
  styleUrls: ['./seo-page.component.css']
})
export class SeoPageComponent implements OnInit {

  constructor(private titleSeo : Title, private meta : Meta) { 
    this.titleSeo.setTitle('Best SEO service | Kalyankari softnet');
    this.meta.addTag({name : 'description', content : '10x Better SEO results | Result Driven SEO | Best SEO Technique '});
    this.meta.addTag({name : 'keywords', content : 'best seo plan, better results, Google first page, serach engine, Research, Optimization, Improvement, Rational Market Research, On-Page Optimization, quality link building '})
  }

  ngOnInit(): void {
  }

}
