<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PQ2B553"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> 
<!-- End Google Tag Manager (noscript) -->
<app-navbar></app-navbar>

<body>
    <div class="container-fluid" style=" background-color: #0f0f0f;" id="heroImage">
        <div class="row" >
            <div class="col-md-12 col-sm-12 col-xl-12"  >
                <h2 class="text-center"
                    style="color: white; font-weight: 800; font-size: 60px; padding-top: 10%; text-shadow: 2px 2px gray;">
                    Our
                    Work Process</h2>
                <p class="text-center " style="color: whitesmoke; font-weight: 400; font-size: 30px; padding-bottom: 40px;">How we work to
                    complete
                    your project. Here you can find our working process.</p>


            </div>

        </div>

        <div class="row" >
            <div class="col-md-12" >
                <h2 class="text-center" style="font-weight: 800; font-size: 25px; color: whitesmoke;">WORKING <span
                        style="color: #eaecee;">PROCESS</span></h2>
                <p class="text-center" style="font-weight: 600; font-size: 18px; color: whitesmoke; padding: 0 50px 0 50px;">Our working process compromises of few
                    steps which we like to follow to complete our undertaken project
                    in an efficient way. We are having the different team assigned to different task and workflow from one
                    to another.</p>
    
                <p class="text-center" style="font-weight: 600; font-size: 20px; margin-bottom: 40PX; color: whitesmoke;">All work is done by
                    our team only. Please note that we don't outsource our work. </p>
    
            </div>

            
        </div>

      
 


    </div>

    <div class="container-fluid" style="margin-top: -6rem;">

        <div class="row" style="background-color: #28A6E9;">
            <div class="col-md-6">
                <img style="margin-top: 20px;" class="img-fluid" src="../../assets/images/workprocesimg1.png" alt="signup for thepopweb">
            </div>

            <div class="col-md-6">
                <div>
                    <h3 class="display-2" style="font-weight: 700; margin-top: 20%; ">SIGN UP</h3>
                    <p class="text-justify">Working process starts when you will contact us. So, first fill our contact
                        form and send your requirement to
                        us or you can directly send email to us. We deal with different types of work such as software
                        development,
                        website designing, website development, digital marketing, SEO and much more. You can have your
                        whole online
                        solution in one place. After sign up, your hosting charges will be started. You will have to pay
                        40% of the
                        amount in advance to start your work. We accept all types of payment such as cash, card, bank
                        transfer etc.
                    </p>
                </div>

            </div>


        </div>



        <!-- ............................................. -->
        <div class="row" style="background-color: whitesmoke;">


            <div class="col-md-6">
                <div>
                    <h3 class="display-4" style="font-weight: 700; margin-top: 20%;  ">RESEARCH & ANALYZE </h3>
                    <p class="text-justify">Once you have contacted us and sent your brief requirement. We will be
                        asking you few questions about what type of design and functionalities you want. You can explain
                        your view to us. Then our team will start doing research on current trends and work related to
                        an idea. We only choose best to design and develop and work accordingly. Simple website
                        development can take around one week and large website designing can take around a month.
                        Application development process can take around a month if you are wanting to customize
                        application according to your need.
                    </p>
                </div>

            </div>

            <div class="col-md-6">
                <img style="border-radius: 50px; padding-top: 12px; padding-bottom: 12px;" class="img-fluid" src="https://cdn.openpr.com/S/b/Sb11771744_g.jpg" alt="RESEARCH & ANALYZE ">
            </div>


        </div>

        <!-- ................................................... -->
        <div class="row" style="background-color: #0078FF;">

            <div class="col-md-6">
                <img class="img-fluid" src="../../assets/images/ui-ux-design-workprocess.png" alt="RESEARCH & ANALYZE ">
            </div>
            <div class="col-md-6">
                <div>
                    <h3 class="display-4" style="font-weight: 700; margin-top: 20%;  ">UI & UX DESIGN </h3>
                    <p class="text-justify">Your website will be designed according to your choice and you will ask to
                        review your design. Until that design is approved by you we will be not doing any website
                        development or build part. you may take multiple revision to approve your design. But once you
                        have approved and build process gets started additional charges may incur to do any alteration
                        in the approved design.
                    </p>
                </div>

            </div>




        </div>


        <!-- ............................................. -->
        <div class="row" style="background-color: white;">


            <div class="col-md-6">
                <div>
                    <h3 class="display-4" style="font-weight: 700; margin-top: 20%;  font-size: 50px;">FUNCTIONALITY
                        DEVELOPMENT </h3>
                    <p class="text-justify">After finalizing your design process we will move towards development part
                        that is whole coding process. Under this process, we will analyze and test your website
                        performance. We will make it fully responsive and analyze on different screen sizes such as
                        mobile, tablet, laptop or any other big screen. At this process, you will need to supply the
                        words and if we are developing your e-Commerce website then you will have to guide us to
                        categories and products and payment methods etc.
                    </p>
                </div>

            </div>

            <div class="col-md-6">
                <img class="img-fluid" style="position: relative; float: right; border-radius: 40px;" src="../../assets/images/FUNCTIONALITY DEVELOPMENT -workprocess.jpg"
                    alt="functional development thepopweb ">
            </div>


        </div>




        <!-- ................................................... -->
        <div class="row" style="background-color: #03AEED;">

            <div class="col-md-6">
                <img class="img-fluid" style="border-radius: 50%;" src="../../assets/images/launch-workprocess.png" alt="launch website thepopweb ">
            </div>
            <div class="col-md-6">
                <div>
                    <h3 class="display-4" style="font-weight: 700; margin-top: 20%;  ">LAUNCH </h3>
                    <p class="text-justify">When the whole process of designing and developing a website completed then
                        we put it live. It can be live on your old domain as well as on new domain. After completing the
                        whole process we also help our client to improve ranking on search engine through the help of
                        Search Engine Optimization. Our team is also expert in digital marketing and social media
                        optimization.
                    </p>
                </div>

            </div>




        </div>




        <!-- ............................................. -->
        <div class="row" style="background-color: #EEF3F9;">


            <div class="col-md-6">
                <div>
                    <h3 class="display-4" style="font-weight: 700; margin-top: 20%;  font-size: 50px;">SUPPORT </h3>
                    <p class="text-justify">We are always ready to support our client. After going live client may
                        require to change many functionalities or alter the design. We also help them in improving
                        website ranking in search engines like google, yahoo, and bing. In case of application
                        development, we provide training and make our client trained. If the website is CMS then also
                        there are many changes that are always required to be updated after the time-to-time interval.
                        We provide fast and efficient service to our clients.
                    </p>
                </div>

            </div>

            <div class="col-md-6">
                <img class="img-fluid" src="../../assets/images/supportimageforworkprocess.png"
                    alt="24/7 support thepopweb ">
            </div>


        </div>


        <!-- ..................connect us .................. -->
       
            <div class="row">
                <div class="col-md-12">
                   <h4 class="text-center" style="font-weight: 800; margin-top: 30px;">GET CONNECTED TO WORK ON YOUR IDEA</h4>
                   <div class="text-center" style="margin-bottom: 30px; margin-top: 15px;">
                    <a [routerLink] = "['/contact-us']"><button type="button" class="btn btn-outline-secondary btn-lg">CONNECT NOW</button></a>
                   </div>
                </div>

            </div>

        

    </div>







</body>

<app-footer></app-footer>