import { Component, OnInit } from '@angular/core';
import { Meta , Title } from '@angular/platform-browser';

@Component({
  selector: 'app-career-page',
  templateUrl: './career-page.component.html',
  styleUrls: ['./career-page.component.css']
})
export class CareerPageComponent implements OnInit {

  constructor(private careerTitle : Title , private meta : Meta) {
    this.careerTitle.setTitle('Career | kalyankari Softnet');
    this.meta.addTag({name : 'description' , content : 'Build Your Career with Kalyankari Softnet'});
    this.meta.addTag({name : 'keywords' , content : 'career opportunity, build your career with us, amazing opportunities, Our happy employee, our happy clients, our locaation, mnc jobs, job opportunities'})
   }

  ngOnInit(): void {
  }

}
