<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PQ2B553"
  height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> 
<!-- End Google Tag Manager (noscript) -->

<app-navbar></app-navbar> 
<body>
    <div class="container" >
        <div class="row" >
                 <div class="col-md-12 text-center">
                     <h2 class="text-center" style="font-weight: 1200; font-size: 45px; color: rgb(14, 13, 13); margin-bottom: 30px;  text-shadow: 2px 2px gray; margin-top: 60px; ">Logo Designing</h2>
                     <img  class="img-fluid" style="width: fit-content;" src="../../assets/images/logo-underline.png" alt="logo services">

                 </div>
                 
                    
                      <div class="col-md-4">
                        <div class="card wiggle">
                          <h3 class=" align-self-center">STARTER </h3>
                          <h2 class=" align-self-center">INR 1500</h2>
                          <img src="../../assets/images/starterlogo.png" class=" align-self-center" alt="">
                          <p>2 Design Concepts</p>
                          <p>2 Revisions </p>
                          <button [routerLink] = "['/contact-us']" class="btn">
                                  CONTACT NOW <span class="badge badge-primary"></span>
                          </button>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="card wiggle">
                          <h3 class=" align-self-center">PREMIUM </h3>
                          <h2 class=" align-self-center">INR 4500</h2>
                          <img src="../../assets/images/premiumlogo.png" class=" align-self-center" alt=""> 
                          <p>Flash Animated Logo</p>
                          <p>1 Design Concept, 2 Revisions </p>
                          <button [routerLink] = "['/contact-us']" class="btn">
                                  CONTACT NOW <span class="badge badge-primary"></span>
                          </button>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="card wiggle">
                          <h3 class=" align-self-center">BEST BUY  </h3>
                          <h2 class=" align-self-center">INR 2500</h2>
                          <img src="../../assets/images/bestbuylogo.png" class=" align-self-center" alt=""> 
                          <p>4 Design Concepts</p>
                          <p>2 Revisions </p>
                          <button [routerLink] = "['/contact-us']" class="btn">
                                  CONTACT NOW <span class="badge badge-primary"></span>
                          </button>
                        </div>
                      </div>

                     

                     
                   
                  
        </div>

    </div>
</body>

<app-footer></app-footer>