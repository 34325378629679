<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PQ2B553"
  height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> 
<!-- End Google Tag Manager (noscript) -->

<app-navbar></app-navbar>

<body>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h2 class="text-center" style=" font-weight:900; color:white; margin-top: 20px; ">We Provide immense of options
          in web hosting !</h2>

        <p class="text-center text-muted">Kalyankari Softnet provides all kinds of web hosting needs. Providing you the
          best advanced Featu Black Web Hosting Platform throughout India. Through our services, we are now the most
          trusted brand in all regions for website services. </p>



        <div class="row" style="margin-bottom: 20px;">
          <div class="col-xs-12 col-md-6 col-lg-3">
            <div class="card" style="margin-bottom: 20px;">
              <img class="card-img-top img-fluid mx-auto d-block" src="../../assets/images/ecomerse-hosting.jpg"
                alt="Card image cap">
              <div class="card-block">
                <h4 class="card-title" style="margin-bottom: 25px;">ECommerce Ready </h4>
                <p class="card-text"> Private SSL Available</p>
                <p>Add-on Dedicated IP</p>
                <p>Supports - osCommerce and</p>
                <p>-CubeCart</p>
                <p>-Zen Cart</p>
              </div>

            </div>

          </div>


          <div class="col-xs-12 col-md-6 col-lg-3">
            <div class="card">
              <img class="card-img-top img-fluid mx-auto d-block" src="../../assets/images/emailhostinglogo.png"
                alt="Card image cap">
              <div class="card-block">
                <h4 class="card-title" style="margin-bottom: 25px;">Advanced Email Hosting</h4>
                <p class="card-text">IMAP Support</p>
                <p>Easy to use Webmail Interface</p>
                <p>Unlimited POP3 Email Accounts</p>
                <p>With SMTP</p>
                <p>100% Uptime and Security</p>
              </div>

            </div>
          </div>

          <!-- Add the extra clearfix for only the required viewport -->
          <div class="clearfix hidden-sm-down hidden-lg-up"></div>
          <div class="col-xs-12 col-md-6 col-lg-3">
            <div class="card">
              <img style=" background: rgba(255, 255, 255, 0.2) !important; 
              backdrop-filter: blur(8px) !important;" class="card-img-top img-fluid mx-auto d-block" src="../../assets/images/anythinghosting.jpg"
                alt="Card image cap">
              <div class="card-block">
                <h4 class="card-title" style="margin-bottom: 25px;">Unlimited Everything!</h4>

                <p class="card-text">Unlimited FTP Accounts</p>
                <p>Unlimited Disk Space</p>
                <p>Unlimited Bandwidth</p>
                <p>Unlimited Domains & Sub-Domains</p>
                <p>Unlimited Email Accounts</p>
                <p></p>
              </div>

            </div>
          </div>


          <div class="col-xs-12 col-md-6 col-lg-3">
            <div class="card">
              <img class="card-img-top img-fluid mx-auto d-block" src="../../assets/images/robostlogohosting.jpg"
                alt="Card image cap">
              <div class="card-block">
                <h4 class="card-title" style="margin-bottom: 25px;">Robust Infrastructure</h4>

                <p class="card-text">State-of-the-art Datacenters</p>
                <p>Redundant Scalable Servers</p>
                <p>Dual Quad-Core Xeon poweblack Servers</p>
                <p>Unlimited FTP Accounts</p>
                <p>Unlimited MySQL/MongoDB/FireBase Databases</p>

              </div>

            </div>
          </div>


          <div class="col-xs-12 col-md-6 col-lg-3">
            <div class="card">
              <img class="card-img-top img-fluid mx-auto d-block" src="../../assets/images/unlimitedhosting.jpg"
                alt="Card image cap">
              <div class="card-block">
                <h4 class="card-title" style="margin-top:35px; margin-bottom:35px">Host Unlimited Domains </h4>
                <p class="card-text"> Desktop publishing packages web page model and a for many web sites.</p>
              </div>

            </div>
          </div>


          <div class="col-xs-12 col-md-6 col-lg-3">
            <div class="card">
              <img class="card-img-top img-fluid mx-auto d-block" src="../../assets/images/support logo.jpg"
                alt="Card image cap">
              <div class="card-block">
                <h4 class="card-title" style="margin-top:35px; margin-bottom:35px">Free 24×7/365 Support </h4>
                <p class="card-text">Desktop publishing packages web page model and a for many web sites.</p>
              </div>

            </div>
          </div>

          <div class="col-xs-12 col-md-6 col-lg-3">
            <div class="card">
              <img class="card-img-top img-fluid mx-auto d-block" src="../../assets/images/cpanel-logo-500x250.jpg"
                alt="Card image cap">
              <div class="card-block">
                <h4 class="card-title" style="margin-top:35px; margin-bottom:59px">cPanel Control Panel </h4>
                <p class="card-text">Desktop publishing packages web page model and a for many web sites.</p>
              </div>

            </div>
          </div>


          <div class="col-xs-12 col-md-6 col-lg-3">
            <div class="card">
              <img class="card-img-top img-fluid mx-auto d-block"
                src="../../assets/images/30-days-money-back-guarantee-m.jpg" alt="Card image cap">
              <div class="card-block">
                <h4 class="card-title" style="margin-top:35px; margin-bottom:35px">30 Day Money-back Guarantee</h4>
                <p class="card-text">Desktop publishing packages web page model and a for many web sites.</p>
              </div>

            </div>
          </div>



        </div>


      </div>

    </div>

  </div>

</body>

<app-footer></app-footer>