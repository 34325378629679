import { Component, OnInit } from '@angular/core';
import { Meta , Title } from '@angular/platform-browser';

@Component({
  selector: 'app-work-process',
  templateUrl: './work-process.component.html',
  styleUrls: ['./work-process.component.css']
})
export class WorkProcessComponent implements OnInit {

  constructor(
    private meta : Meta,
    private title : Title
  ) { 
    this.title.setTitle('Work process | kalyankari softnet');
    this.meta.addTag({name :'description', content : 'our work process, signup, research & analyze, UI & UX Design, functionality development '});
    this.meta.addTag({name : 'keywords', content : 'research, support 24/7, client support, tention free work, we listen our client, production, launch date, work deadline'})
  }

  ngOnInit(): void {
  }

}
