import { Component, OnInit } from '@angular/core';
import { Meta , Title } from '@angular/platform-browser';

@Component({
  selector: 'app-cloud-service',
  templateUrl: './cloud-service.component.html',
  styleUrls: ['./cloud-service.component.css']
})
export class CloudServiceComponent implements OnInit {

  constructor(private cloudTitle : Title, private meta : Meta ) {
    this.cloudTitle.setTitle('Cloud Service | Kalyankari Softnet');
    this.meta.addTag({name : 'description', content : 'Optimize cloud environments to achieve Agile and DevOps | AWS | Azur | Google Cloud'});
    this.meta.addTag({name: 'keywords', content : 'Best cloud service, Cloud Engineering, cloud & application management, Cloud Engineering solutions, latest digital technologies, CI/CD automation,  IoT, AI and machine learning, Data and Server Migration, Powerful cloud storage, No data loss'})
   }

  ngOnInit(): void {
  }

}
