import { Component, OnInit } from '@angular/core';
import { Meta , Title } from '@angular/platform-browser';

@Component({
  selector: 'app-software-developmentn',
  templateUrl: './software-developmentn.component.html',
  styleUrls: ['./software-developmentn.component.css']
})
export class SoftwareDevelopmentnComponent implements OnInit {

  constructor(private STitle : Title , private meta : Meta) {
    this.STitle.setTitle('Software Development | Kalyankari Softnet');
    this.meta.addTag({name: 'description', content : 'software development in kalyankari softnet'});
    this.meta.addTag({name: 'keywords', content: 'easy development, user friendly, attractive UI, latest, standalone application, desktop application, custom software, gaming, test series, Online student enrolment software and many more '});
   }

  ngOnInit(): void {
  }

}
