<div class="container ">
    <h2 style="font-weight: 900; margin-top: 25px; margin-bottom: 25px;  color:#113F64;" class="text-center">From Digital Experts</h2>
    <div class="card-group vgr-cards">
        <div class="card">
            <div class="card-img-body">
                <img class="card-img" src="../../../assets/images/BlackhatSeo.jpg" alt="Card image cap">
            </div>
            <div class="card-body">
                <h4 class="card-title" style="font-weight: 700; font-size: 20px;">8 Black Hat SEO Techniques That Can
                    Bankrupt You</h4>
                <p class="card-text text-muted">Beware of fatal Black Hat SEO Techniques, which cause your online business from penalized or banned.</p>
                <a [routerLink]="['/dangerous-black-hat-seo-techniques']" class="btn btn-outline-primary">Read More &nbsp; <i class="fas fa-arrow-right"></i></a>
            </div>
        </div>
        <div class="card">
            <div class="card-img-body">
                <img class="card-img" src="../../../assets/images/simple seo.jpg" alt="Card image cap"></div>
            <div class="card-body">
                <h4 class="card-title" style="font-weight: 700; font-size: 20px;">Did You Know a Simple SEO Audit Can
                    Save Hundreds of Dollars?</h4>
                <p class="card-text text-muted">SEO Audit is mandatory for your site. Make every minute change to your SEO strategy so that you can be on the top.</p>
                <a [routerLink] ="['/Why-seo_audit_important']" class="btn btn-outline-primary">Read More &nbsp; <i class="fas fa-arrow-right"></i></a>
            </div>
        </div>
        <div class="card">
            <div class="card-img-body">
                <img class="card-img" src="../../../assets/images/simple mistakes.png" alt="Card image cap"></div>
            <div class="card-body">
                <h4 class="card-title" style="font-weight: 700; font-size: 20px;">Basic On-Page SEO Mistakes That Can Be the
                    Downfall of Your Business</h4>
                <p class="card-text text-muted">Willing to rank at the Top? On-Page SEO mistakes!  Always try to abstain from them. Recognize them properly and protect your website from being destroyed.</p>
                <a [routerLink]="['/basic-on-page-seo-mistakes']" class="btn btn-outline-primary">Read More &nbsp; <i class="fas fa-arrow-right"></i></a>
            </div>
        </div>
    </div>
</div>