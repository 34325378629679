<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PQ2B553"
  height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> 
<!-- End Google Tag Manager (noscript) -->
<app-navbar></app-navbar>

<div class="container-fluid">
  <div class="row">
    <div class="col-md-12" id="hero-baground">
      <div>
        <h4 class="text-center" style="font-weight: 900;">Frequenty Asked Question (FAQ)</h4>
      </div>

    </div>

  </div>

</div>


<!--faq section for accordion start-->
<div class="container my-4">
  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
    <div class="panel panel-default">
      <div class="panel-heading" role="tab" id="headingOne" style="background-color: #ffc15a;">
        <h4 class="panel-title">
          <a style="color: black; text-decoration: none;" role="button" data-toggle="collapse" data-parent="#accordion"
            href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            About kalyankari softnet ?
          </a>
        </h4>
      </div>
      <div id="collapseOne" class="panel-collapse collapse show" role="tabpanel" aria-labelledby="headingOne">
        <div class="panel-body my-4 text-justify">
          Kalyankari Softnet is a leading Web Design and Development Company in India. It specializes in Web designing,
          Web Development, E-Commerce solutions, Customized CMS design, WordPress design, Joomla Development, Search
          Engine Marketing, Internet Marketing, and Project Training to IT background students. Having a vastly
          experienced team of IT Engineers harnessed with the latest technologies(e.i. Angular, NodeJS, etc.). Whatever
          be the size and type of companies, are included in our customers' list. We always strive to provide an optimum
          level of satisfaction with our world-class services.
        </div>
      </div>
    </div>
    <div class="panel panel-default">
      <div class="panel-heading" role="tab" id="headingTwo" style="background-color: #ffc15a;">
        <h4 class="panel-title">
          <a style="color: black; text-decoration: none;" class="collapsed" role="button" data-toggle="collapse"
            data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            What is web development ?
          </a>
        </h4>
      </div>
      <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
        <div class="panel-body">
          <p class="text-justify">The term Web Development means marketing a website and deploying on the web. Web
            development is done by scripting languages both at the server end and also at the client end.</p>

          <p class="text-justify">We develop all types of Software and Websites. We are more focused on single page
            application. We are also focused on loading time and many other aspects which improve the Alexa ranking
            website.</p>
        </div>
      </div>
    </div>


    <div class="panel panel-default">
      <div class="panel-heading" role="tab" id="headingThree" style="background-color: #ffc15a;">
        <h4 class="panel-title">
          <a style="color: black; text-decoration: none;" class="collapsed" role="button" data-toggle="collapse"
            data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
            What is web design ?
          </a>
        </h4>
      </div>
      <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
        <div class="panel-body">
          <p class="text-justify">Web Design is the appearance of your website and the way it works. But, in a real
            sense, all techniques and skills are involved in creating a web page design to give it desired look.</p>
        </div>
      </div>
    </div>


    <div class="panel panel-default">
      <div class="panel-heading" role="tab" id="headingFour" style="background-color: #ffc15a;">
        <h4 class="panel-title">
          <a style="color: black; text-decoration: none;" class="collapsed" role="button" data-toggle="collapse"
            data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
            What is search engine optimization ?
          </a>
        </h4>
      </div>
      <div id="collapseFour" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
        <div class="panel-body">
          <p class="text-justify">Search Engine Optimization (SEO) is a technique or process by which a website get
            good rank in search engine like Google, Yahoo, AOL , Ask etc. SEO involves lot's of method to optimized
            your website pages as per search engine search algorithm to get noticed. Now a days it is noticed that
            more visitors means more business leads so SEO play a very important role in Internet marketing.</p>
        </div>
      </div>
    </div>

    <div class="panel panel-default">
      <div class="panel-heading" role="tab" id="headingFive" style="background-color: #ffc15a;">
        <h4 class="panel-title">
          <a style="color: black; text-decoration: none;" class="collapsed" role="button" data-toggle="collapse"
            data-parent="#accordion" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
            What is difference between Web and desktop Application or Software ?
          </a>
        </h4>
      </div>
      <div id="collapseFive" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
        <div class="panel-body">
          <p class="text-justify">A desktop application is a computer program that can be run on a computer device
            itself. While Web-based Software can be accessed through a web browser. It can not run without the internet.
          </p>
        </div>
      </div>
    </div>


    <div class="panel panel-default">
      <div class="panel-heading" role="tab" id="headingSix" style="background-color: #ffc15a;">
        <h4 class="panel-title">
          <a style="color: black; text-decoration: none;" class="collapsed" role="button" data-toggle="collapse"
            data-parent="#accordion" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
            What is Content Management System or CMS ?
          </a>
        </h4>
      </div>
      <div id="collapseSix" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSix">
        <div class="panel-body">
          <p class="text-justify">A Content Management System is a system that helps users to create, manage, and update
            content on a website without having specialized coding knowledge.</p>
        </div>
      </div>
    </div>


    <div class="panel panel-default">
      <div class="panel-heading" role="tab" id="headingSeven" style="background-color: #ffc15a;">
        <h4 class="panel-title">
          <a style="color: black; text-decoration: none;" class="collapsed" role="button" data-toggle="collapse"
            data-parent="#accordion" href="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
            What is Web Hosting ?
          </a>
        </h4>
      </div>
      <div id="collapseSeven" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSeven">
        <div class="panel-body">
          <p class="text-justify">Web Hosting is a service through which an organization and individual can post a
            website or web page on a web browser. A web host or web hosting service provider provides you space and
            technologies needed for websites or web pages.</p>
        </div>
      </div>
    </div>


    <div class="panel panel-default">
      <div class="panel-heading" role="tab" id="heading8" style="background-color: #ffc15a;">
        <h4 class="panel-title">
          <a style="color: black; text-decoration: none;" class="collapsed" role="button" data-toggle="collapse"
            data-parent="#accordion" href="#collapse8" aria-expanded="false" aria-controls="collapse8">
            What is domain name ?
          </a>
        </h4>
      </div>
      <div id="collapse8" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading8">
        <div class="panel-body">
          <p class="text-justify">The website name is your Domain name. It is the address where your audience can access
            your website. It is a unique address which means that it can not be given the same address to other
            different organizations.</p>
        </div>
      </div>
    </div>


    <div class="panel panel-default">
      <div class="panel-heading" role="tab" id="heading9" style="background-color: #ffc15a;">
        <h4 class="panel-title">
          <a style="color: black; text-decoration: none;" class="collapsed" role="button" data-toggle="collapse"
            data-parent="#accordion" href="#collapse9" aria-expanded="false" aria-controls="collapse9">
            What is Static Website ?
          </a>
        </h4>
      </div>
      <div id="collapse9" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading9">
        <div class="panel-body">
          <p class="text-justify">Static Website refers to the site mainly aimed at displaying content for all users,
            rather than personalized content and which is not updated periodically.</p>
        </div>
      </div>
    </div>



  </div>
</div>






<!--faq section for accordion end-->

<div class="container">
  <div class="row">
    <div class="col-md-8 card " style="margin-top: 20px;">




















      <h3 style="font-weight: 700; margin-top: 10px;">What is Email Hosting?</h3>
      <hr>

      <p class="text-justify">Email Hosting is a term that refers to a web or internet hosting service which rents out
        and operates an email server. We have all solutions for the individual regarding personal Email Hosting. Our
        Plans are suitable for all your individual needs. You can create a personalized email as per you or your company
        name. The domain provided by us is free for all our email hosting packages. It is the cheapest email hosting in
        India.</p>



      <h3 style="font-weight: 700; margin-top: 10px;">What is Dynamic Website?</h3>
      <hr>

      <p class="text-justify">The term Dynamic website or Dynamic web page refers to the information of website or web
        page that changes, depending on the viewer, the time of the day, the time zone, the viewer's native language,
        and other factors. A Dynamic web page that would automatically change daily to give visitors new content without
        any human intervention.</p>

      <h3 style="font-weight: 700; margin-top: 10px;">What is multimedia flash design?</h3>
      <hr>

      <p class="text-justify">Multimedia Flash design permits you to add sound and motion to your website that enhances
        the appearance of your website. By proper use of it can engage effectively your visitors.</p>

    </div>


    <!----------------------------aside section start----------------------->

    <div class="col-md-4">


      <aside class="accordion">
        <h1>News</h1>
        <div class="opened-for-codepen">
          <h2>News Item #1</h2>
          <div class="opened-for-codepen">
            <h3>News Item #1a</h3>
            <div>
              <h4>News Subitem 1</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat.</p>
              <h4>News Subitem 2</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat.</p>

              <h4>News Subitem 3</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat.</p>
            </div>

            <h3>News Item #1b</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat.</p>

            <h3>News Item #1c</h3>
            <div class="opened-for-codepen">
              <h4>News Subitem 1</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat.</p>

              <h4>News Subitem 2</h4>
              <p class="opened-for-codepen">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            </div>
          </div>

          <h2>News Item #2</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat.</p>

          <h2>News Item #3</h2>
          <div>
            <h3>News Item #3a</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat.</p>

            <h3>News Item #3b</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat.</p>
          </div>
        </div>

        <h1>Updates</h1>
        <div>
          <h2>Update #1</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat.</p>

          <h2>Update #2</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat.</p>

          <h2>Update #3</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat.</p>

          <h2>Update #4</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat.</p>
        </div>

        <h1>Miscellaneous</h1>
        <div>
          <h2>Misc. #1</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat.</p>

          <h2>Misc. #2</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat.</p>

          <h2>Misc. #3</h2>
          <div>
            <h3>Misc. Item #1a</h3>
            <div>
              <h4>Misc. Subitem 1</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat.</p>

              <h4>Misc. Subitem 2</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat.</p>

              <h4>Misc. Subitem 3</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat.</p>
            </div>
            <h3>Misc. Item #2a</h3>
            <div>
              <h4>Misc. Subitem 1</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat.</p>

              <h4>Misc. Subitem 2</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat.</p>
            </div>
          </div>
        </div>
      </aside>



    </div>















  </div>

</div>


<app-footer></app-footer>