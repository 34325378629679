import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-whythepopweb',
  templateUrl: './whythepopweb.component.html',
  styleUrls: ['./whythepopweb.component.css']
})
export class WhythepopwebComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
