import { OnPageSeoMistakeComponent } from './on-page-seo-mistake/on-page-seo-mistake.component';
import { SimpleSeoAuditComponent } from './simple-seo-audit/simple-seo-audit.component';
import { CareerPageComponent } from './career-page/career-page.component';
import { BlackHatSEO8Component } from './black-hat-seo8/black-hat-seo8.component';
import { AboutusPageComponent } from './aboutus-page/aboutus-page.component';
import { SeoPageComponent } from './seo-page/seo-page.component';
import { CustomWebsiteDesignComponent } from './custom-website-design/custom-website-design.component';
import { SoftwareDevelopmentnComponent } from './software-developmentn/software-developmentn.component';
import { ECommerceComponent } from './e-commerce/e-commerce.component';

import {WebHostingComponent} from './web-hosting/web-hosting.component'
import { LogoDesignComponent } from './logo-design/logo-design.component';
import { EmailHostingComponent } from './email-hosting/email-hosting.component';
import { DomainregistrationComponent } from './domainregistration/domainregistration.component';
import { WebDesiningComponent } from './web-desining/web-desining.component';
import {FAQComponent} from './faq/faq.component';
import { CaseStudyComponent } from './case-study/case-study.component';
import { BrandingComponent } from './branding/branding.component';
import { WorkProcessComponent } from './work-process/work-process.component';
import { ContactUsComponent } from './home/contact-us/contact-us.component';
import { HappyClientComponent } from './home/happy-client/happy-client.component';
import { OurWorkComponent } from './home/our-work/our-work.component';
import { WhythepopwebComponent } from './home/whythepopweb/whythepopweb.component';
import { RevewCardComponent } from './home/revew-card/revew-card.component';
import { SeoComponent } from './home/seo/seo.component';
import { ServiceTabComponent } from './home/service-tab/service-tab.component';
import { OurValueComponent } from './home/our-value/our-value.component';
import { TechnologyUsedComponent } from './home/technology-used/technology-used.component';
import { CardViewTabsComponent } from './home/card-view-tabs/card-view-tabs.component';
import{ConatctUsComponent} from './conatct-us/conatct-us.component'

// import { WelcomeHeaderComponent } from './home/welcome-header/welcome-header.component';
import { NavbarComponent } from './home/navbar/navbar.component';
import { FooterComponent } from './home/footer/footer.component'
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeroComponent } from './home/hero/hero.component';
import { from } from 'rxjs';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { CloudServiceComponent } from './cloud-service/cloud-service.component';
import { DevopsServiceComponent } from './devops-service/devops-service.component';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { OffersComponent } from './offers/offers.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    // WelcomeHeaderComponent,
    FooterComponent,
    CardViewTabsComponent,
    TechnologyUsedComponent,
    OurValueComponent,
    ServiceTabComponent,
    SeoComponent,
    HeroComponent,
    RevewCardComponent,
    WhythepopwebComponent,
    OurWorkComponent,
    HappyClientComponent,
    ContactUsComponent,
    ConatctUsComponent,
    WorkProcessComponent,
    BrandingComponent,
    CaseStudyComponent,
    FAQComponent,
    WebDesiningComponent,
    DomainregistrationComponent,
    EmailHostingComponent,
    LogoDesignComponent,
    WebHostingComponent,
    ECommerceComponent,
    SoftwareDevelopmentnComponent,
    CustomWebsiteDesignComponent,
    SeoPageComponent,
    AboutusPageComponent,
    BlackHatSEO8Component,
    CareerPageComponent,
    SimpleSeoAuditComponent,
    OnPageSeoMistakeComponent,
    PrivacyPolicyComponent,
    TermsConditionComponent,
    DisclaimerComponent,
    CloudServiceComponent,
    DevopsServiceComponent,
    PortfolioComponent,
    OffersComponent
    

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    


  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
