<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PQ2B553"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> 
<!-- End Google Tag Manager (noscript) -->
<app-navbar></app-navbar>
<main>
    <div>
        <!-- Left Aligned -->
        <section id="left-flex">
            <div class="shado-banner ribbon">
                <div class=" title-wrapper">
                    <h1 class="no-margin no-border">Web ! Designing !</h1>
                    <p style="margin-top: 20px; font-size: 16px;">Web Design And Development</p>
                </div>
            </div>
        </section><!-- /end Left Aligned -->
        <p class="spacer"></p>



    </div>
</main>


<div class="container " style="margin-top: -55px;">


    <div class="row">
        <!-- <div class="col-md-12"> <br>
            <h2  class="text-center" style="font-weight: 900; background-color: burlywood;">WEB DESIGNING</h2>

        </div> -->

        <div class="col-md-6 ">
            <h2 class="text-center " style=" font-weight:700; font-size: 25px; margin-top: 20px;">Amazing Website
                Development</h2>
            <p class="text-justify text-muted">Today, the world of Business completely depends upon online business
                ethics. The business boundary has broken its limit. Now, it is global. With sophisticated strategies, a
                business can grow tremendously. So, we have established ourselves as an esteemed website design company
                in India. We efficiently nourish your website. So that you can be benefited most.</p>

            <p class="text-justify text-muted">Kalyankari Softnet, provides its valuable services throughout India,
                which is beyond innovation and engineering. Gives you long-lasting outcomes. We always strive for the
                development of mankind and society. We produce an awesome web design that fitted to everyone's needs
                cardinally. We are a hub of the best and expert designers with their extreme knowledge and efficiency
                render you the best web designing.</p>

        </div>

        <div class="col-md-6 ">
            <img src="../../assets/images/web_bg.png" class="img-fluid" alt="">

        </div>






        <!-- ................................section -2............................. -->

        <div class="col-md-6" style="margin-top: 15px; ">
            
            <img class="img-fluid" src="../../assets/images/branding-vector.png" alt="">

        </div>


        <div class="col-md-6">

            <div id="accordion" class="panel-group">
                <div class="panel">
                    <div class="panel-heading">
                        <h4 class="panel-title">
                            <a href="#panelBodyOne" class="accordion-toggle" data-toggle="collapse"
                                data-parent="#accordion">Analysis, Budgeting & Confirmation</a>
                        </h4>
                    </div>
                    <div id="panelBodyOne" class="panel-collapse collapse show">
                        <div class="panel-body">
                            <p class="text-muted text-justify">For further analysis, we set the time frame required to
                                finish, fix the process to follow it, and determine the cost. We keep preferences in
                                mind before proceeding ahead. Thanks for your value confirmation.</p>
                        </div>
                    </div>
                </div>


                <div class="panel">
                    <div class="panel-heading">
                        <h4 class="panel-title">
                            <a href="#panelBodyTwo" class="accordion-toggle collapsed" data-toggle="collapse"
                                data-parent="#accordion">Consultation & Conceptualization</a>
                        </h4>
                    </div>
                    <div id="panelBodyTwo" class="panel-collapse collapse show">
                        <div class="panel-body">
                            <p class="text-muted text-justify">First of all, We listen carefully to your whole concepts or ideas, then suggest all possible refines or alternatives. At last, we declare that we will do it.</p>
                        </div>
                    </div>
                </div>

                <div class="panel">
                    <div class="panel-heading">
                        <h4 class="panel-title">
                            <a href="#panelBodyThree" class="accordion-toggle collapsed" data-toggle="collapse"
                                data-parent="#accordion">Maintainance & Update</a>
                        </h4>
                    </div>
                    <div id="panelBodyThree" class="panel-collapse collapse">
                        <div class="panel-body">
                            <p class="text-muted text-justify">We offer you services for a year free of cost. We keep attention to the security of your websites. We provide you all the necessary updates regularly. Thanks for allowing us to serve you.</p>
                        </div>
                    </div>
                </div>

                <div class="panel">
                    <div class="panel-heading">
                        <h4 class="panel-title">
                            <a href="#panelBodyFour" class="accordion-toggle collapsed" data-toggle="collapse"
                                data-parent="#accordion">Domain and Hosting</a>
                        </h4>
                    </div>
                    <div id="panelBodyFour" class="panel-collapse collapse">
                        <div class="panel-body">
                            <p class="text-muted text-justify">Once it is confirmed, we buy the domain and hosting. We place a welcome page to show that website is about to come soon. After it, we start the actual work of development. Here patience is needed while we develop. </p>
                        </div>
                    </div>
                </div>

                <div class="panel">
                    <div class="panel-heading">
                        <h4 class="panel-title">
                            <a href="#panelBodyFive" class="accordion-toggle collapsed" data-toggle="collapse"
                                data-parent="#accordion">Design & Develop</a>
                        </h4>
                    </div>
                    <div id="panelBodyFive" class="panel-collapse collapse">
                        <div class="panel-body">
                            <p class="text-muted text-justify">We initiate according to the requirement recommended by you. We start working only after proper documentation. We work accordingly with passion. Thanks for showing your patience.</p>
                        </div>
                    </div>
                </div>

                <div class="panel">
                    <div class="panel-heading">
                        <h4 class="panel-title">
                            <a href="#panelBodySix" class="accordion-toggle collapsed" data-toggle="collapse"
                                data-parent="#accordion">Upload & Review</a>
                        </h4>
                    </div>
                    <div id="panelBodySix" class="panel-collapse collapse">
                        <div class="panel-body">
                            <p class="text-muted text-justify">Now, your design is ready online. We need your valuable feedback. Give your precious review, point out mistakes, give your color preferences and all this stuff should be completed within a week.</p>
                        </div>
                    </div>
                </div>

                <div class="panel">
                    <div class="panel-heading">
                        <h4 class="panel-title">
                            <a href="#panelBodySeven" class="accordion-toggle collapsed" data-toggle="collapse"
                                data-parent="#accordion">Final Finishing</a>
                        </h4>
                    </div>
                    <div id="panelBodySeven" class="panel-collapse collapse">
                        <div class="panel-body">
                            <p class="text-muted text-justify">After your review and our team’s finding, we work to give
                                your project a final touch. We
                                provide the best feel for your website and the delivery is confirmed here.</p>
                        </div>
                    </div>
                </div>




            </div>

        </div>


        <div class="col-md-12" style="margin-top: 20px !important; margin-bottom: 10px; ">
            <h2 class="text-center"
                style="font-size: 25px; font-weight: 700; margin-top: 28px; margin-bottom: 5px; border-bottom: solid 1px gray;">
                What We Offer </h2> <br>
            <ul class="text-muted text-muted">
                <li>Get suitable plans as per your requirement. Choose your apparatus which will attract your prospects to your powerful site. </li> <br>

                <li>User-friendly website to increase organic traffic through targeted clients.</li> <br>

                <li>Catchy designs direct the customer's eyes on their useful stuff through viable space and typography. </li> <br>

                <li>With the use of such pictures, photograph exhibitions, features, articles, we make your visual sappy and alluring.  </li> <br>

                <li>Our dedicated teams are capable enough to serve you to innovate you consistently.  </li> <br>

                <li>A simple yet powerful site enhances the functionality.</li> <br>

                <li>Consistent, creative & concise Web 2.0.</li> <br>
            </ul>
        </div>





    </div>
</div>
<app-footer></app-footer>