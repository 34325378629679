import { Component, OnInit } from '@angular/core';
import {Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-black-hat-seo8',
  templateUrl: './black-hat-seo8.component.html',
  styleUrls: ['./black-hat-seo8.component.css']
})
export class BlackHatSEO8Component implements OnInit {

  constructor(private meta : Meta,
    private titleService : Title) { 
      this.titleService.setTitle('SEO Services');
      this.meta.addTag({name : 'description', content : 'black hat SEO'});
      this.meta.addTag({name: 'keywords', content: 'best seo services, On page SEO, Black Hat SEO'});
    }

  ngOnInit(): void {
  }

}
