<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PQ2B553"
  height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> 
<!-- End Google Tag Manager (noscript) -->

<app-navbar></app-navbar>
<!-- ..................................Section -1 ........................ -->
<div class="hero">

  <div class="bg-image"></div>

  <div class="bg-text">
    <h2 style="font-weight: 900;">ABOUT COMPANY</h2>
    <h1 style="font-size: 40px;  font-weight: 600;"> What makes a company great ? </h1>
    <p>That's simple.
      It's the people who work here.</p>
  </div>

  <!-- ...................................Section-2 ............................. -->

  <div class="container"
    style="margin-top: 0px !important; background-image: url('../../assets/images/left-bg-decor.png');">
    <h2 class="text-center" style="margin-top: -70px; margin-bottom: 15px; font-weight: 900; color: #141029 !important;">EXPLORE THE COMPANY DETAIL
    </h2>
    <div class="row">
      <div class="col-md-6" style="background-color: #141029;">
        <img src="../../assets/images/about1.gif" style="width: 430px;" alt="">

      </div>

      <div class="col-md-6" style="background-color: #141029;">
        <h4 class="text-muted" style="font-weight: 700; margin-top: 70px; margin-bottom: 10px;">Company Overview</h4>
        <p class="text-muted text-justify">The esteemed organization, “Kalyankari Softnet” emerged as a brand, rendering
          its valuable services in IT Sector. We offer affordable and quality services to our clients and having fully
          functional centers all over the globe. </p>


      </div>

      <!-- ...........................section-3.................................... -->


      <div class="col-md-6" style="background-color: #303030; padding-top: 40px; padding-bottom: 40px;">
        <h4 class="text-muted" style="font-weight: 700; margin-top: 30px;">How We Work ?</h4>
        <p class="text-muted text-justify">
          The prevailing dynamic digital world of business requires powerful websites, which can derive traffic, calls,
          leads to enhance your sales. We are one of the Tops among India including overseas. Having vast experience of
          rendering our services with a large number of satisfied clients, with our time-tested expertise; we offer our
          clients a fresh and innovative service. We here to provide you professional website SEO, SMM, promotion,
          redesign, software on-demand, bulk SMS, school ERP, e-commerce, hosting, and many more. We have a highly
          proficient team to deliver you the best services. We understand your need as per market requirements.
        </p>

        <p class="text-muted text-justify">
          So, the klyankari softnet world is the best choice for your website as we are result-oriented and always
          strive to increase your ROI.
        </p>


      </div>

      <div class="col-md-6" style="background-color: #303030;">
        <img class="img-fluid" src="../../assets/images/abaoutusimage3.jpg" style="width: 430px; margin-top: 90px;"
          alt="">

      </div>

      <!-- .......................section-4..................................... -->

      <div class="col-md-6" style="background-color: #1F1714;">
        <img class="img-fluid" src="../../assets/images/about5.jpeg"
          style="width: 430px; margin-top: 35px; margin-left: 15px; padding-bottom: 40px;" alt="">

      </div>

      <div class="col-md-6" style="background-color: #1F1714;padding-top: 30px;">
        <h4 style="font-weight: 700; margin-top: 30px;" class="text-muted">Our journey</h4>
        <p class="text-muted text-justify">It takes time, high dedication, great passion, and hard work, to be
          established as a reputed and trusted website Design Company. Being one of India’s fastest growing web design
          company believes that no one can match our best services at the cost which we offer. Also, we offer many
          value-added services to grow your business decently and reap the benefits of online trading.</p>

        <p class="text-muted text-justify">To achieve our goals we are constantly innovating and upgrading our services
          at no additional cost to our customers.
        </p>


      </div>




    </div>

  </div>

  <!-- .............................SECTION-5..................................... -->
  <div class="container">
    <h2 style="margin-top: 20px; font-weight: 800;" class="text-muted text-center">MISSION AND VISION</h2>
    <div class="row">
      <div class="col-md-6" style="background-color: #303030;">
        <h2 class="text-muted" style="font-weight: 700; margin-top: 70px; padding-top: 30PX;">MISSION</h2>
        <p class=" text-muted text-justify">We are passionately committed to providing the best IT services & Web
          Solutions to our clients. We involve best human efforts, high technology & modern management practices to
          envisage our mission. We believe in transparency and are thus determined to create an environment free of
          restlessness. </p>
      </div>

      <div class="col-md-6 text-muted" style="background-color: #303030; ">
        <img class="img-fluid"
          style="width: 430px; margin-top: 25px; margin-left: 15px; margin-bottom: 40px; padding-bottom: 50px;"
          src="https://evergreenfoundationpk.org/wp-content/uploads/2020/09/mission.png" alt="">

      </div>


      <!-- ....................................section-6.................................... -->

      <div class="col-md-6" style="background-color:#1F1714 ;">
        <img style="width: 430px; margin-top: 2px; margin-left: 15px;"
          src="https://www.va.jo/wp-content/uploads/2018/02/vision3.png" alt="">
      </div>

      <div class="col-md-6" style="background-color:#1F1714 ;">
        <p class="text-muted text-justify" style="margin-top: 75px;">We aspire to provide you the best web & related
          services to your business.
        </p>
        <h5 class="text-muted" style="font-weight: 800;">Our Understanding</h5>
        <p class="text-muted text-justify">To serve clients with new-fangled infrastructure and advanced technique
          brings client users together. Through a cohesive undertaking, we are here to fabricate a noteworthy clients'
          brand..</p>

      </div>

    </div>





  </div>






  <app-footer></app-footer>