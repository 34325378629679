<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PQ2B553"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> 
<!-- End Google Tag Manager (noscript) -->

<app-navbar></app-navbar>

<div style="height: 100vh; background-image: url('../../assets/images/casestidiesHeroimg'); background-size: cover; background-position: center;"
    class="position-relative w-100">
    <div  class="position-absolute text-white d-flex flex-column align-items-center justify-content-center"
        style="top: 0; right: 0; bottom: 0; left: 0; background-color: rgba(0,0,0,.6);">
        <span style="padding-left: 25px; padding-right: 25px;"><span style="font-size: 14px;"><i
                    class="fa fa-quote-left" aria-hidden="true"></i></span> &nbsp; <span style="font-size: 18px; ">The
                Best place to hide a dead body is the second page of google search"......Anonymous</span> &nbsp; <span
                style="font-size: 18px;"> <i class="fa fa-quote-right" aria-hidden="true"></i></span></span>


        <h3 style="padding-left: 25px; padding-right: 25px; font-weight: 900;" class="mb-4 mt-2  text-center">Investing on
            high quality design yields high levels of customer engagement</h3>
        <div class="text-center">
            <!-- hover background-color: white; color: black; -->
            <a [routerLink]="['/contact-us']" id="filled" class="btn px-5 py-3 text-white mt-3 mt-sm-0 mx-1"
                style="border-radius: 30px; background-color: rgb(255, 173, 0); font-weight: 700;">Connect us</a>
            <!-- hover background-color: #9B5DE5; color: white; -->
            <a href="#" id="outlined" class="btn px-5 py-3 text-white mt-3 mt-sm-0 mx-1"
                style="border-radius: 30px; border:1px solid rgb(255, 173, 0); font-weight: 700;">Home</a>
        </div>
    </div>
</div>









<!-- <h2 class="text-center" style="font-weight: 800; margin-top: 10px;"> Investing on high quality design yields high levels
    of customer
    engagement</h2>
<p class="text-center">"The Best place to hide a dead body is the second page of google search"......Anonymous</p> -->
<div class="container">
    <div class="row" style="margin-bottom: 30px;">
        <div class="col-md-7">
            <img class="img-fluid" src="../../assets/images/case studies.png" alt="">

        </div>
        <div class="col-md-5">
            <h3 style="font-weight : 900; padding-top: 20px; margin-top: 20px;">Why Kalyankari Softnet ?</h3>
            <p class="text-muted text-justify">Kalyankari Softnet has deep faith in a user-friendly website. We design comprehensive, captivating, vivid, and functional websites, through which users can search easily exactly what they're looking for. Then, it converts them into paying customers. We have vast experience of transforming users into followers and also customers into ambassadors.</p>

        </div>
        <div class=" col-md-12 ">
            <p class="text-muted text-justify">We listen to you carefully, deeply understand your target audience. After that, we apply our experienced and tested techniques to achieve your desired business goals. We always strive to elevate your business brand. Then, we make thorough competitive analysis and on/off-page search engine optimization. That helps you in achieving your targeted goals.</p>

        </div>

        <div class="col-md-6">
            <img src="../../assets/images/project goal.jpg" class="img-fluid" alt="project goal of the pop web">

        </div>

        <div class="col-md-6">
            <h2 style="font-weight: 800; margin-top: 30px;">Project Goal</h2>
            <p class="text-muted text-justify">A client always seeks a high-end appearance and feel of their website with more than 15 pages describing all the services they offer. Your band is created in such a manner as to portray a corporate touch.</p>

        </div>

        <div class="col-md-6">
            <h2 style="font-weight: 800; margin-top: 30px;">Our Solution</h2>
            <p class="text-muted text-justify">Firstly, the appropriate template is selected, and then the angular developer is deployed to get the workflow done. This site is involving the latest angular version with a node(for translation). For the translation of the site, we used a Full-stack Developer to implement the functionality. We also worked on the backend in the node for any signup and did the database integration. The complete site was functional in 5 weeks. </p>

        </div>

        <div class="col-md-6">
            <img src="../../assets/images/our solution.png" class="img-fluid" alt="our solution of thepopweb">

        </div>



    </div>

</div>

<app-footer></app-footer>