
import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import { FormBuilder, FormGroup, NgForm, FormControl, Validators } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
declare var $;


@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})

export class ContactUsComponent implements OnInit {
  url = "https://backend.softnetworld.in/api/query/mail";
  form: FormGroup;

  constructor(
    public formBuilder: FormBuilder,
    private titleService : Title ,
    private meta : Meta,
   
    private http : HttpClient
    
  )
  
  {
    


    this.titleService.setTitle('Best IT Solution in India - Web development | Kalyankari Softnet');
     this.meta.addTag({ name: 'description' , content: 'contact-us | Kalyankari softnet'});
     this.meta.addTag({ name : 'keywords', content : 'address of kalyankari softnet, contact detail, contact number of kalyankari, email of kalyankari'})
   }




  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: new FormControl(''),
      company: new FormControl(''),
      email: new FormControl(''),
      phoneNumber : new FormControl(''),
      message : new FormControl(''),
      role: new FormControl('', Validators.required)

    });

   $(document).ready(function(){
    $('#checkName').hide();
    $('#checkCompany').hide();
    $('#checkEmail').hide();
    $('#checkPhoneNumber').hide();
    $('#checkMessage').hide();

    var name_err = true;
    var company_err = true;
    var email_err = true;
    var phoneNumber_err =true;
    var message_err = true;

    // Name Validation start ..........

    $('#userName').keyup(function () {
      name_Check();
    });

    function name_Check(){
      var user_Name = $('#userName').val();
      if (user_Name.length == ''){
        $('#checkName').show();
        $('#checkName').html("* Enter Your Full Name");
        $('#checkName').focus();
        $('#checkName').css("color", "red");

        name_err =false;
        return false

      }else{
        $('#checkName').hide();
      }


      if ((user_Name.length < 3) || (user_Name.length > 45)) {
        $('#checkName').show();
        $('#checkName').html("Please Enter valid Name");
        $('#checkName').focus();
        $('#checkName').css("color", "red");

        name_err = false;
        return false;

      }else{
        $('#checkName').hide()
      }

    }



    // Company Name validation userCompany

    $('#userCompany').keyup(function () {
      checkCompany();
    });


    function checkCompany(){
      var user_company = $('#userCompany').val();
      if (user_company.length == ''){
        $('#checkCompany').show();
        $('#checkCompany').html("Please enter your comapny name");
        $('#checkCompany').focus();
        $('#checkCompany').css("color", "red");
        company_err = false;
        return false;

      }else{
        $('#checkCompany').hide();
      }

      if (user_company.length < 3 || user_company.length > 160 ){
        $('#checkCompany').show();
        $('#checkCompany').html("Enter valid Company Name");
        $('#checkCompany').focus();
        $('#checkCompany').css("color", "red");

        company_err = false;
        return false;

      }else{
        $('#checkComapny').hide();
      }
    }


   // User email Id validation

   $('#userMail').keyup(function () {
    email_Check();
  });

  function email_Check() {
    var emailStr = $('#userMail').val();
    if (emailStr == ''){
      $('#checkEmail').show();
      $('#checkEmail').html("Enter Email address");
      $('#checkEmail').focus();
      $('#checkEmail').css("color", "red");
      email_err = false;
      return false;
    }else {
      $('#checkEmail').hide();
    }
  }



  //User Phone number validation 

  $('#userPhone').keyup(function (){
    checkPhoneNumber();
  })

  function checkPhoneNumber(){
    var user_phone = $('#userPhone').val();
    if (user_phone.length == ''){
      $('#checkPhoneNumber').show();
      $('#checkPhoneNumber').html("Enter Phone Number");
      $('#checkPhoneNumber').focus();
      $('#checkPhoneNumber').css("color", "red");
      phoneNumber_err = false;
      return false;

    }else{
      $('#checkPhoneNumber').hide();
    }
    
    if (user_phone.length != 10){
      if(isNaN(user_phone)){
        $('#checkPhoneNumber').show();
        $('#checkPhoneNumber').html("Enter Number");
        $('#checkPhoneNumber').focus();
        $('#checkPhoneNumber').css("color", "red");

        phoneNumber_err =false;
        return false;
      }

      $('#checkPhoneNumber').show();
      $('#checkPhoneNumber').html("Enter 10 Digit Phone Number");
      $('#checkPhoneNumber').focus();
      $('#checkPhoneNumber').css("color", "red");

      phoneNumber_err = false;
      return false;


    }else{
      $('#checkPhoneNumber').hide();
    }
  }

  //user message validation
  $('#userMessage').keyup(function(){
    checkMessage();
  })

  function checkMessage(){
    var user_message = $('#userMessage').val();
    if(user_message.length == ''){
      $('#checkMessage').show();
      $('#checkMessage').html("Please Write Your Query Here");
      $('#checkMessage').focus();
      $('#checkMessage').css("color", "red");
      
      message_err =false;
      return false;

    }else{
      $('#checkMessage').hide();
    }

    if(user_message.length < 10 || user_message.length > 500){
      $('#checkMessage').show();
      $('#checkMessage').html("Lenght not Should be in the range of 10 to 500");
      $('#checkMessage').focus();
      $('#checkMessage').css("color", "red");

      message_err =false;
      return false;

    }else{
      $('#checkMessage').hide();
    }
  }

  //submition validation

  
  $('#submitbtn').click(function(){
    name_err =true;
    company_err = true;
    email_err = true;
    phoneNumber_err = true;
    message_err = true;

    name_Check();
    checkCompany();
    email_Check();
    checkPhoneNumber();
    checkMessage();


    if((name_err == true) && (company_err == true) && (email_err == true) && (phoneNumber_err == true) && (message_err == true)){
          return true;
    } else {
      return false;
    }

    


  })




   })
  }


  onSubmit(){
    console.log(this.form.value);
    this.http.post(this.url,this.form.value).subscribe((data : any) =>{
      console.log("Response Msg : ", data)
      if({message : "Email sent successfully"}){
        $("#response").html("Your Query has been sent !!")
      }
    })
  }

  

  

}
