import { Component, OnInit } from '@angular/core';
import { Meta , Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(
    private titleHome: Title,
    private meta : Meta
  ) { 
    this.titleHome.setTitle('Best IT Solution in India - Web development | Kalyankari Softnet');
    this.meta.addTag({ name: 'description' , content: 'One of the best web development company in inida, Affordable web development in india, cheap and best website building in india'});
    this.meta.addTag({ name: 'keywords' , content: 'keep your business online, web development, online marketing, best IT services, School ERP, Ecommerce, Hosing, Bulk SMS, Software on demand, web design, website building, latest technologies, angular, ajax, react, best DB, Best SEO , Digital marketing, Wordpress website, CMS Website ,  payment gateway integration, our values , integrity, value of client, commitment, Health care website, education website, MLM, Govt website, Beauty & Fashion Website, B2B website, Finance website, construction wesbite, law website, NGO website design, why us, review'});
  
  }

  ngOnInit(): void {
  }

}
