import { Component, OnInit } from '@angular/core';
import { Meta , Title } from '@angular/platform-browser';

@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.css']
})
export class TermsConditionComponent implements OnInit {

  constructor(private termsConditionTitle : Title, private meta : Meta) { 
    this.termsConditionTitle.setTitle('Terms & Condtion | kalyankari Softnet');
    this.meta.addTag({name : 'description' , content : 'Terms and condition, Introduction of terms and condition'});
    this.meta.addTag({name : 'keywords', content : 'terms of usee, provison of services, survey questions, use of the service by users,personal data, user rights, processing of personal data, providing personal data to thirsd party, limitations'})
  }

  ngOnInit(): void {
  }

}
