import { Component, OnInit } from '@angular/core';
import {Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {

  constructor(private meta : Meta,
    private titleService : Title) {
      this.titleService.setTitle("Portfolio | kalyankari Softnet");
      this.meta.addTag({name : "description", content : "portfolio of kalyankari softnet"});
      this.meta.addTag({name:"keywords", content : "our reach, core funtionalities, vertical we dominate, media,taxi and travel, health care, game,banking, real estate, e-commerce, enterprises,dating, on demand services,development strategy, customer satisfation, on time delivery, project quality, project scope,cost,fresh veg store,wow2store, vansh.ca, pioneermultiservices,yuwee,softnetworld, computer pathshala, harsh pay"});
     }

  ngOnInit(): void {
  }

}
