

<div class="container-fluid" style="background-color: #171518;">
    <div class="row">
        <div class="col-md-6">
            <div>
                <img class="img-fluid" src="../../assets/images/heroImagephoneView.gif" alt="">
            </div>

        </div>
        <div class="col-md-6">
            <div class=" text-center" style="color: white; margin-top: 10%;">

                <body >
                    <div class="waviy">
                        <span style="--i:1; color: red; ">K</span>
                        <span style="--i:2">A</span>
                        <span style="--i:3">L</span>
                        <span style="--i:4">Y</span>
                        <span style="--i:5">A</span>
                        <span style="--i:6">N</span>
                        <span style="--i:7">K</span>
                        <span style="--i:8">A</span>
                        <span style="--i:9">R</span>
                        <span style="--i:10">I</span>
                        <span style="--i:11">&nbsp; &nbsp;</span>
                        <span style="--i:12; color: #ffad00;">S</span>
                        <span style="--i:13">O</span>
                        <span style="--i:14">F</span>
                        <span style="--i:15">T</span>
                        <span style="--i:16">N</span>
                        <span style="--i:17">E</span>
                        <span style="--i:18">T</span> &nbsp;
                        <span style="--i:19">.</span> &nbsp;
                        <span style="--i:19">.</span> &nbsp;
                        <span style="--i:20">.</span>
                    </div>
                </body>
                <img class="img-fluid" style="width: 340px; height: auto;" src="../../../assets/images/PngItem_5507013.png" alt="kalyankari softnet homepage">
                <h2 class="text-muted" style=" margin-top: 19px; font-size: 16px;">युक्तियुक्तं वचो ग्राह्यं बालादपि शुकादपि !!</h2>
               
             <div style="font-size: 12px; padding-right: 40px; padding-left: 40px; ">
                <p class="text-muted text-justify">Kalyankari softnet provides you prevailing world class services in IT Sector round
                    the clock by our highly proficient team. Vastly experienced professionals work dedicatedly to
                    providing solutions to every need of your business.</p>

                <p class="text-muted text-justify">We always are committed to our quality services to grow your organization to keep
                    you ahead. As we understand, all your business needs in the prevailing competitive scenario at an
                    unbeatable affordable cost.</p>
             </div>

            </div>

        </div>

    </div>

</div>