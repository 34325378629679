import { Component, OnInit } from '@angular/core';
import { Meta , Title } from '@angular/platform-browser';

@Component({
  selector: 'app-web-hosting',
  templateUrl: './web-hosting.component.html',
  styleUrls: ['./web-hosting.component.css']
})
export class WebHostingComponent implements OnInit {

  constructor(private title : Title, private meta : Meta) {
    this.title.setTitle('Web Hosting | Kalyankari Softnet');
    this.meta.addTag({name: 'description', content : 'We Provide immense of options in web hosting'});
    this.meta.addTag({name : 'keywords', content : 'Ecommerce Ready, Advanced Email Hosting, Unlimited Everything, Robust Infrastructure,Host Unlimited Domains , Free 24×7/365 Support , cPanel Control Panel , 30 Day Money-back Guarantee'})
   }

  ngOnInit(): void {
  }

}
