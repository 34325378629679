<!-- ........................start short info..................................................... -->

<body id="toHideshortInfoinPhone" style="overflow: hidden; ">
    <div class="container-fluid" style="padding-bottom: -8px; padding-top: 8px;">
        <div class="row rw">

            <div class="col-md-6">
                <div style="font-size: 13px !important;" class="mainline ">
                    <p>kalyankari softnet Pvt. Ltd. is one of the premium website building company in your area !! </p>

                </div>
            </div>



            <div class="col-md-2">
                <div style="font-size: 13px !important;" class="phnno text-center">
                    <p> <i class="fa fa-volume-control-phone" aria-hidden="true"></i>
                        &nbsp; +91 9607926665</p>

                </div>

            </div>

            <div class="col-md-4">
                <div style="font-size: 13px !important;" class="mail text-center">
                    <p><i class="fa fa-envelope" aria-hidden="true"></i>

                        &nbsp; info@kalyankarisoftnet.com</p>
                </div>

            </div>

        </div>

    </div>
</body>




<!-- ...........................end short info..................................................... -->


<nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="myNav">
    <div class="container">
        <a class="navbar-brand" href="#">
            <!-- <h4>Kalyankari SoftNet</h4> -->
            <img class="img-fluid" style="width: 200px; height: 60px;" src="../../../assets/images/1.png" alt="">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#main_nav">
            <span class="navbar-toggler-icon"> </span>

        </button>
        <div class="collapse navbar-collapse" id="main_nav">

            <ul class="navbar-nav ml-auto">
                <li class="nav-item"> <a class="nav-link text-muted" href="#"><i class="fa fa-home"></i> Home </a>
                </li>

                <li class="nav-item"> <a class="nav-link" [routerLink]="['/aboutUs']"><i class="fa fa-user"
                            aria-hidden="true"></i>

                        About Us </a></li>


                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown"><i class="fa fa-cogs"
                            aria-hidden="true"></i>

                        Services </a>
                    <ul class="dropdown-menu dropdown-content">

                        <li>
                            <a class="dropdown-item" [routerLink]="['/web-designing']"><i class="fa fa-tablet"
                                    aria-hidden="true"></i> &nbsp; Web Designing </a>
                        </li>

                        <li>
                            <a class="dropdown-item" [routerLink]="['/domain-registration']"><i
                                    class="fa fa-venus-double" aria-hidden="true"></i> &nbsp;Domain Registration </a>
                        </li>

                        <li><a class="dropdown-item" [routerLink]="['/email-hosting']"><i class="fa fa-cloud-download"
                                    aria-hidden="true"></i>
                                &nbsp; Email Hosting </a>
                        </li>

                        <li><a class="dropdown-item" [routerLink]="['/logo-design']"><i class="fa fa-heart"
                                    aria-hidden="true"></i>
                                &nbsp; Logo Designing </a>
                        </li>

                        <li><a class="dropdown-item" [routerLink]="['/web-hosting']"><i class="fa fa-cloud-download"
                                    aria-hidden="true"></i>
                                &nbsp; Web Hosting </a>
                        <li><a class="dropdown-item" [routerLink]="['/E-Commerce']"> <i class="fa fa-cart-arrow-down"
                                    aria-hidden="true"></i>
                                &nbsp; E-Commerce </a>
                        </li>

                        <li><a class="dropdown-item" [routerLink]="['/software-Development']"> <i class="fa fa-laptop"
                                    aria-hidden="true"></i>
                                &nbsp; Software Development </a>
                        </li>

                        <li><a class="dropdown-item" [routerLink]="['/custom-website-design']"><i class="fa fa-asterisk"
                                    aria-hidden="true"></i>
                                &nbsp; Custom Website Design </a>
                        </li>

                        <li><a class="dropdown-item" [routerLink]="['/cloud-services']"><i class="fa fa-cloud"
                                    aria-hidden="true"></i>

                                &nbsp; Cloud Services </a>
                        </li>

                        <li><a class="dropdown-item" [routerLink]="['/devops-services']"><i style="border-radius: 50%; background-color: black;" class="fa fa-connectdevelop" aria-hidden="true"></i>


                        &nbsp; Devops Services </a>
                </li>


                        <li><a class="dropdown-item" [routerLink]="['/Best-seo-plan']"><i class="fa fa-rocket"
                                    aria-hidden="true"></i>
                                &nbsp; SEO </a>
                        </li>

                    </ul>
                </li>


                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown"><i class="fa fa-window-restore"
                            aria-hidden="true"></i>

                        Resources </a>
                    <ul class="dropdown-menu dropdown-content">

                        <li><a class="dropdown-item" [routerLink]="['/branding']"><i class="fa fa-leaf"
                                    aria-hidden="true"></i>


                                &nbsp; Branding </a></li>


                        <li><a class="dropdown-item" [routerLink]="['/web-development-case-studies']"><i
                                    class="fa fa-coffee" aria-hidden="true"></i>
                                &nbsp; Case Studies </a></li>

                        <li><a class="dropdown-item" [routerLink]="['/FAQ']"><i class="fa fa-question-circle"
                                    aria-hidden="true"></i>
                                &nbsp; FAQ </a>

                    </ul>
                </li>

                <!-- <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown"><i class="fab fa-cloudsmith"></i> Domain & Hosting </a>
                    <ul class="dropdown-menu">

                        <li><a class="dropdown-item" href="#"> Domain Name Registration </a></li>
                        <li><a class="dropdown-item" href="#"> Web Hosting </a></li>
                        <li><a class="dropdown-item" href="#"> Digital certificate </a>
                            <li><a class="dropdown-item" href="#"> Cloud Hosting </a>
                                <li><a class="dropdown-item" href="#"> Google Business GSuit </a>
                                    
                        
                    </ul>
                </li> -->

                <li class="nav-item"> <a class="nav-link" [routerLink]="['/our-work-process']"><i class="fa fa-tasks"
                            aria-hidden="true"></i>
                        Work Process </a> </li>

                        <li class="nav-item"> <a class="nav-link" [routerLink]="['/career']"><i class="fa fa-graduation-cap"
                            aria-hidden="true"></i>
                        Career </a> </li>        

                <li class="nav-item"> <a class="nav-link" [routerLink]="['/contact-us']"><i class="fa fa-envelope-open"
                            aria-hidden="true"></i>
                        Contact Us </a> </li>

                        <li class="nav-item"> <a class="nav-link" [routerLink]="['/portfolio']"><i class="fa fa-line-chart"
                            aria-hidden="true"></i>
                            Portfolio </a> </li>       


                        

            </ul>

        </div> <!-- navbar-collapse.// -->
    </div>
</nav>