import { Component, OnInit } from '@angular/core';
import { Meta , Title } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(private privacyTitle : Title, private meta : Meta) {
    this.privacyTitle.setTitle('Privacy Policy | kalyankari Softnet ');
    this.meta.addTag({name : 'description', content : 'privacy policy of kalyankari Softnet'});
    this.meta.addTag({name : 'keywords', content : 'privacy policy, introduction, terms condition, Protection of your information, survey questions, Account information, user right, activities, Limitations, general'})
   }

  ngOnInit(): void {
  }

}
