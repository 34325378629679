<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PQ2B553"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> 
<!-- End Google Tag Manager (noscript) -->

<app-navbar></app-navbar>


<main role="main">
    <!-- Main jumbotron for a primary marketing message or call to action -->
    <div class="jumbotron">
        <div class="jumbotron-background"></div>
        <div class="container">
            <div class="row">
                <div class="col-md-8">
                    <h1 id="titlecontact" class=" jumbotron-title newStyle"
                        style="font-size: 30px; margin-bottom: 25px; ">Call Us For Assistance</h1>

                    <span> <i style="font-size: 24px;" class="fa fa-phone-square" aria-hidden="true"></i> &nbsp;
                        <span style="font-size: 16px; " >+91 9607926665 <span style="font-size: 20px; color: whitesmoke;">&nbsp; OR &nbsp;</span> +91 7503485269</span></span> <br> <br>

                        <span> <i class="fa fa-envelope" aria-hidden="true"></i> &nbsp; <span
                            style="font-size: 16px; ">sales@kalyankarisoftnet.com</span></span>   <br> <br>  

                    <span> <i class="fa fa-envelope" aria-hidden="true"></i> &nbsp; <span
                            style="font-size: 16px; " >support@kalyankarisoftnet.com</span></span>  <br> <br>

                               
                                
                                <span> <i class="fa fa-2x fa-map-marker" aria-hidden="true"></i> &nbsp; <span
                                    style="font-size: 16px; ">199, PKT-11, Sec A6, , narela, Delhi 110040, IN</span></span>       

                    

                    <div class="row" style="margin-top: 20px;">
                        <div class="col-md-8">
                            <p class="jumbotron-description">
                                In an IT context, 24/7 support means a support service that is provided 24 hours a day
                                and 7 days a week. This may include an array of IT services – like server monitoring,
                                call center support, database support and so on.
                            </p>
                        </div>

                    </div>
                </div>
                <div class="col-md-4">
                    <div class="h-100 d-flex justify-content-center align-items-center">
                        <div class="hero-image-container">
                            <img src="../../assets/images/contactusimage.png" alt="" class="img-fluid" />
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <app-contact-us></app-contact-us>

                </div>
            </div>
        </div>
    </div>
</main>



<app-footer></app-footer>